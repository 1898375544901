import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../auth/jwt/JwtCreditCard'

const initialError = (message = '') => ({
  statusCode: null,
  name: '',
  message
})

export const applicationDocsCall = createAsyncThunk(
  'appUploadDocuments/getApplicationDocs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getApplicationDocs()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong while getting documents')
      )
    }
  }
)

export const applicationDocsTypesListCall = createAsyncThunk(
  'appUploadDocuments/getADTypesList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getADTypesList()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError(
            'Something went wrong while getting document type catalog'
          )
      )
    }
  }
)

export const uploadDocCall = createAsyncThunk(
  'appUploadDocuments/postApplicationDocs',
  async (body, { rejectWithValue }) => {
    try {
      const response = await UseJwt.postApplicationDocs(body)
      return {
        response: response.data,
        docKey: body.documentType
      }
    } catch (e) {
      return rejectWithValue({
        error:
          e.response?.data?.error ||
          initialError('Something went wrong while uploading document'),
        docKey: body.documentType
      })
    }
  }
)

export const deleteDocCall = createAsyncThunk(
  'appUploadDocuments/deleteApplicationDocs',
  async (body, { rejectWithValue }) => {
    try {
      console.log(body)
      const response = await UseJwt.deleteApplicationDocs({
        id: body.id,
        module: body.module
      })
      return {
        response: response.data,
        docKey: body.documentType
      }
    } catch (e) {
      return rejectWithValue({
        error:
          e.response?.data?.error ||
          initialError('Something went wrong while deleting document'),
        docKey: body.documentType
      })
    }
  }
)

// UPLOAD SSN DOCUMENT LOGIC HERE

// export const uploadDocCall = createAsyncThunk(
//   'appUploadDocuments/uploadDoc',
//   async (body, { rejectWithValue }) => {
//     try {
//       const response = await UseJwt.postApplicationDocs(body)
//       return response.data
//     } catch (e) {
//       return rejectWithValue(
//         e.response?.data?.error ||
//           initialError(
//             'Something went wrong while submiting application documents'
//           )
//       )
//     }
//   }
// )

export const appUploadDocuments = createSlice({
  name: 'appUploadDocuments',
  initialState: {
    pending: false,
    applicationDocs: {
      data: null,
      error: null
    },
    typeList: {
      data: null,
      error: null
    },
    uploadGeneralDoc: {},
    uploadSSNDoc: {
      data: null,
      error: null
    }
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(applicationDocsCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(applicationDocsCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        state.applicationDocs.data = action.payload
      })
      .addCase(applicationDocsCall.rejected, (state, action) => {
        state.pending = false
        state.applicationDocs.error = action.payload
      }) // applicationDocsTypesListCall
      .addCase(applicationDocsTypesListCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(applicationDocsTypesListCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        state.typeList.data = action.payload
      })
      .addCase(applicationDocsTypesListCall.rejected, (state, action) => {
        state.pending = false
        state.typeList.error = action.payload
      }) // uploadDocCall
      .addCase(uploadDocCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(uploadDocCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        console.log(action)
        state.uploadGeneralDoc[action.payload.docKey] = {
          data: action.payload.response,
          error: null
        }
      })
      .addCase(uploadDocCall.rejected, (state, action) => {
        state.pending = false
        console.log(action)
        state.uploadGeneralDoc[action.payload.docKey] = {
          data: null,
          error: action.payload.error
        }
      }) // deleteDocCall
      .addCase(deleteDocCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(deleteDocCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        console.log(action)
        state.uploadGeneralDoc[action.payload.docKey] = {
          data: action.payload.response,
          error: null
        }
      })
      .addCase(deleteDocCall.rejected, (state, action) => {
        state.pending = false
        console.log(action)
        state.uploadGeneralDoc[action.payload.docKey] = {
          data: null,
          error: action.payload.error
        }
      })
})

export default appUploadDocuments.reducer
