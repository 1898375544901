import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { APPLICATION_STEP } from '../../options'

const CurrentApplication = () => {
  const navigate = useNavigate()
  const { applicationStep } = useSelector((state) => state.globalApplication)

  return (
    <button onClick={() => navigate(APPLICATION_STEP[applicationStep].path)}>
      CurrentApplication
    </button>
  )
}

export default CurrentApplication
