import React, { useState } from 'react'
import ModalContainer from '../AtomicDesign/atoms/ModalContainer/ModalContainer'
import { Button, Typography } from '@mui/material'
import { useMediaQuery } from '@material-ui/core'
import { useStyles } from './StartCreditCardModalStyle'
import StartCreditCard from '../../assets/Images/start-credit-card.png'
import { useNavigate } from 'react-router-dom'
import { Interceptor } from '../../Utils/Interceptor'
import toast, { Toaster } from 'react-hot-toast'
import jwtDecode from 'jwt-decode'

const getReferenceNumber = () => {
  const token = sessionStorage.getItem('access_token')
  if (token) {
    const decoded = jwtDecode(token)
    return decoded.referenceNumber
  }
  return null
}

const StartCreditCardModal = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const axiosInterceptor = Interceptor()

  const isSmallMidScreen = useMediaQuery('(max-width: 872px)')

  const [openModal, setOpenModal] = useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setOpenModal(true)
    }, 3200)
  }, [])

  const handleAccept = () => {
    const referenceNumber = getReferenceNumber()
    const codeU2f = sessionStorage.getItem('code')

    if (!referenceNumber || !codeU2f) {
      return toast.error('Unexpected error occurred, try again later.')
    }

    axiosInterceptor
      .post('/credit-card/create-ola-cc-application', { referenceNumber })
      .then((response) => {
        if (response.error) {
          return toast.error('Unexpected error occurred, try again later.')
        }
        setOpenModal(false)
        navigate(
          `/credit-card-application/?q=${sessionStorage.getItem('code')}`
        )
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.message ===
            'Customer already has a Credit Card Application.'
        ) {
          setOpenModal(false)

          return toast.success(
            'You already has a Credit Card Application. Go to Credit Card Options in order to continue with your process',
            {
              duration: 4200
            }
          )
        }

        toast.error('Unexpected error occurred, try again later.')
      })
  }

  const handleReject = () => {
    const codeU2f = sessionStorage.getItem('code')

    if (!!codeU2f) {
      setOpenModal(false)
      navigate(`/?q=${sessionStorage.getItem('code')}`)
    } else {
      return toast.error('Unexpected error occurred, try again later.')
    }
  }

  return (
    <>
      <ModalContainer
        open={openModal}
        width={824}
        onClose={() => {
          setOpenModal(false)
        }}
      >
        <div className={classes.modalWrapper}>
          <div className={classes.container}>
            {!isSmallMidScreen && (
              <div className={classes.imageContainer}>
                <img
                  src={StartCreditCard}
                  alt='credit cards'
                  className={classes.image}
                />
              </div>
            )}

            <div className={classes.contentContainer}>
              <Typography className={classes.title}>
                We have a credit card available for you
              </Typography>

              <Typography className={classes.description}>
                You can start enjoying all the{' '}
                <span className={classes.benefitsHighlight}>benefits</span> of
                having a Belize Bank credit card. Simply complete the following
                quick and easy e-application and we'll process it together with
                your loan.
              </Typography>

              <Button className={classes.primaryButton} onClick={handleAccept}>
                YES, I WISH TO PROCEED
              </Button>
              <Button
                className={classes.secondaryButton}
                onClick={handleReject}
              >
                NO, I'LL CONSIDER IT LATER
              </Button>
            </div>
          </div>
        </div>
      </ModalContainer>

      <Toaster />
    </>
  )
}

export default StartCreditCardModal
