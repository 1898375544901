import axios from 'axios'
import { endpoints } from './endpoints.js'

export default class JwtService {
  #jwtConfig = { ...endpoints }

  #isAlreadyFetchingAccessToken = false

  #subscribers = []
  constructor(jwtOverrideConfig = {}) {
    this.#jwtConfig = { ...this.#jwtConfig, ...jwtOverrideConfig }

    axios.interceptors.request.use(
      (config) => {
        const accessToken = this.#getToken()

        if (accessToken) {
          config.headers.Authorization = `${
            this.#jwtConfig.tokenType
          } ${accessToken}`

          config.headers['X-CustomH-credit-card'] = 'credit'
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    axios.interceptors.response.use(
      (response) => {
        if (
          response &&
          response.data &&
          response.data.jwt &&
          response.data.jwt.length > 0
        ) {
          this.setToken(response.data.jwt)
        }

        return response
      },
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          console.info('Your session has expired! Please log in again.')
          if (!this.#isAlreadyFetchingAccessToken) {
            this.#isAlreadyFetchingAccessToken = true
            this.getRefreshToken().then((r) => {
              this.#isAlreadyFetchingAccessToken = false

              // ** Update accessToken in store
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          return new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.#jwtConfig.tokenType
              } ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })
        }
        return Promise.reject(error)
      }
    )
  }

  #getToken() {
    return sessionStorage.getItem(this.#jwtConfig.tokenKeyName)
  }

  onAccessTokenFetched(accessToken) {
    this.#subscribers = this.#subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.#subscribers.push(callback)
  }

  getRefreshToken() {
    return sessionStorage.getItem(this.#jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    sessionStorage.setItem(this.#jwtConfig.tokenKeyName, value)
  }

  setRefreshToken(value) {
    sessionStorage.setItem(this.#jwtConfig.storageRefreshTokenKeyName, value)
  }

  #simulationHTTP(payload) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: { ...payload } })
      }, 2000)
    })
  }

  // ! Services credit-card (START)
  authenticate(token) {
    return axios.post(this.#jwtConfig.authentication, token, {
      headers: {
        'Content-Type': 'application/json',
        // allow * for CORS
        // 'Access-Control-Allow-Origin': '*',
        Authorization: ``
      }
    })
    // return this.#simulationHTTP({
    //   token
    // })
  }

  hasApplicationInProgress() {
    return axios.get(this.#jwtConfig.application)
  }

  getApplicationDocs() {
    return axios.get(this.#jwtConfig.applicationDocsGET)
  }

  getADTypesList() {
    return axios.get(this.#jwtConfig.applicationDocsTypesListGET)
  }

  postApplicationDocs(body) {
    return axios.post(this.#jwtConfig.applicationDocsPOST, body)
  }

  deleteApplicationDocs(body) {
    // DELETE has no 'body' like POST or PUT
    return axios.delete(this.#jwtConfig.applicationDocsDELETE, { data: body })
  }

  personalInformationReview1() {
    return axios.get(this.#jwtConfig.personalInformationReviewGET)
  }

  pirCheckPhone(body) {
    return axios.post(this.#jwtConfig.pirCheckPhonePOST, body)
  }

  pirConfirmationPhone(body) {
    return axios.post(this.#jwtConfig.pirConfirmationPhonePOST, body)
  }

  pirResendCodePhone() {
    return axios.post(this.#jwtConfig.pirResendCodePhonePOST)
  }

  pirCheckEmail(body) {
    return axios.post(this.#jwtConfig.pirCheckEmailPOST, body)
  }

  pirConfirmationEmail(body) {
    return axios.post(this.#jwtConfig.pirConfirmationEmailPOST, body)
  }

  pirResendCodeEmail() {
    return axios.post(this.#jwtConfig.pirResendCodeEmailPOST)
  }

  pirUploadSSNDocument(body) {
    return axios.post(this.#jwtConfig.pirUploadSSNPOST, body)
  }

  getAdditionalApplicantsInfo() {
    return axios.get(this.#jwtConfig.additionalApplicantsGET)
  }

  getApplicantsTypeCatalog() {
    return axios.get(this.#jwtConfig.applicantTypeCatalogGET)
  }

  getDistrictsCatalog() {
    return axios.get(this.#jwtConfig.districtsCatalogGET)
  }

  getUrbanAreasCatalog(param) {
    return axios.get(`${this.#jwtConfig.urbanAreasCatalogGET}/${param}`)
  }

  getRelationshipsCatalog() {
    return axios.get(this.#jwtConfig.relationshipsCatalogGET)
  }

  getCountriesCatalog() {
    return axios.get(this.#jwtConfig.countriesCatalogGET)
  }

  getStatesCatalog(param) {
    return axios.get(`${this.#jwtConfig.statesCatalogGET}/${param}`)
  }

  getOccupantsCatalog() {
    return axios.get(this.#jwtConfig.occupantsCatalogGET)
  }

  getTitlePersonCatalog() {
    return axios.get(this.#jwtConfig.titlePersonCatalogGET)
  }

  postAdditionalApplicants(body) {
    return axios.post(this.#jwtConfig.additionalApplicantsPOST, body)
  }

  updateAdditionalApplicants(body) {
    return axios.put(this.#jwtConfig.additionalApplicantsPUT, body)
  }

  getCreditCards() {
    return axios.get(this.#jwtConfig.creditCards)
  }

  gerCardDetailsById(id) {
    return axios.get(`${this.#jwtConfig.cardDetailsById}/${id}`)
  }

  startApplication(params) {
    return axios.post(this.#jwtConfig.application, params)
  }

  getApplicationStep() {
    return axios.get(`${this.#jwtConfig.application}/step`)
  }

  updateApplicationStep(step) {
    return axios.post(`${this.#jwtConfig.application}/step`, { step })
  }

  getCreditLimitRequestData() {
    return axios.get(this.#jwtConfig.limitRequest)
  }

  getCreditLimitRequestPromoCode(promoCode) {
    if (!promoCode) {
      console.error('Promo code is required')
      return
    }

    return axios.get(`${this.#jwtConfig.promoCode}/${promoCode}`)
  }

  getCreditLimitRequestSelectedCard(cardId) {
    return axios.get(`${this.#jwtConfig.creditCards}/${cardId}`)
  }

  getInsuranceList() {
    return axios.get(this.#jwtConfig.insurance)
  }

  getSelectedCardID() {
    return axios.get(this.#jwtConfig.selectedCardID)
  }

  createCreditLimitRequest(requestData) {
    return axios.post(this.#jwtConfig.limitRequest, requestData)
  }

  updateCreditLimitRequest(requestData) {
    return axios.put(this.#jwtConfig.limitRequest, requestData)
  }

  updateCurrentCardTypeInApplication(requestData) {
    return axios.put(this.#jwtConfig.updateCredtiCardType, requestData)
  }

  getMonthlyIncome() {
    return axios.get(this.#jwtConfig.monthlyIncome)
  }

  createMonthlyIncome(data) {
    return axios.post(this.#jwtConfig.monthlyIncome, data)
  }

  updateMonthlyIncome(data) {
    return axios.put(this.#jwtConfig.monthlyIncome, data)
  }

  getMyObligations() {
    return axios.get(`${this.#jwtConfig.monthlyExpenses}/bbl-obligations`)
  }

  getMonthlyExpensesTypes() {
    return axios.get(`${this.#jwtConfig.monthlyExpenses}/types-list`)
  }

  getMyMonthlyExpenses() {
    return axios.get(this.#jwtConfig.monthlyExpenses)
  }

  createExpenseRecord(data) {
    return axios.post(`${this.#jwtConfig.monthlyExpenses}/create`, data)
  }

  updateExpenseRecord(data) {
    return axios.put(`${this.#jwtConfig.monthlyExpenses}`, data)
  }

  deleteExpenseRecord(params) {
    return axios.delete(`${this.#jwtConfig.monthlyExpenses}/delete`, { data: params })
  }

  getProofOfIncome() {
    return axios.get(this.#jwtConfig.proofOfIncomeGET)
  }

  getSalaryCatalog(filter = '') {
    return axios.get(this.#jwtConfig.salaryCatalogGET + filter)
  }

  getJobLetterCatalog(filter = '') {
    return axios.get(this.#jwtConfig.jobLetterCatalogGET + filter)
  }

  getBankCatalog(filter = '') {
    return axios.get(this.#jwtConfig.externalBankCatalogGET + filter)
  }

  postProofOfIncome(body) {
    return axios.post(this.#jwtConfig.proofOfIncomePOST, body)
  }

  updateProofOfIncome(body) {
    return axios.put(this.#jwtConfig.proofOfIncomePUT, body)
  }

  getADMissingDocs() {
    return axios.get(this.#jwtConfig.adMissingDocsGET)
  }

  getCustomerHasSSB() {
    return axios.get(this.#jwtConfig.customerHasSSBGET)
  }

  getADCheckRequirements() {
    return axios.get(this.#jwtConfig.adCheckRequirementsGET)
  }

  getADAssignLender() {
    return axios.get(this.#jwtConfig.adAssignLenderGET)
  }

  getADComplete() {
    return axios.get(this.#jwtConfig.adCompleteGET)
  }

  // ! Services credit-card (END)
}
