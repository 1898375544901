import React, { useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import VerificationInput from '../Inputs/VerificationInput'
import Image from '../../../assets/CCImages/shield.png'
import { useStyles } from './OtpPopUpStyle.js'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import PopupAlert from './PopupAlert'
import Dialog from '@mui/material/Dialog'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import AlertInfo from '../Alert'
import {
  checkPhoneCall,
  confirmationPhoneCall,
  resendCodePhoneCall
} from '../../Pages/PersonalInformationReviewForm/store/index.js'

export const ConfirmOTP = ({
  open = false,
  setOpenDialog,
  newPhoneNumber,
  refreshData
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [pin, setPin] = useState('')

  const { pending, error } = useSelector(
    (state) => state.personalInformationReview.checkPhoneDialog
  )

  const { dataPhone } = useSelector(
    (state) => state.personalInformationReview.application
  )

  useEffect(() => {
    if (open) {
      dispatch(checkPhoneCall({ body: { phone: newPhoneNumber } }))
    }
  }, [open])

  useEffect(() => {
    if (error.message && error.message.length > 0 && !pending) {
      setTimeout(() => {
        setOpenDialog(false)
      }, 2400)
    }
  }, [error])

  useEffect(() => {
    if (dataPhone.verifiedPhone && !pending) {
      setTimeout(() => {
        setOpenDialog(false)
      }, 2400)
    }
  }, [dataPhone])

  const handleResendCode = () => {
    dispatch(resendCodePhoneCall()).then((res) => {
      if (res && res.payload && res.payload.sent) {
        toast.success('The code resend was successful', {
          duration: 4200
        })
      }
    })
  }

  const handleConfirmCode = () => {
    dispatch(confirmationPhoneCall({ body: { code: pin } })).then((res) => {
      if (res && res.payload && res.payload.verifiedPhone) {
        refreshData()
        toast.success('Phone number successfully verified', {
          duration: 4200
        })
      }
    })
  }

  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={open}
      onClose={() => setOpenDialog(false)}
      aria-labelledby='draggable-dialog-title'
    >
      {!pending && !dataPhone.verifiedPhone && !(error.message.length > 0) ? (
        <Box>
          {/* ni idea para que era este pop-up */}
          {/* <PopupAlert newPhone={newPhoneNumber} previousNumber={data.phone} /> */}
          <Box className={classes.PopupContainer}>
            <Box className={classes.PopupHeader}></Box>

            <Box className={classes.PopupMainSection}>
              <Box className={classes.PopupHeaderLogo}>
                <img className={classes.Logo} src={Image} alt='Logo' />
              </Box>

              <Typography className={classes.PopupTitle}>
                Phone number confirmation required
              </Typography>

              <Typography className={classes.PopupText}>
                We need to validate your phone number as it will be required to
                complete the application. An text confirmation was sent to the
                phone number provided at the beginning of your application.
              </Typography>

              <Box className={classes.PopupExpireTime}>
                <Typography className={classes.ExpireTimeText}>
                  Enter the confirmation code now.
                </Typography>
              </Box>

              <VerificationInput
                length={6}
                onChange={(e) => setPin(e)}
                sx={{
                  borderRadius: '8px',
                  fontSize: '16px'
                }}
              />

              {/* <Box className={classes.PopupExpireTime}>
                <img className={classes.ClockImage} src={clock} alt="clock" />
                <Typography className={classes.ExpireTimeText}>
                  The code will expire in 15 minutes
                </Typography>
              </Box> */}

              <Box className={classes.PopupBtn} style={{ marginTop: '2rem' }}>
                <Button
                  disabled={pin.length < 6}
                  className={classes.PopupButton}
                  onClick={handleConfirmCode}
                >
                  VALIDATE CODE
                </Button>
              </Box>

              <Box
                style={{ margin: '0' }}
                className={classes.PopupExpireTime}
                onClick={handleResendCode}
              >
                <Typography
                  className={classes.ExpireTimeText}
                  style={{
                    textDecoration: 'underline',
                    fontWeight: '700',
                    cursor: 'pointer'
                  }}
                >
                  RESEND CODE
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box className={classes.PopupContainer}>
            <Box className={classes.PopupMainSection}>
              {pending && <p>...</p>}

              {error.message.length > 0 && (
                <AlertInfo
                  content={error.message}
                  style={{ marginBottom: 0 }}
                />
              )}

              {dataPhone.verifiedPhone && (
                <AlertInfo
                  content={'The phone number has been verified'}
                  severity='success'
                  style={{ marginBottom: 0 }}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Dialog>
  )
}

const ForgetPassword = ({
  phoneNumber: lockPhoneNumber,
  onSuccessfulLogin,
  goBackUrl
}) => {
  const theme = useTheme()
  const [sentCode, setSentCode] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')

  const changePassword = async (pin) => {}

  const sendCode = async (resend, password) => {}

  return (
    <>
      <ConfirmOTP
        sendCode={sendCode}
        onSuccessfulLogin={onSuccessfulLogin}
        cancel={() => setSentCode(false)}
        phoneNumber={phoneNumber}
        submit={changePassword}
      />
    </>
  )
}

export default ForgetPassword
