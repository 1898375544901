import React, { useEffect, useState } from 'react'
import ProgressBar from '@ramonak/react-progress-bar'
import { useStyles } from './ProgressBarStyle'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

const Progress = ({ progrress }) => {
  const [ProgressValue, setProgressValue] = useState(0)
  const classes = useStyles()
  const [path, setPath] = useState(window.location.pathname)

  useEffect(() => {
    setPath(window.location.pathname)
    let progressNumber = progrress.toString()
    progressNumber = parseInt(progressNumber)

    setProgressValue(progressNumber)
  }, [progrress])

  useEffect(() => {
    if (window.location.pathname === '/credit-card/personal-information-review')
      setProgressValue(0)
  }, [path])

  return (
    <Box>
      {/* <Typography variant="subtitle1" className={classes.CompleteText}>
        COMPLETE
      </Typography> */}
      <ProgressBar
        completed={ProgressValue}
        className={classes.ProgressBar}
        labelClassName={classes.Lable}
        height='8px'
        baseBgColor='#D9D9D9'
        bgColor='#275DA6'
        borderRadius='0px'
        isLabelVisible={false}
        // barContainerClassName={classes.barContainer}
      />
    </Box>
  )
}

export default Progress
