import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../../auth/jwt/JwtCreditCard'

const initialError = (message = '') => ({
  statusCode: null,
  name: '',
  message
})

export const customerHasSSBCall = createAsyncThunk(
  'appStepUploadMissingDocs/getCustomerHasSSB',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getCustomerHasSSB()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const adMissingDocsCall = createAsyncThunk(
  'appStepUploadMissingDocs/getADMissingDocs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getADMissingDocs()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const adCheckRequirementCall = createAsyncThunk(
  'appStepUploadMissingDocs/getADCheckRequirements',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getADCheckRequirements()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const adAssignLenderCall = createAsyncThunk(
  'appStepUploadMissingDocs/getADAssignLender',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getADAssignLender()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const adCompleteCall = createAsyncThunk(
  'appStepUploadMissingDocs/getADComplete',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getADComplete()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const appStepUploadMissingDocs = createSlice({
  name: 'appStepUploadMissingDocs',
  initialState: {
    pending: true,
    error: initialError(),
    customerHasSSB: null,
    missingDocs: [],
    checkRequirements: null,
    assignLender: null,
    complete: null
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(customerHasSSBCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(customerHasSSBCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        state.customerHasSSB = action.payload
      })
      .addCase(customerHasSSBCall.rejected, (state, action) => {
        state.pending = false
        state.error = action.payload
      }) // adMissingDocsCall
      .addCase(adMissingDocsCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(adMissingDocsCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        state.missingDocs = action.payload
      })
      .addCase(adMissingDocsCall.rejected, (state, action) => {
        state.pending = false
        state.error = action.payload
      }) // adCheckRequirementCall
      .addCase(adCheckRequirementCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(adCheckRequirementCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        state.checkRequirements = action.payload
      })
      .addCase(adCheckRequirementCall.rejected, (state, action) => {
        state.pending = false
        state.error = action.payload
      }) // adAssignLenderCall
      .addCase(adAssignLenderCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(adAssignLenderCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        state.assignLender = action.payload
      })
      .addCase(adAssignLenderCall.rejected, (state, action) => {
        state.pending = false
        state.error = action.payload
      }) // adCompleteCall
      .addCase(adCompleteCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(adCompleteCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        state.complete = action.payload
      })
      .addCase(adCompleteCall.rejected, (state, action) => {
        state.pending = false
        state.error = action.payload
      })
})

export default appStepUploadMissingDocs.reducer
