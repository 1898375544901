import React from 'react'
import { Card, Typography } from '@mui/material'
import CheckMark from '.././../../../assets/CCImages/check.png'
import NotAvailable from '.././../../../assets/CCImages/cancel.png'
// import { BusinessCreditCardList } from '../auxiliarData/businessCreditCardInfo'
import { useSelector } from 'react-redux'

const Business = () => {
  const { tableComparison, comparison } = useSelector(
    (state) => state.comparison
  )
  return (
    <>
      {/* features */}
      <Card style={{ paddingBottom: '2rem', margin: '2rem 0' }}>
        <Typography
          style={{ paddingLeft: '1rem', paddingTop: '1.5rem' }}
          color={'#275DA6'}
          fontSize={24}
          fontWeight={700}
        >
          Compare Features
        </Typography>

        <hr />
        {/* 'repeat(4, 1fr)' */}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${comparison.length + 1}, 1fr)`
          }}
        >
          {tableComparison.features.map((feature, idx) => (
            <React.Fragment key={idx}>
              <div style={{ textAlign: 'initial' }}>
                <Typography
                  style={{
                    padding: '1rem 0',
                    backgroundColor: idx % 2 ? '#F4F4F4' : '#fff'
                  }}
                  color={'#868686'}
                  fontSize={12}
                >
                  {feature.title}
                </Typography>
              </div>
              {feature.values.map((value, idy) => (
                <div style={{ textAlign: 'center' }} key={idy}>
                  <Typography
                    style={{
                      padding: '1rem 0',
                      backgroundColor: idx % 2 ? '#F4F4F4' : '#fff'
                    }}
                    color={'#868686'}
                    fontSize={12}
                  >
                    {value}
                  </Typography>
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </Card>

      {/* benefits */}
      <Card style={{ paddingBottom: '2rem' }}>
        <Typography
          style={{ paddingLeft: '1rem', paddingTop: '1.5rem' }}
          color={'#275DA6'}
          fontSize={24}
          fontWeight={700}
        >
          Benefits
        </Typography>

        <hr />

        <div style={{ display: 'grid', gridTemplateColumns: `repeat(${comparison.length + 1}, 1fr)` }}>
          {tableComparison.benefits.map((benefit, idx) => (
            <React.Fragment key={idx}>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  style={{
                    padding: '1rem 0',
                    backgroundColor: idx % 2 ? '#F4F4F4' : '#fff'
                  }}
                  color={'#868686'}
                  fontSize={12}
                >
                  {benefit.title}
                </Typography>
              </div>
              {benefit.values.map((value, idy) => (
                <div style={{ textAlign: 'center' }} key={idy}>
                  <Typography
                    style={{
                      padding: '1rem 0',
                      backgroundColor: idx % 2 ? '#F4F4F4' : '#fff'
                    }}
                    color={'#868686'}
                    fontSize={12}
                  >
                    {value ? (
                      <img width={16} src={CheckMark} alt='' />
                    ) : (
                      <img width={12} src={NotAvailable} alt='' />
                    )}
                  </Typography>
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </Card>
    </>
  )
}

export default Business
