import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  ButtonTitle: {
    backgroundColor: '#0066A4',
    color: 'white',
    width: '100%',
    padding: '0.9rem 0',
    textTransform: 'capitalize',
    fontSize: '24px',
    marginBottom: '3rem',
    textAlign: 'center',
    borderRadius: '8px',
    fontFamily: theme.fonts.family.primary,
    '&:hover': {
      backgroundColor: '#0066A4',
      color: 'white'
    }
  },
  Container: {
    width: '100%',
    marginBottom: '186px'
  },
  CreditCardLimitContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '8px',
    marginBottom: '4rem',
    '@media (max-width: 945px)': {
      flexWrap: 'wrap'
    }
  },
  CreditCardInfo: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #BDBDBD',
    borderRadius: '8px',
    padding: '1rem 0',
    minWidth: '324px',
    '@media (max-width: 364px)': {
      padding: '1.2rem',
      minWidth: 'unset'
    }
  },
  CreditCardInfoBody: {
    padding: '0 1.35rem'
  },
  CreditCardLimit: {
    maxWidth: 824,
    minWidth: 464,
    margin: '0 1rem',
    flexGrow: 1,
    '@media (max-width: 945px)': {
      minWidth: 'unset',
      width: '98%',
      margin: '16px 1rem 0 1rem'
    }
  },
  PercentageLimit: {
    width: '100%',
    border: '1px solid rgba(0,0,0,0.5)',
    borderRadius: '8px',
    textAlign: 'center',
    padding: '0.85rem 0',
    margin: '1rem 0'
  },
  InputPromoCode: {
    border: '1px solid rgba(0,0,0,0.5)',
    width: '100%',
    maxWidth: '285px',
    height: '40px',
    borderRadius: '8px'
  },
  BtnPromoCode: {
    backgroundColor: '#0066A4',
    color: 'white',
    borderRadius: '1.5rem',
    fontSize: '17px',
    fontWeight: '600',
    textTransform: 'capitalize',
    width: '156px',
    height: '40px',
    marginLeft: '1rem',
    '&.Mui-disabled': {
      backgroundColor: '#d3d3d3',
      color: '#a9a9a9'
    }
  },
  BtnConfirm: {
    backgroundColor: '#0066A4',
    color: '#fff',
    borderRadius: '2rem',
    padding: '7px 32px',
    minWidth: '165px',
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #0066A4',
      color: '#0066A4'
    },
    '&.Mui-disabled': {
      backgroundColor: '#d3d3d3',
      color: '#a9a9a9'
    }
  },
  BtnReject: {
    backgroundColor: '#fff',
    border: '1px solid #0066A4',
    color: '#0066A4',
    borderRadius: '2rem',
    padding: '7px 32px',
    minWidth: '165px',
    '&:hover': {
      backgroundColor: '#0066A4',
      color: '#fff'
    }
  },
  resumeSpan: {
    marginLeft: 4,
    color: '#091242',
    fontSize: 15,
    fontWeight: 'bolder',
    textTransform: 'uppercase'
  },
  InsuranceContainer: {
    display: 'flex',
    gap: '1.5rem',
    borderRadius: '8px',
    padding: '1rem',
    marginTop: '2rem',
    position: 'relative',
    cursor: 'pointer',
    '@media (max-width: 636px)': {
      gap: '1.2rem',
      padding: '25px 10px 10px 10px',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& img': {
      width: 272,
      objectFit: 'cover',
      borderRadius: 12,
      '@media (max-width: 636px)': {
        width: 212
      }
    }
  },
  ContainerCenterResponsive: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 945px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    gap: 12
  },
  WidthFullToUnset: {
    width: '100%',
    '@media (max-width: 945px)': {
      width: 'auto'
    }
  }
}))
