import { Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { personalCreditCardAdapter } from '../auxiliarData/personalCreditCardInfo'
import CloseIcon from '../../../../assets/CCImages/close.png'
import { useSelector } from 'react-redux'

const CreditCardsSelected = ({
  selectedCC,
  setComparisson,
  setSelectedCC
}) => {

  const handleOnClickRemoveItem = (item, e) => {
    setSelectedCC((oldValues) => {
      return oldValues.filter((current) => current !== item.id)
    })
  }

  const { catalog } = useSelector((state) => state.availableCreditCard)

  const [cards, setCards] = useState([])

  useEffect(() => {
    if (catalog) {
      const cards = personalCreditCardAdapter(catalog.options)
      setCards(cards)
    } else setCards([])
  }, [catalog])

  return (
    <div
      style={{
        height: '146px',
        background: '#fff',
        position: 'fixed',
        width: '100%',
        bottom: '0',
        boxShadow: '3px 4px 8px rgba(0,0,0,0.25)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          paddingLeft: '1rem'
        }}
      >
        {selectedCC.length > 0 &&
          cards
            .filter((cc) => selectedCC.includes(cc.id))
            .map((selected) => (
              <div
                key={selected.id}
                style={{
                  width: '261px',
                  height: '88px',
                  border: '1px solid #D9D9D9',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '1rem',
                  padding: '0.5rem',
                  position: 'relative'
                }}
              >
                <img
                  onClick={(e) => handleOnClickRemoveItem(selected, e)}
                  style={{ position: 'absolute', top: '-10%', right: '-3%' }}
                  width={24}
                  src={CloseIcon}
                  alt=''
                />
                <img width={86} src={selected.cardImage} alt='' />
                <Typography>{selected.title}</Typography>
              </div>
            ))}

        {selectedCC.length < 3 && (
          <div
            style={{
              width: '261px',
              height: '88px',
              border: '3px dashed #D9D9D9',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography>Add card to comparison</Typography>
          </div>
        )}
      </div>
      {selectedCC.length > 1 && (
        <Button
          style={{
            marginRight: '1rem',
            background: '#005FAA',
            width: '250px',
            height: '34px',
            color: '#fff',
            borderRadius: '2rem'
          }}
          onClick={() => setComparisson(true)}
        >
          Compare Now
        </Button>
      )}
    </div>
  )
}

export default CreditCardsSelected
