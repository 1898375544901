import React, { useEffect, useRef, useState } from 'react'
import { Alert, Box, Typography } from '@mui/material'
import Button from '../../Components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './UploadDocumentsStyle'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import {
  adAssignLenderCall,
  adCheckRequirementCall,
  adCompleteCall,
  adMissingDocsCall,
  customerHasSSBCall
} from './store'
import { UPLOAD_DOCUMENTS } from '../../options'
import ClearIcon from '@mui/icons-material/Clear'
import ArrowButton from '../../Components/ArrowButton/ArrowButton'
import { deleteDocCall, uploadDocCall } from '../../StoreCC/upload-store'

const fileInitialInfo = {
  filename: null,
  error: null
}

const UploadDocumentsCC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const ssnFileRef = useRef([])
  const [ssnFileInput, setSsnFileInput] = useState(fileInitialInfo)

  const fileRefs = useRef({})
  const [missingDocsInputs, setMissingDocsInputs] = useState({})

  const {
    customerHasSSB,
    missingDocs,
    checkRequirements,
    assignLender,
    complete,
    pending,
    error
  } = useSelector((state) => state.ccUploadDocuments)

  const {
    pending: uploadPending,
    uploadGeneralDoc,
    uploadSSNDoc
  } = useSelector((state) => state.ccApplicationDocuments)

  useEffect(() => {
    window.scroll(0, 0)

    dispatch(customerHasSSBCall())
    dispatch(adMissingDocsCall())
    dispatch(adCheckRequirementCall())

    // dispatch(adAssignLenderCall())
    // dispatch(adCompleteCall())
  }, [])

  useEffect(() => {
    // TODO: manage responses an errors here
    console.log(uploadGeneralDoc)
    console.log(uploadSSNDoc)
  }, [uploadGeneralDoc, uploadSSNDoc])

  // useEffect(() => {
  //   window.onpopstate = (e) => {
  //     window.history.pushState(null, '')
  //   }
  //   window.history.pushState(null, '')
  // }, [])

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const ssnFileRefClick = () => {
    setSsnFileInput(fileInitialInfo)
    ssnFileRef.current.click()
  }

  const handleSsnFileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      let currentStateFile = { filename: file.name, error: null }

      if (file.size > 2 * 1024 * 1024) {
        currentStateFile = { filename: null, error: 'File exceeds 2MB limit' }
      } else {
        const base64File = await convertBase64(file)
        // TODO: UPLOAD SSN CARD FILE HERE
        console.log({
          ssnDocumentFile: base64File,
          identificationNumber: 'no tengo esto valor en este momento, preguntar'
        })
      }

      setSsnFileInput(currentStateFile)
    }
  }

  const handleFileClick = (docKey) => {
    setMissingDocsInputs((prev) => ({
      ...prev,
      [docKey]: fileInitialInfo
    }))

    fileRefs.current[docKey]?.click()
  }

  const handleFileChange = async (event, itemFromMissingDoc) => {
    const file = event.target.files[0]
    if (file) {
      let currentStateFile = { filename: file.name, error: null }

      if (file.size > 2 * 1024 * 1024) {
        currentStateFile = { filename: null, error: 'File exceeds 2MB limit' }
      } else {
        const base64File = await convertBase64(file)

        dispatch(
          uploadDocCall({
            documentType: itemFromMissingDoc.docKey,
            documentName: currentStateFile.filename,
            module: itemFromMissingDoc.module,
            moduleId: itemFromMissingDoc.id,
            documentFile: base64File
          })
        )
      }

      setMissingDocsInputs((prev) => ({
        ...prev,
        [itemFromMissingDoc.docKey]: currentStateFile
      }))
    }
  }

  const handleDeleteFile = async (itemFromMissingDoc) => {
    dispatch(
      deleteDocCall({
        documentType: itemFromMissingDoc.docKey,
        module: itemFromMissingDoc.module,
        id: itemFromMissingDoc.id
      })
    )

    setMissingDocsInputs((prev) => ({
      ...prev,
      [itemFromMissingDoc.docKey]: { filename: null, error: 'File is requried' }
    }))
  }

  const handleNext = () => {
    // TODO: hacer las validaciones necesarias
    dispatch(adCheckRequirementCall())
  }

  return (
    <Box>
      <div className={classes.ButtonTitle}>Upload Documents</div>

      <Alert
        icon={false}
        sx={{
          backgroundColor: '#FFD6D6',
          padding: '20px',
          marginBottom: '24px'
        }}
      >
        <strong>Dear {localStorage.getItem('name') || 'Client'},</strong>
        <br />
        If you have any pending documents to upload prior to the final
        submission, please feel free to resume your application as soon as you
        have obtained those documents. Rest assured that all your information
        and uploaded documents have been saved.
      </Alert>

      <Typography className={classes.SelectLable}>
        Please complete the following
      </Typography>

      <Typography className={classes.errorMessage}>
        * Mandatory Upload
      </Typography>

      {customerHasSSB &&
        customerHasSSB.hasSsb === false &&
        customerHasSSB.identificationNumber !== null && (
          <div style={{ margin: '24px 0' }}>
            <Typography className={classes.SelectLable}>
              Social Security Card*
            </Typography>

            <Button
              Title={`Please Upload Your Social Security Card Document File`}
              variant='h7'
              className={classes.UploadFiles}
              endIcon={<AttachFileIcon />}
              onClick={ssnFileRefClick}
            />

            <div>
              <input
                type='File'
                accept='application/pdf,image/*'
                value=''
                style={{ display: 'none' }}
                ref={ssnFileRef}
                onChange={(event) => {
                  handleSsnFileChange(event)
                }}
              />

              <Typography className={classes.UploadFileText}>
                Only pdf documents are permitted with a maximum of 2MB
              </Typography>

              {ssnFileInput && ssnFileInput.error ? (
                <Typography className={classes.errorMessage}>
                  {ssnFileInput.error}
                </Typography>
              ) : (
                ssnFileInput &&
                ssnFileInput.filename && (
                  <p className={classes.uploadFileName}>
                    {`${String(ssnFileInput.filename).substring(0, 32)}...`}
                  </p>
                )
              )}
            </div>
          </div>
        )}

      {missingDocs.map((item, indx) => (
        <div key={indx} style={{ margin: '24px 0' }}>
          <Typography className={classes.SelectLable}>
            {UPLOAD_DOCUMENTS[item.docKey].name}*
          </Typography>

          <Button
            Title={`Please Upload Your ${
              UPLOAD_DOCUMENTS[item.docKey].name
            } Document File`}
            variant='h7'
            className={classes.UploadFiles}
            endIcon={<AttachFileIcon />}
            onClick={() => handleFileClick(item.docKey)}
          />

          <div>
            <input
              type='File'
              accept='application/pdf,image/*'
              value=''
              style={{ display: 'none' }}
              ref={(element) => (fileRefs.current[item.docKey] = element)}
              onChange={(event) => {
                handleFileChange(event, item)
              }}
            />

            <Typography className={classes.UploadFileText}>
              Only pdf documents are permitted with a maximum of 2MB
            </Typography>

            {missingDocsInputs[item.docKey] &&
            missingDocsInputs[item.docKey].error ? (
              <Typography className={classes.errorMessage}>
                {missingDocsInputs[item.docKey].error}
              </Typography>
            ) : (
              missingDocsInputs[item.docKey] &&
              missingDocsInputs[item.docKey].filename && (
                <p className={classes.uploadFileName}>
                  {`${String(missingDocsInputs[item.docKey].filename).substring(
                    0,
                    35
                  )}...`}
                  <span>
                    <ClearIcon
                      className={classes.deleteFileIcon}
                      onClick={() => {
                        handleDeleteFile(item)
                      }}
                    />
                  </span>
                </p>
              )
            )}
          </div>
        </div>
      ))}

      <ArrowButton back={'/credit-card/proof-of-income'} onClick={handleNext} />
    </Box>
  )
}

export default UploadDocumentsCC
