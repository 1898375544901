import React, { useEffect, useState } from 'react'
import { Interceptor } from '../../../Utils/Interceptor'
import { Box } from '@mui/material'
import ArrowButton from '../ArrowButton/ArrowButton'
import LoanApproved from './LoanApproved'
import MakeAppointment from './MakeAppointment'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
// import Congratulations from '../Congratulations/Congratulations'
import Actions from '../../../Store/Types'

const ScheduleAppointment = () => {
  const AxiosInterceptor = Interceptor()
  const [status, setStatus] = useState(null)
  const [Files, setFiles] = useState([])
  const dispatch = useDispatch()
  const countPage = useRef(0)
  const navigate = useNavigate()
  // useEffect(() => {
  //   if (countPage.current === 0) {
  //     countPage.current = 1
  //     window.scroll(0, 0)
  //     return
  //   }
  // }, [])
  // useEffect(() => {
  //   window.onpopstate = (e) => {
  //     window.history.pushState(null,'')
  //   }
  //   window.history.pushState(null,'')
  // }, [])
  // const checkStatus = async () => {
  //   await AxiosInterceptor.get("/customer/loan-status").then((response) => {
  //     setStatus(response.data.status)
  //   })
  // }
  // const getMissingDocs = async () => {
  //   await AxiosInterceptor.get("/schedule-appointment-missing-docs").then(
  //     (response) => {
  //       setFiles(response.data)
  //     }
  //   )
  // }
  // const getData = async () => {
  //   return Promise.all([
  //     checkStatus(),
  //     getMissingDocs()
  //   ])
  // }
  // useEffect(() => {
  //   async function fetchData() {
  //    let response = false
  //     if(Files.ssnImage && Files.proofOfAddressImage && Files.jobLetterImage && Files.loanPurposeDocs){
  //       await AxiosInterceptor.post("/update-loan-status/customer/send-to-review");
  //       response = true
  //    }
  //    return response
  //   }
  //   fetchData().then((value)=> {
  //     if(value == true) {
  //       navigate('/Congratulations')
  //     }
  //   });
  // }, [Files]);

  // useEffect(() => {
  //   dispatch({ type: Actions.LOADING, payload: true })
  //   getData().finally(() => {
  //     dispatch({ type: Actions.LOADING, payload: false })
  //   })
  // }, [])
  return (
    <Box>
      <LoanApproved />
      {/* <MakeAppointment/> */}

      {/* <ArrowButton
        // link="/Customer/UplaodDocuments"
        back="/Customer/UplaodDocuments"
      /> */}
    </Box>
  )
}

export default ScheduleAppointment
