import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { useStyles } from '../styles'
import { CardContent, Typography, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCardsAvaliable, updateCurrentCardType } from '../store'
import ModalContainer from '../../../../Components/AtomicDesign/atoms/ModalContainer/ModalContainer'
import RequestAAdvantageModal from '../../../Components/Modals/RequestAAdvantageModal'
import toast, { Toaster } from 'react-hot-toast'

const ChangeCardModal = ({ isOpen = false, setOpenModal }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isSmallScreen = useMediaQuery('(max-width:424px)')

  const { cardID, hasAad, allCards, pending, errors } = useSelector(
    (state) => state.creditLimitRequest
  )

  const [selectedCard, setSelectedCard] = useState(null)
  const [openAAModal, setOpenAAModal] = useState(false)

  React.useEffect(() => {
    dispatch(getAllCardsAvaliable())
  }, [])

  React.useEffect(() => {
    if (selectedCard) {
      if (selectedCard.requiredAA && hasAad === false) {
        setOpenAAModal(true)
      } else {
        onHandleSubmitChange(null)
      }
    }
  }, [selectedCard])

  React.useEffect(() => {
    if (
      errors &&
      errors.allCards &&
      errors.allCards.error &&
      errors.allCards.error.message
    ) {
      toast.error(
        'Something went wrong at Change Card, please try again later.'
      )
    }
  }, [errors?.allCards])

  const onHandleSubmitChange = (aadvantage) => {
    if (!selectedCard) return
    const toResponse = {
      creditCardId: selectedCard.id,
      aAdvantage: aadvantage || ''
    }

    dispatch(updateCurrentCardType(toResponse)).then((res) => {
      if (res && res.payload && res.payload.success) {
        window.location.reload()
      }
      setSelectedCard(null)
    })
  }

  return (
    <>
      <ModalContainer
        open={isOpen}
        width={'832px'}
        onClose={() => {
          setOpenModal(false)
        }}
      >
        <div
          style={{ padding: '1.8rem', overflowY: 'auto', maxHeight: '732px' }}
        >
          {pending ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <p>...</p>
            </div>
          ) : (
            <>
              <Typography
                textAlign={'center'}
                color={'#091242'}
                fontSize={22}
                fontWeight={700}
              >
                Change Card
              </Typography>

              <CardContent
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'start',
                  gap: '1.6rem',
                  flexWrap: 'wrap'
                }}
              >
                {allCards.map((card) => {
                  if (card.id === cardID) return null
                  return (
                    <div
                      key={card.id}
                      style={{
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        padding: '16px',
                        width: '272px',
                        flex: '1 1 auto',
                        minHeight: '250px',
                        maxHeight: '400px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        WebkitBoxShadow: '3px 1px 5px 0px rgba(0,0,0,0.32)',
                        boxShadow: '3px 1px 5px 0px rgba(0,0,0,0.32)'
                      }}
                    >
                      <h3 style={{ margin: '8px 0' }}>{card.title}</h3>

                      <img
                        src={card.cardImage}
                        alt={card.title}
                        style={{
                          width: !isSmallScreen ? 212 : 180,
                          height: !isSmallScreen ? 132 : 100,
                          borderRadius: '4px',
                          marginBottom: '10px'
                        }}
                      />

                      <Button
                        onClick={() => {
                          setSelectedCard(card)
                        }}
                        className={classes.BtnConfirm}
                      >
                        Change to this card
                      </Button>
                    </div>
                  )
                })}
              </CardContent>
              <div
                style={{
                  marginTop: 8,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Button
                  onClick={() => {
                    setOpenModal(false)
                  }}
                  className={classes.BtnReject}
                >
                  Cancel
                </Button>{' '}
              </div>
            </>
          )}
        </div>
      </ModalContainer>

      {openAAModal && (
        <RequestAAdvantageModal
          openModal={openAAModal}
          setOpenModal={(newVal) => {
            setOpenAAModal(newVal)
            setSelectedCard(null)
          }}
          startApplication={onHandleSubmitChange}
        />
      )}

      <Toaster />
    </>
  )
}

export default ChangeCardModal
