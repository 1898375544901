import React from 'react'
import { Stack } from '@mui/material'
import { InputLabel, Typography, Button } from '@material-ui/core'
import { useStyles } from './MonthlyExpensesStyle'
import Currency from '../../Components/Inputs/currency2'
import { useDispatch, useSelector } from 'react-redux'
import { getMyObligations } from './store'

const LoanWithUs = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { currentObligations } = useSelector((state) => state.ccMonthlyExpenses)

  React.useEffect(() => {
    dispatch(getMyObligations())
  }, [])

  return (
    <React.Fragment>
      <Stack mt={4}>
        <Typography className={classes.LoanWithUs}>
          LOANS AND OTHER CREDIT OBLIGATIONS WITH US.
        </Typography>

        <Currency
          className={classes.MonthlyCreditInput}
          value={currentObligations.cardTotal}
          readOnly
        />

        <Currency
          className={classes.MonthlyCreditInput}
          value={currentObligations.mortgageTotal}
          readOnly
        />

        <Currency
          className={classes.MonthlyCreditInput}
          value={currentObligations.overdraftTotal}
          readOnly
        />
        <Currency
          className={classes.MonthlyCreditInput}
          value={currentObligations.otherLnTotal}
          readOnly
        />
      </Stack>
    </React.Fragment>
  )
}

export default LoanWithUs
