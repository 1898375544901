import React from 'react'
import { Button, useMediaQuery } from '@material-ui/core'
import { useStyles } from './styles'
import { CardContent, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import ModalContainer from '../../../Components/AtomicDesign/atoms/ModalContainer/ModalContainer'

const SelectedCard = () => {
  const classes = useStyles()
  const isSmallScreen = useMediaQuery('(max-width:492px)')
  const isMidScreen = useMediaQuery('(max-width:1112px)')

  const { selectedCard } = useSelector((state) => state.creditLimitRequest)

  const [isOpenModalDetail, setIsOpenModalDetail] = React.useState(false)

  return (
    <React.Fragment>
      <div className={classes.CreditCardInfo}>
        {selectedCard.cardImage ? (
          <img
            style={{ margin: '0 auto 8px auto' }}
            width={isSmallScreen ? 224 : 289}
            src={selectedCard.cardImage}
            alt='selected card'
          />
        ) : (
          <div
            style={{
              width: isSmallScreen ? 224 : 289,
              height: isSmallScreen ? 224 : 132,
              margin: '0 auto',
              color: 'gray',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <p>...</p>
          </div>
        )}
        <div className={classes.CreditCardInfoBody}>
          <Typography  fontSize={14} fontWeight={500}>
            Selected Card
          </Typography>
          <Typography fontSize={16} fontWeight={700}>
            {selectedCard.title}
          </Typography>
          <hr style={{ margin: '12px 0 20px 0' }} />
          <span style={{ display: 'flex', gap: '1rem' }}>
            <Typography  fontSize={14}>
              Benefits
            </Typography>
            <span style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
              {selectedCard.benefits.map((item, indx) => (
                <img key={indx} src={item.icon} alt={item.title} />
              ))}
            </span>
          </span>
          <br />
          <Button
            fullWidth
            style={{
              background: '#005FAA',
              color: '#fff',
              borderRadius: '1.5rem'
            }}
            onClick={() => setIsOpenModalDetail(true)}
          >
            Learn More
          </Button>
        </div>
      </div>

      {selectedCard && (
        <ModalContainer
          open={isOpenModalDetail}
          width={'100%'}
          onClose={() => {
            setIsOpenModalDetail(false)
          }}
        >
          <div
            style={{ padding: '2rem', overflowY: 'auto', maxHeight: '600px' }}
          >
            <CardContent
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
                gap: '4rem',
                flexWrap: 'wrap'
              }}
            >
              <div style={{ maxWidth: !isMidScreen ? '532px' : 'unset' }}>
                <Typography fontSize={24} fontWeight={700} color='#091242'>
                  {selectedCard.title}
                </Typography>

                <ul>
                  {selectedCard.features.map((points, indx) => (
                    <li key={indx}>
                      <Typography fontSize={14}>{points.title}</Typography>
                    </li>
                  ))}
                </ul>

                <hr style={{ marginTop: 8, marginBottom: 20 }} />

                {selectedCard?.benefits.map((benefit, indx) => (
                  <div
                    key={indx}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.7rem',
                      marginBottom: '1rem'
                    }}
                  >
                    <img src={benefit.icon} width={30} height={30} alt='' />
                    <Typography color='#868686' fontSize={15}>
                      {benefit.text}
                    </Typography>
                  </div>
                ))}
              </div>

              <div style={{ marginTop: !isMidScreen ? '2rem' : '0' }}>
                <img
                  width={!isSmallScreen ? 350 : 212}
                  src={selectedCard.cardImage}
                  alt='current card seleceted'
                />
              </div>
            </CardContent>

            <div
              style={{
                marginTop: 8,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Button
                onClick={() => {
                  setIsOpenModalDetail(false)
                }}
                className={classes.BtnReject}
              >
                Close
              </Button>{' '}
            </div>
          </div>
        </ModalContainer>
      )}
    </React.Fragment>
  )
}

export default SelectedCard
