import Business1 from '../../../../assets/CCImages/businesscc1.png'
import Business2 from '../../../../assets/CCImages/businesscc2.png'
import Business3 from '../../../../assets/CCImages/businesscc3.png'
import Airplane from '../../../../assets/CCImages/airplane.png'
import Globe from '../../../../assets/CCImages/global.png'
import Shield from '../../../../assets/CCImages/security-card.png'
import Car from '../../../../assets/CCImages/car.png'
import Medal from '../../../../assets/CCImages/medal-star.png'
import Alarm from '../../../../assets/CCImages/alarm.png'
import Shield2 from '../../../../assets/CCImages/shield4.png'
import Security from '../../../../assets/CCImages/security.png'

export const BusinessCreditCardList = [
  {
    id: 1,
    cardType: 'business',
    title: 'AAdvantage MC Executive Business',
    cardImage: Business1,
    bulletPoints: [
      {
        id: 1,
        text: '22% interest rate'
      },
      {
        id: 2,
        text: 'Minimum payment: 5% of outstanding balance; minimun BZ $50'
      },
      {
        id: 3,
        text: 'Annual membership: BZ$500 on primary card'
      }
    ],
    benefits: [
      {
        id: 1,
        icon: Airplane,
        text: 'AAdvantage® Miles lets you earn AAdvantage® miles and Loyalty Points.'
      },
      {
        id: 2,
        icon: Globe,
        text: 'Get emergency card-related assistance any time, anywhere with Master Card Global services.'
      },
      {
        id: 3,
        icon: Medal,
        text: 'Enjoy personal assistance including recommendations and reservations during your travels with Concierge Service.'
      },
      {
        id: 4,
        icon: Car,
        text: 'Have access to Master Rental, which pays for damages up to US$75,000 to a rental vehicle.'
      },
      {
        id: 5,
        icon: Airplane,
        text: 'Obtain pre-trip destination information, referral information in case of medical assistance, and legal referrals with Travel Assistance.'
      },
      {
        id: 6,
        icon: Globe,
        text: 'Get reimburses on medical expenses, hotel convalescence, emergency family travel costs, and more with Master Assist Plus.'
      },
      {
        id: 7,
        icon: Shield,
        text: 'Have access to Purchases Protection, which reimburses you for theft and/or accidental damage of purchased items.'
      },
      {
        id: 8,
        icon: Alarm,
        text: 'TM Robbery & Assault Protection covers death during an assault and replaces stolen cash when using the eligible Mastercard at an ATM.'
      },
      {
        id: 9,
        icon: Shield2,
        text: 'Master Coverage protects companies from employee misuse of payment cards of up to US$15,000.'
      },
      {
        id: 10,
        icon: Security,
        text: 'Extended Warranty enhances coverage up to 1 year beyond the original warranty, max 3 years, for eligible purchases. Up to $400 every 2 months ($200 per occurrence).'
      },
      {
        id: 11,
        icon: Airplane,
        text: 'Trip Inconvenience covers pre-commencement delays or cancellations. Eligible olders can qualify for up to $3,000 in cancellations and $200 for delays exceeding 4 hours.'
      }
    ],
    buttonText: 'Learn more',
    checkboxText: 'Add to comparasion',
    features: [
      {
        title: 'Interest',
        value: '22%'
      },
      {
        title: 'Minimum Payment',
        value: '5% of outstanding balance'
      },
      {
        title: 'Annual membership fee-primary card',
        value: 'BZ$500'
      },
      {
        title: 'Annual membership fee - additional card',
        value: 'BZ$100'
      }
    ],
    benefitsList: [
      true,
      true,
      true,
      true,
      true,
      null,
      true,
      true,
      true,
      true,
      true,
      true
    ]
  },
  {
    id: 2,
    cardType: 'business',
    title: 'AAdvantage MC Platinum Corporate',
    cardImage: Business2,
    bulletPoints: [
      {
        id: 1,
        text: '22% interest rate'
      },
      {
        id: 2,
        text: 'Minimum payment: 5% of outstanding balance; minimun BZ $50'
      },
      {
        id: 3,
        text: 'Annual membership: BZ$300 on primary card'
      }
    ],
    benefits: [
      {
        id: 1,
        icon: Airplane,
        text: 'AAdvantage® Miles lets you earn AAdvantage® miles and Loyalty Points.'
      },
      {
        id: 2,
        icon: Globe,
        text: 'Get emergency card-related assistance any time, anywhere with Master Card Global services.'
      },
      {
        id: 3,
        icon: Medal,
        text: 'Enjoy personal assistance including recommendations and reservations during your travels with Concierge Service.'
      },
      {
        id: 4,
        icon: Car,
        text: 'Have access to Master Rental, which pays for damages up to US$75,000 to a rental vehicle.'
      },
      {
        id: 5,
        icon: Airplane,
        text: 'Obtain pre-trip destination information, referral information in case of medical assistance, and legal referrals with Travel Assistance.'
      },
      {
        id: 6,
        icon: Globe,
        text: 'Mastercard Travel Services offers upgrades, free breakfast, early/late check-in at 1300+ hotels globally. Enjoy exclusive savings on car rentals, air, tours, and cruises. (*Conditions apply)'
      },
      {
        id: 7,
        icon: Globe,
        text: 'Get reimburses on medical expenses, hotel convalescence, emergency family travel costs, and more with Master Assist Plus.'
      }
    ],
    buttonText: 'Learn more',
    checkboxText: 'Add to comparasion',
    features: [
      {
        title: 'Interest',
        value: '22%'
      },
      {
        title: 'Minimum Payment',
        value: '5% of outstanding balance'
      },
      {
        title: 'Annual membership fee-primary card',
        value: 'BZ$500'
      },
      {
        title: 'Annual membership fee - additional card',
        value: 'BZ$100'
      }
    ],
    benefitsList: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false
    ]
  },
  {
    id: 3,
    cardType: 'business',
    title: 'Mastercard Platinum Corporate',
    cardImage: Business3,
    bulletPoints: [
      {
        id: 1,
        text: '22% interest rate'
      },
      {
        id: 2,
        text: 'Minimum payment: 5% of outstanding balance; minimun BZ $50'
      },
      {
        id: 3,
        text: 'Annual membership: BZ$300 on primary card'
      }
    ],
    benefits: [
      {
        id: 1,
        icon: Globe,
        text: 'Get emergency card-related assistance any time, anywhere with Master Card Global services.'
      },
      {
        id: 2,
        icon: Medal,
        text: 'Enjoy personal assistance including recommendations and reservations during your travels with Concierge Service.'
      },
      {
        id: 3,
        icon: Car,
        text: 'Have access to Master Rental, which pays for damages up to US$75,000 to a rental vehicle.'
      },
      {
        id: 4,
        icon: Airplane,
        text: 'Obtain pre-trip destination information, referral information in case of medical assistance, and legal referrals with Travel Assistance.'
      },
      {
        id: 5,
        icon: Globe,
        text: 'Mastercard Travel Services offers upgrades, free breakfast, early/late check-in at 1300+ hotels globally. Enjoy exclusive savings on car rentals, air, tours, and cruises. (*Conditions apply)'
      },
      {
        id: 6,
        icon: Globe,
        text: 'Get reimburses on medical expenses, hotel convalescence, emergency family travel costs, and more with Master Assist Plus.'
      }
    ],
    buttonText: 'Learn more',
    checkboxText: 'Add to comparasion',
    features: [
      {
        title: 'Interest',
        value: '22%'
      },
      {
        title: 'Minimum Payment',
        value: '5% of outstanding balance'
      },
      {
        title: 'Annual membership fee-primary card',
        value: 'BZ$500'
      },
      {
        title: 'Annual membership fee - additional card',
        value: 'BZ$100'
      }
    ],
    benefitsList: [
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false
    ]
  }
]
