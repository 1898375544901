import React from 'react'
import { Typography } from '@mui/material'
import { useStyles } from './styles'
import { formatBelizeCurrency } from '../../../Utils/utils'
import { Button, Input } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getCreditLimitRequestPromoCode, setCreditAmount } from './store'
import ModalContainer from '../../../Components/AtomicDesign/atoms/ModalContainer/ModalContainer'

const CreditLimitForm = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    limits,
    errors,
    creditAmount,
    promoCode: reponsePromoCode,
    promoCodeString
  } = useSelector((state) => state.creditLimitRequest)

  const [promoCode, setPromoCode] = React.useState('')

  const [isOpen, setOpenModal] = React.useState(false)

  React.useEffect(() => {
    if (promoCodeString) {
      setPromoCode(promoCodeString)
    }
  }, [promoCodeString])

  const validatePromoCode = () => {
    if (!promoCode) return

    try {
      dispatch(getCreditLimitRequestPromoCode(promoCode)).then((res) => {
        if (res && res.payload && res.payload.promoCodeID) {
          setOpenModal(true)
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <React.Fragment>
      <div>
        <Typography color='#666C89'>
          Select a limit request for your card
        </Typography>
        <div className={classes.PercentageLimit}>
          <Typography fontSize={32} fontWeight={700} color='#706F6F'>
            {formatBelizeCurrency(creditAmount)}
          </Typography>
        </div>
        <div>
          <input
            value={creditAmount}
            onChange={(e) => dispatch(setCreditAmount(e.target.value))}
            style={{ width: '100%' }}
            type='range'
            min={limits.min}
            max={limits.max}
            step={limits.step}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Typography color={'#868686'} fontSize={14}>
              {formatBelizeCurrency(limits.min)}
            </Typography>
            <Typography color={'#868686'} fontSize={14}>
              {formatBelizeCurrency(limits.max)}
            </Typography>
          </div>
        </div>
      </div>
      <div
        style={{ marginTop: '2rem' }}
        className={classes.ContainerCenterResponsive}
      >
        <Typography color={'#666C89'} className={classes.WidthFullToUnset}>
          I have a promo code
        </Typography>
        <Input
          style={{ paddingLeft: '1rem' }}
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          className={classes.InputPromoCode}
        />
        <Button
          onClick={validatePromoCode}
          className={classes.BtnPromoCode}
          disabled={!promoCode}
        >
          Apply
        </Button>
      </div>
      {errors.promoCode && (
        <Typography
          style={{ marginTop: '0.8rem' }}
          color={'#FF0606'}
          fontSize={11}
        >
          {errors.promoCode.message}
        </Typography>
      )}

      <ModalContainer
        open={isOpen}
        onClose={() => {
          setOpenModal(false)
        }}
      >
        <div
          style={{ padding: '1.8rem', overflowY: 'auto', maxHeight: '532px' }}
        >
          <Typography
            textAlign={'center'}
            color={'#666C89'}
            fontSize={24}
            fontWeight={500}
          >
            Valid Promo Code
          </Typography>

          <Typography
            textAlign={'center'}
            color={'#091242'}
            fontSize={18}
            fontWeight={700}
          >
            {reponsePromoCode?.description || ''}
          </Typography>

          <div
            style={{
              marginTop: 8,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Button
              onClick={() => {
                setOpenModal(false)
              }}
              className={classes.BtnReject}
            >
              OK
            </Button>
          </div>
        </div>
      </ModalContainer>
    </React.Fragment>
  )
}

export default CreditLimitForm
