import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    width: '824px',
    '@media (max-width: 872px)': {
      width: 'unset'
    }
  },
  container: {
    width: '100%',
    display: 'flex'
  },
  imageContainer: {
    width: '49%',
    backgroundColor: '#99e2ff'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  contentContainer: {
    width: '51%',
    padding: 32,
    maxHeight: '632px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 872px)': {
      width: '100%',
      padding: 24
    }
  },
  title: {
    '&.MuiTypography-root': {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '28px',
      textAlign: 'center',
      marginBottom: 42
    }
  },
  description: {
    '&.MuiTypography-root': {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '21px',
      textAlign: 'center',
      marginBottom: 15
    }
  },
  benefitsHighlight: {
    fontWeight: 700,
    textDecoration: 'underline'
  },
  primaryButton: {
    '&.MuiButton-root': {
      width: '100%',
      color: '#fff',
      background: '#0066A4',
      borderRadius: '1.5rem',
      marginTop: '24px',
      padding: '8px 32px',
      '&:hover': {
        backgroundColor: '#54b2f5'
      }
    }
  },
  secondaryButton: {
    '&.MuiButton-root': {
      width: '100%',
      color: '#0066A4',
      fontWeight: 550,
      background: '#fff',
      border: '2px solid #0066A4',
      borderRadius: '1.5rem',
      marginTop: '24px',
      padding: '8px 32px',
      '&:hover': {
        backgroundColor: '#e7e7e7'
      }
    }
  }
}))
