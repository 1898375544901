import React, { useEffect, useRef, useState } from 'react'
import { useFormik, Field, FormikProvider } from 'formik'
import Button from '../../Components/Button/Button'
import { useStyles } from './ProofOfIncomeStyle'
import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  createFilterOptions,
  InputLabel,
  Stack
} from '@mui/material'
import ArrowButton from '../../Components/ArrowButton/ArrowButton'
import { validationSchema } from './validation'
import Alert from '@mui/material/Alert'
import { useNavigate } from 'react-router-dom'
import FILEJOBLETTER from '../../../assets/pdf/Employment Verification Letter.pdf'
import { Download } from '@mui/icons-material'

import { useMovil } from '../../../hooks/useMovil'
import AlertInfo from '../../Components/Alert'
import { useDispatch, useSelector } from 'react-redux'
import {
  bankCatalogCall,
  getProofOfIncomeInfoCall,
  jobLetterCatalogCall,
  salaryCatalogCall
} from './store'
import clsx from 'clsx'
import { toast, Toaster } from 'react-hot-toast'
import SalaryPayment from './components/SalaryPayment'
import InstitutionFinancial from './components/InstitutionFinancial'
import JobLetter from './components/JobLetter'
import UploadFile from './components/UploadFile'
import EmailConfirmation from './components/EmailConfirmation'

const ProofOfIncomeCC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formData } = useSelector((state) => state.ccProofOfIncome)

  const classes = useStyles()

  const inputFile = useRef(null)
  const inputFile2 = useRef(null)

  const { message, hasDownload } = useMovil('employementverification')

  const [showNext, setShowNext] = useState(true)
  const [FormId, setFormId] = useState({
    id: 0,
    customerId: ''
  })

  const [visualSalarySelection, setVisualSalarySelection] = useState(null)
  const filterSalaryOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.name
  })

  const [visualJobLetterSelection, setVisualJobLetterSelection] = useState(null)
  const filterJobLetterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.name
  })

  const [visualBankSelection, setVisualBankSelection] = useState(null)
  const filterBankOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.name
  })

  const formik = useFormik({
    initialValues: {
      salary: null,
      jobLetter: null,
      bank: null,
      File: null,
      File2: null,
      email: '',
      confirmEmail: ''
    },
    enableReintialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const { data, pending, error } = useSelector((state) => state.ccProofOfIncome)

  const { salaryCatalog, jobLetterCatalog, bankCatalog } = useSelector(
    (state) => state.ccProofOfIncome.catalogs
  )

  useEffect(() => {
    window.scroll(0, 0)

    dispatch(salaryCatalogCall()).then(() => {
      dispatch(jobLetterCatalogCall()).then(() => {
        dispatch(bankCatalogCall())
        dispatch(getProofOfIncomeInfoCall()).then(() => {
          formik.validateForm()
        })
      })
    })
  }, [])

  // useEffect(() => {
  //   window.onpopstate = (e) => {
  //     window.history.pushState(null, '')
  //   }
  //   window.history.pushState(null, '')
  // }, [])

  const convertToUpload = (theBlob, filename) => {
    if (theBlob !== null) {
      var arr = theBlob.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    } else {
      return ''
    }
  }

  const onButtonClick = () => {
    inputFile.current.click()
  }

  const onButton2Clicked = () => {
    inputFile2.current.click()
  }

  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
          resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve('')
      })
    }
  }

  const downloadJobLetter = () => {
    const link = document.createElement('a')
    link.download = `${'Employment Verification Letter' || ''}`
    link.href = FILEJOBLETTER
    link.click()
  }

  const handleChangeOptions = ({ id }) => {
    if (id === 4) setShowNext(true)
  }

  const handleSubmit = (values) => {
    console.log(values)
    // navigate('/credit-card/upload-documents')
  }

  console.log('formik Errors', formik.errors)

  return (
    <Box>
      <FormikProvider value={formik}>
        <Box className={classes.ProofOfIncomeContainer}>
          <div className={classes.ButtonTitle}>Proof Of Income</div>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            alignItems='end'
            gap={3}
            mb={10}
          >
            <SalaryPayment />

            {formData.salaryId === 2 && <InstitutionFinancial />}
          </Stack>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            gap={3}
            mb={10}
          >
            {formData.salaryId !== 4 && (
              <>
                <Stack width={{ xs: '100%', sm: '50%' }}>
                  <JobLetter />
                </Stack>

                {formData?.jobLetterId === 3 && (
                  <Stack width={{ xs: '100%', sm: '50%' }}>
                    {hasDownload && (
                      <Button
                        Title='Download Job Letter'
                        className={classes.UploadFiles}
                        endIcon={<Download />}
                        onClick={downloadJobLetter}
                      />
                    )}
                    {!hasDownload && <AlertInfo content={message} />}
                  </Stack>
                )}
              </>
            )}

            {[1, 3, 4].includes(formData?.jobLetterId) &&
              formData?.salaryId !== 4 && (
                <Stack width={{ xs: '100%', sm: '50%' }}>
                  {/* <Button
                    Title='Please upload your job letter'
                    variant='h7'
                    className={classes.UploadFiles}
                    endIcon={<AttachFileIcon />}
                    onClick={onButtonClick}
                  />

                  <Field name='File'>
                    {({ field, form, meta }) => (
                      <>
                        <input
                          {...field}
                          type='file'
                          accept='application/pdf,image/*'
                          onChange={(event) => {
                            form.setFieldValue(
                              field.name,
                              event.currentTarget.files[0]
                            )
                          }}
                          value=''
                          style={{ display: 'none' }}
                          ref={inputFile}
                          // error={meta.touched && meta.error}
                        />
                        <Typography className={classes.UploadFileText}>
                          Only pdf documents are permitted with a maximum of 2MB
                        </Typography>
                        {meta.touched && meta.error ? (
                          <p className={classes.errorMessage}>{meta.error}</p>
                        ) : (
                          meta?.value?.name && (
                            <p className={classes.uploadFileName}>
                              {`${String(meta.value.name).substring(0, 20)}...`}
                              <span>
                                <ClearIcon
                                  className={classes.deleteFileIcon}
                                  onClick={() => {
                                    formik.setFieldValue(field.name, null)
                                  }}
                                />
                              </span>
                            </p>
                          )
                        )}
                      </>
                    )}
                  </Field> */}
                  <UploadFile />
                </Stack>
              )}
          </Stack>

          {formData.jobLetterId === 2 && formData.salaryId !== 4 && (
            <EmailConfirmation />
          )}

          {formData?.jobLetterId &&
            formData?.jobLetterId !== 1 &&
            formData?.salaryId !== 4 && (
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2 }}
                alignItems='end'
                style={{ margin: '35px 0' }}
              >
                <Alert
                  icon={false}
                  sx={{ backgroundColor: '#F4EBFE', padding: '20px' }}
                >
                  <strong>
                    Dear {localStorage.getItem('name') || 'Client'},
                  </strong>{' '}
                  <br />
                  Please note that your loan cannot be approved without a valid
                  employment letter (issued within the last 6 months). Kindly
                  request your Employer to issue the letter promptly;
                  thereafter, please submit this document via Belize Bank’s
                  online loan application.
                </Alert>
              </Stack>
            )}
        </Box>
      </FormikProvider>

      <ArrowButton
        back={'/credit-card/monthly-expenses'}
        onClick={() => {
          // formik.handleSubmit()
        }}
      />

      <Toaster />
    </Box>
  )
}

export default ProofOfIncomeCC
