import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  ButtonTitle: {
    backgroundColor: '#0066A4',
    color: 'white',
    width: '100%',
    padding: '0.5rem',
    textTransform: 'capitalize',
    fontSize: '24px',
    marginBottom: '3rem',
    '&:hover': {
      backgroundColor: '#0066A4',
      color: 'white'
    }
  },
  MonthlyCreditContainer: {
    width: '100%',
    marginTop: '4rem'
  },
  MonthlyCreditDataContainer: {
    width: '100%'
  },
  AmountInput: {
    '@media (max-width: 600px)': {
      width: '100%'
    }
  },
  MonthlyCreditHeader: {
    '& .MuiTypography-body1': {
      fontSize: '16.46px',
      fontWeight: '800',
      fontFamily: theme.fonts.family.secondary,
      lineHeight: '22.49px',
      letterSpacing: '0.1em',
      color: '#005FAA'
    }
  },
  MonthlyCreditInput: {
    '& .MuiInputLabel-root': {
      fontSize: '16px',
      '@media (min-width: 600px)': {
        fontSize: '19px'
      },
      fontWeight: '500',
      lineHeight: '17.76px',
      letterSpacing: '0.1em',
      color: '#706F6F',
      fontWeight: 800,
      fontFamily: theme.fonts.family.secondary
    }
  },
  PlusIcon: {
    '&.MuiSvgIcon-root': {
      marginTop: '23px',
      color: theme.colors.secondary.Green,
      marginBottom: '20px'
    }
  },
  RemoveIcon: {
    '&.MuiSvgIcon-root': {
      marginTop: '23px',
      color: '#F07F3C',
      marginBottom: '20px'
    }
  },
  deleteFileIcon: {
    '&.MuiSvgIcon-root': {
      color: ' red',
      marginTop: 0,
      fontSize: 'large',
      marginLeft: 10,
      position: 'absolute'
    }
  },
  UploadFiles: {
    '&.MuiButtonBase-root': {
      justifyContent: 'space-between',
      height: '38.06px',
      border: ' 1px solid #DADADA',
      color: '#B2B2B2',
      fontSize: '13px',
      fontWeight: '500',
      letterSpacing: '0.1em',
      lineHeight: '17.76px',
      fontFamily: theme.fonts.family.tertiary
    }
  },
  fileText: {
    width: '460px',
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '17.75px',
    fontFamily: theme.fonts.family.secondary,
    color: '#706F6F',
    '@media (max-width: 1200px)': {
      width: '100%'
    }
  },
  uploadFileName: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 15,
    fontWeight: 400,
    color: theme.colors.secondary.Green,
    // width:500,
    marginTop: 0,
    marginBottom: 15
  },
  Total: {
    width: '100%',
    // fontWeight: "800",
    marginTop: '20px',
    '& .MuiInputLabel-root': {
    },
    '@media (max-width: 600px)': {
      width: '100%'
    }
  },
  LoanWithUs: {
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
    color: '#706F6F'
  },
  RadioBtn: {
    width: '49%',
    '@media (max-width: 600px)': {
      width: '100%'
    }
  },
  RadioLabel: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 13,
    fontWeight: '500',
    color: theme.colors.primary.Grey
  },
  otherErrorMessage: {
    color: 'red',
    margin: '8px 0px 0px 0px',
    fontSize: '13px',
    fontFamily: 'avenirLight !important',
    fontWeight: '400',
    marginLeft: '40px'
  }
}))
