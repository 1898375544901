import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../auth/jwt/JwtCreditCard'
import { expensesAdapter } from './adapters/expenses'

// get --> my obligations with BBL
export const getMyObligations = createAsyncThunk('appMonthlyExpenses/getMyObligations', async () => {
  const response = await UseJwt.getMyObligations()

  const total = response.data.creditObligations.cardTotal + response.data.creditObligations.mortgageTotal + response.data.creditObligations.otherLnTotal + response.data.creditObligations.overdraftTotal
  return { data: response.data, total }
})

// get --> List of all Monthly Expenses Types
export const getMonthlyExpensesTypes = createAsyncThunk('appMonthlyExpenses/getMonthlyExpensesTypes', async () => {
  const response = await UseJwt.getMonthlyExpensesTypes()
  return response.data.filter((item) => !['bbl-obligations', 'rent'].includes(item.id))
})

// get --> All my monthly expenses Records
export const getMyMonthlyExpenses = createAsyncThunk('appMonthlyExpenses/getMyMonthlyExpenses', async (_, { rejectWithValue }) => {
  try {
    const response = await UseJwt.getMyMonthlyExpenses()
    // console.log(expensesAdapter(response.data))
    return { data: expensesAdapter(response.data.monthlyExpenses), total: response.data.totalObligationsAmmount }
  } catch (e) {
    return rejectWithValue(e.response?.data)
  }
})

// Create Expense Record
export const createExpenseRecord = createAsyncThunk('appMonthlyExpenses/createExpenseRecord', async (data, { dispatch }) => {
  const response = await UseJwt.createExpenseRecord(data).finally(() => dispatch(getMyMonthlyExpenses()))
  return response.data
})

// Update Monthly Expenses
export const updateExpenseRecord = createAsyncThunk('appMonthlyExpenses/updateExpenseRecord', async (data, { dispatch }) => {
  const response = await UseJwt.updateExpenseRecord(data).finally(() => dispatch(getMyMonthlyExpenses()))
  return response.data
})

// Delete only monthly Expense Record And Document
export const deleteExpenseRecord = createAsyncThunk('appMonthlyExpenses/deleteExpenseRecord', async (params, { dispatch }) => {
  const response = await UseJwt.deleteExpenseRecord(params).finally(() => dispatch(getMyMonthlyExpenses()))
  return response.data
})

export const createMonthlyRentObligation = createAsyncThunk('appMonthlyExpenses/createMonthlyRentObligation', async (data, { dispatch }) => {
  const response = await dispatch(createExpenseRecord(data))
  return response.data
})

export const updateMonthlyRentObligation = createAsyncThunk('appMonthlyExpenses/updateMonthlyRentObligation', async (data, { dispatch }) => {
  const response = await dispatch(updateExpenseRecord(data))
  return response.data
})

export const deleteMonthlyRentObligation = createAsyncThunk('appMonthlyExpenses/deleteMonthlyRentObligation', async (params, { dispatch }) => {
  const response = await dispatch(deleteExpenseRecord(params))
  return response.data
})


const initialState = {
  data: {},
  monthlyExpensesType: [],
  totalObligations: 0.00,
  totalCurrentObligations: 0.00,
  currentObligations: {
    cardTotal: 0.00,
    mortgageTotal: 0.00,
    otherLnTotal: 0.0,
    overdraftTotal: 0.00
  },
  dataRent: {
    amount: 0.00,
    nextAction: "CREATE",
  },
  pending: true,
  nextAction: "GET", // GET, CREATE, UPDATE
}

export const appMonthlyExpensesSlice = createSlice({
  name: 'appMonthlyExpenses',
  initialState,
  reducers: {},
  extraReducers: {
    [getMyObligations.fulfilled]: (state, action) => {
      state.currentObligations = action.payload.data.creditObligations
      state.totalCurrentObligations = action.payload.total
    },
    [getMonthlyExpensesTypes.fulfilled]: (state, action) => {
      state.monthlyExpensesType = action.payload
    },
    [getMyMonthlyExpenses.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.totalObligations = action.payload.total
      state.pending = false
    },
    [getMyMonthlyExpenses.pending]: (state) => {
      state.pending = true
    },
    [createExpenseRecord.fulfilled]: (state, action) => {
      state.pending = false
    },
    [createExpenseRecord.pending]: (state) => {
      state.pending = true
    },
    [createMonthlyRentObligation.fulfilled]: (state, action) => {
      state.pending = false
    },
    [createMonthlyRentObligation.pending]: (state) => {
      state.pending = true
    },
    [updateExpenseRecord.fulfilled]: (state, action) => {
      state.pending = false
    },
    [updateExpenseRecord.pending]: (state) => {
      state.pending = true
    },
    [updateMonthlyRentObligation.fulfilled]: (state, action) => {
      state.pending = false
    },
    [updateMonthlyRentObligation.pending]: (state) => {
      state.pending = true
    },
    [deleteExpenseRecord.fulfilled]: (state, action) => {
      state.pending = false
    },
    [deleteExpenseRecord.pending]: (state) => {
      state.pending = true
    },
    [deleteMonthlyRentObligation.fulfilled]: (state, action) => {
      state.pending = false
    },
    [deleteMonthlyRentObligation.pending]: (state) => {
      state.pending = true
    }
  }
})

export default appMonthlyExpensesSlice.reducer