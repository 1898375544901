import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginTop: "2.5rem",
    display: "flex",
    ["@media (max-width: 1100px)"]: {
      flexDirection: "column",
    },
    justifyContent : 'center'
  },
  StepWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: 90,
    height: 130.45,
    backgroundColor: "#F2F2F2",
    border: "1px solid #FFFFFF",
    cursor: "pointer",
    ["@media (max-width: 1100px)"]: {
      width: "auto",
      height: "31px",
      marginBottom: "10px",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "20px",
    },
  },
  Active: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: 90,
    height: 130.45,
    backgroundColor: theme.colors.secondary.Green,
    border: "1px solid #FFFFFF",
    cursor: "pointer",
    ["@media (max-width: 1100px)"]: {
      width: "auto",
      marginBottom: "10px",
      height: "31px",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "20px",
    },
  },
  Icons: {
    width: 54.43,
    height: 54.43,
    objectFit: "cover",
    marginTop: '1.2rem',
    "@media (max-width: 900px)": {
      marginTop: '0'
    },
  },
  Title: {
    textAlign: "center",
    "&.MuiTypography-root": {
      fontSize: 11,
      fontFamily: theme.fonts.family.tertiary,
      color: theme.colors.primary.Grey,
      fontWeight: 500,
      letterSpacing: "0.1em",
    },
  },
  ActiveTitle: {
    textAlign: "center",
    "&.MuiTypography-root": {
      fontSize: 11,
      fontFamily: theme.fonts.family.tertiary,
      color: "white",
      fontWeight: 500,
      letterSpacing: "0.1em",
    },
  },
  DownArrowIcon: {
    "&.MuiSvgIcon-root": {
      display: "none",
      ["@media (max-width: 1100px)"]: {
        display: "block",
      },
    },
  },
  Links: {
    textDecoration: "none",
  },
}));
