
import React, { useRef } from "react";
import { Box, Stack } from "@mui/material";
import { useStyles } from "./SignDocumentStyle";
import Button from "../Button/Button";
import Select from "../Inputs/Select";
import DatePicker from "../Inputs/DatePicker";
import TimePicker from "../Inputs/TimePicker";
import SalaryAssignment from "../../../assets/pdf/Salary Assignment.pdf";
import { useFormik, FormikProvider } from "formik";
import { baseUrl } from "../../../baseUrl";
import { useState } from "react";
import { useEffect } from "react";
import { ScheduleRequestAppointments } from "../../../Store/Actions/ScheduleRequestAppointment";
import { useDispatch } from "react-redux";
import {validationSchema} from "./validation"
import { toast } from "react-hot-toast";
import { useMovil } from "../../../hooks/useMovil";

const MakeAppointment2 = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const inputFile = useRef(null);

  const formik = useFormik({
    initialValues: {
      salary: null,
    },
    //  enableReintialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
 
  const countPage = useRef(0)
  useEffect(() => {
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])

  const [valueTime, setValueTime] = useState("")
  const [upload, setupload] = useState("")
  // const [salaryLetter, setSalaryLetter] = useState("")
  const [showScheduleThankyou, setShowScheduleThankyou] = useState(false)
  
  const [dataSchedule, setDataSchedule] = useState({
    branchId: "",
    date: "",
    time: "",
  });

  useEffect(() => {
    setDataSchedule({ ...dataSchedule, time: valueTime });
  }, [valueTime]);

  const handleSubmit = () => {
    if (dataSchedule.branchId == "" || dataSchedule.date == "") {
      return toast.error("Please complete all the required fields.");
    }
    if (upload === "") return toast.error("Upload signed salary assignment letter.");
   
    const values = {
      // ...salaryLetter,
      branch: dataSchedule.branchId,
      appointmentDate: dataSchedule.date,
      appointmentTime: dataSchedule.time,
    };
  const responde = dispatch(ScheduleRequestAppointments({ ...values }));
    // setShowScheduleThankyou(true);
  };

  // const onDownload = () => {
  //   const link = document.createElement("a");
  //   link.download = `${SalaryAssignment}`;
  //   link.href = SalaryAssignment;
  //   link.click();
  // };

  // UPLOAD SALARY ASSIGNMENT
  // const File = async (e) => {
  //   const file = e.target.files[0];
  //   setupload(file.name);
  //   const base64 = await convertBase64(file);
  //   const salaryLetter = base64.split(",")[1];
  //   setSalaryLetter({
  //     salaryLetter,
  //   });
  // };

  // const convertBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);

  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  // const onButtonClick = () => {
  //   inputFile.current.click();
  //   formik.setFieldTouched("salary")
  // };
  // const { hasDownload, message } = useMovil('salaryassignment')
  return (
    <Stack>
      <FormikProvider value={formik}>
        <Box>
          <Stack width={{ xs: "100%", sm: "100%" }} mt={6}>
            <Select
              Label="Please select one of our locations for your co-applicant to visit to sign your documents"
              placeholder="Select your prefered branch"
              style={{ backgroundColor: "#ffff" }}
              ApiUrl={`${baseUrl}/branches`}
              name="branchId"
              onChange={(e, val) =>
                setDataSchedule({
                  ...dataSchedule,
                  branchId: val?.id,
                  branchName: val?.name,
                })
              }
              required
            />
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2 }}
            mt={6}
          >
            <DatePicker
              Label="Select appointment date"
              openTo="year"
              views={["year", "month", "day"]}
              style={{ backgroundColor: "#ffff" }}
              disablePast={true}
              onChange={(e) => setDataSchedule({ ...dataSchedule, date: e })}
              disableFuture={false}
              value={dataSchedule.date}
              name="appointmentDate"
            />
            <TimePicker
              Label="Select appointment time"
              placeholder="8:00AM"
              style={{ backgroundColor: "#ffff" }}
              name="appointmentTime"
              appointmentDate={dataSchedule.date}
              setValueTime={setValueTime}
              onChange={(e) => setDataSchedule({ ...dataSchedule, time: e })}
            />
          </Stack>
          {/* <Box className={classes.SubmitBtn}>

            {!showScheduleThankyou ? (
              <Button Title="Schedule" onClick={handleSubmit} />
            ) : null}
          </Box> */}
        </Box>
      </FormikProvider>
      {/* <SheduleThankYou/> */}
      {/* <Box>{showScheduleThankyou ? <SheduleThankYou /> : null}</Box> */}
      </Stack>
  )
}
export default MakeAppointment2
