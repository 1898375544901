import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../auth/jwt/JwtCreditCard'
import { creditCardInfoAdapter } from '../adapters/creditCardInfoAdapter'

/**
 * Asynchronous thunk action to update the step of the application.
 *
 * @function
 * @name updateApplicationStep
 * @param {string} step - The current step of the application to be updated.
 * @returns {Promise<Object>} The response data from the updateApplicationStep API call.
 */
export const updateApplicationStep = createAsyncThunk('globalApplication/updateApplicationStep', async (step) => {
    const response = await UseJwt.updateApplicationStep(step)
    return response.data
})

/**
 * Asynchronous thunk action to get the step application.
 *
 * This function uses the `createAsyncThunk` utility from Redux Toolkit to create an
 * asynchronous action that fetches the step application data from the server.
 *
 * @async
 * @function getApplicationStep
 * @returns {Promise<Object>} The response data containing the step application information.
 */
export const getApplicationStep = createAsyncThunk('globalApplication/getApplicationStep', async () => {
    const response = await UseJwt.getApplicationStep()
    return response.data
})


/**
 * Asynchronous thunk action to get the selected credit card details by ID.
 *
 * @function
 * @name getCardSelectedById
 * @param {string} cardID - The ID of the credit card to retrieve details for.
 * @returns {Promise<Object>} A promise that resolves to the credit card information.
 */
export const getCardSelectedById = createAsyncThunk('appComparison/getCardSelectedById', async (cardID) => {
  const response = await UseJwt.gerCardDetailsById(cardID)
  return creditCardInfoAdapter(response.data)
})


/**
 * Asynchronous thunk action to upload a document.
 *
 * @function
 * @param {Object} params - The parameters for the document upload.
 * @param {string} params.documentType - The type of the document.
 * @param {string} params.documentName - The name of the document.
 * @param {File} params.documentFile - The file of the document.
 * @param {Object} thunkAPI - The thunk API object.
 * @param {Function} thunkAPI.rejectWithValue - Function to return a rejected value.
 * @returns {Promise<Object>} The response data from the upload.
 * @throws {Object} The error response data if the upload fails.
 */
export const uploadDocument = createAsyncThunk('appGlobalApplication/uploadDocument', async (params, { rejectWithValue }) => {
  try {
    // const { documentType, documentName, documentFile } = params
    const response = await UseJwt.postApplicationDocs(params)
    return response.data
  } catch (e) {
    return rejectWithValue(e.response?.data)
  }
})



const initialState = {
    applicationStep: null,
    selectedCard: {},
}

export const appGlobalApplication = createSlice({
  name: 'globalApplication',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(getApplicationStep.fulfilled, (state, action) => {
        state.applicationStep = action.payload?.applicationStep
      })
      .addCase(getCardSelectedById.fulfilled, (state, action) => {
        state.selectedCard = action.payload
      })
})


export default appGlobalApplication.reducer
