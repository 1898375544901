import { Button, InputLabel, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Stack } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Radio from '@mui/material/Radio'
import { Box } from '@mui/system'
import { Field, Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../Components/BackDrop/Spinner'
import ArrowButton from '../../Components/ArrowButton/ArrowButton'
import Currency from '../../Components/Inputs/currency2'
import Input from '../../Components/Inputs/Input'
import Total from '../../Components/Inputs/Total2'
import InputWithTooltip from './Input'
import { useStyles } from './MonthlyIncomeStyle'
import {
  createMonthlyIncome,
  getMonthlyIncome,
  updateMonthlyIncome
} from './store'
import { validation } from './vallidations'
import { updateApplicationStep } from '../../context/global-application'
import { APPLICATION_STEP } from '../../options'
import { useNavigate } from 'react-router-dom'

const MonthlyIncomeCC = () => {
  const minAmount = 900
  const classes = useStyles()
  const countPage = useRef(0)
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const [total, setTotal] = useState(0)
  const [isChecked, setChecked] = useState(false)
  const [radioButtonError, setRadioButtonError] = useState('')
  const { data, nextAction, pending } = useSelector((state) => state.ccMonthlyIncome)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...data.monthlyIncome },
    validationSchema: validation,
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const continueWithApplication = () => navigation('/credit-card/monthly-expenses')

  const handleSubmit = () => {
    if (!(total >= minAmount)) return
    if (!isChecked) {
      setRadioButtonError(
        'You must confirm that the information provided is true in order to continue the application.'
      )
      return
    }

    setRadioButtonError('')
    const formData = { ...formik.values }
    delete formData.otherIncome
    const newFormDataParser = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, Number(value)])
    )

    const data = { ...newFormDataParser }

    if (formik.values.otherIncome) data.otherIncome = formik.values.otherIncome

    if (nextAction === 'CREATE') {
      dispatch(createMonthlyIncome(data))
    }

    if (nextAction === 'UPDATE') {
      dispatch(updateMonthlyIncome(data))
    }
    continueWithApplication()
  }

  const onChangeInput = (e, value, field) => {
    const { name } = field
    let inputValue = value
    if (value === '' || !value.length) {
      inputValue = '0'
    }
    formik.setFieldTouched(name)
    formik.setFieldValue(name, inputValue)
  }

  React.useEffect(() => {
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])

  React.useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null, '')
    }
    window.history.pushState(null, '')
  }, [])

  React.useEffect(() => {
    const values = { ...formik.values }
    delete values['otherIncome']
    delete values['id']
    delete values['applicationID']

    const total = Object.values(values).reduce((a, b) => {
      return parseFloat(a) + parseFloat(b)
    }, 0)

    setTotal(total)
  }, [formik.values])

  React.useEffect(() => {
    dispatch(getMonthlyIncome())
  }, [])

  React.useEffect(() => {
    const step = APPLICATION_STEP['monthly-income-cc']

    dispatch(updateApplicationStep(step.key))
  }, [])

  return (
    <React.Fragment>
      <Spinner open={pending} />
      <Box>
        <Button className={classes.ButtonTitle}>Monthly Income</Button>
        <FormikProvider value={formik}>
          <Form>
            <Box className={classes.MonthlyIncomeContainer}>
              <Box className={classes.MonthlyIncomeHeader}>
                <Typography>
                  Please fill out the information for primary applicant
                </Typography>
              </Box>
              <Stack className={classes.errorMessage}>
                <Typography>{}</Typography>
              </Stack>
              <Stack direction='column' spacing={4} mt={4} mb={5}>
                <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                  <Field name='employmentAmmount'>
                    {({ field, form, meta }) => (
                      <>
                        <Currency
                          Lable='Monthly income from employment'
                          error={meta.touched && meta.error}
                          onChange={(e, value) =>
                            onChangeInput(e, value, field)
                          }
                          name={field.name}
                          value={meta.value}
                        />
                      </>
                    )}
                  </Field>
                </Stack>
                <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                  <Field name='rentalAmmount'>
                    {({ field, form, meta }) => (
                      <>
                        <Currency
                          Lable='Monthly Rental Income'
                          error={meta.touched && meta.error}
                          onChange={(e, value) =>
                            onChangeInput(e, value, field)
                          }
                          name={field.name}
                          value={meta.value}
                        />
                      </>
                    )}
                  </Field>
                </Stack>
                <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                  <Field name='pensionAmmount'>
                    {({ field, form, meta }) => (
                      <>
                        <Currency
                          Lable='Monthly Pension'
                          error={meta.touched && meta.error}
                          onChange={(e, value) =>
                            onChangeInput(e, value, field)
                          }
                          name={field.name}
                          value={meta.value}
                        />
                      </>
                    )}
                  </Field>
                </Stack>
                <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                  <Field name='businessAmmount'>
                    {({ field, form, meta }) => (
                      <>
                        <InputWithTooltip
                          Lable='Monthly Net Income From Business'
                          error={meta.touched && meta.error}
                          onChange={(e, value) =>
                            onChangeInput(e, value, field)
                          }
                          name={field.name}
                          value={meta.value}
                        />
                      </>
                    )}
                  </Field>
                </Stack>
              </Stack>
              <Accordion elevation={0} className={classes.SupplyIncome}>
                <AccordionSummary
                  style={{ borderRadius: '6px' }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.paragraph}>
                    SUPPLEMENTAL MONTHLY INCOME
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction='column' spacing={4} mt={4} mb={5}>
                    <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                      <Field name='alimonyAmmount'>
                        {({ field, form, meta }) => (
                          <>
                            <Currency
                              Lable='ALIMONY'
                              error={meta.touched && meta.error}
                              onChange={(e, value) =>
                                onChangeInput(e, value, field)
                              }
                              name={field.name}
                              value={meta.value}
                            />
                          </>
                        )}
                      </Field>
                    </Stack>
                    <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                      <Field name='consultancyAmmount'>
                        {({ field, form, meta }) => (
                          <>
                            <Currency
                              Lable='CONSULTANCY'
                              error={meta.touched && meta.error}
                              onChange={(e, value) =>
                                onChangeInput(e, value, field)
                              }
                              name={field.name}
                              value={meta.value}
                            />
                          </>
                        )}
                      </Field>
                    </Stack>
                    <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                      <Field name='allowancesAmmount'>
                        {({ field, form, meta }) => (
                          <>
                            <Currency
                              Lable='ALLOWANCE'
                              error={meta.touched && meta.error}
                              onChange={(e, value) =>
                                onChangeInput(e, value, field)
                              }
                              name={field.name}
                              value={meta.value}
                            />
                          </>
                        )}
                      </Field>
                    </Stack>
                    <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                      <Field name='commissionsAmmount'>
                        {({ field, form, meta }) => (
                          <>
                            <Currency
                              Lable='COMMISSION'
                              error={meta.touched && meta.error}
                              onChange={(e, value) =>
                                onChangeInput(e, value, field)
                              }
                              name={field.name}
                              value={meta.value}
                            />
                          </>
                        )}
                      </Field>
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion elevation={0} className={classes.Other}>
                <AccordionSummary
                  style={{ borderRadius: '6px' }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.paragraph}>
                    OTHER (please specify)
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction='column' spacing={4} mt={4} mb={5}>
                    <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                      <Input
                        Lable='Specify the nature of other business'
                        name='otherIncome'
                        value={formik.values.otherIncome}
                        error={
                          formik.touched.otherIncome &&
                          formik.errors.otherIncome
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </Stack>
                    <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                      <Field name='otherAmmount'>
                        {({ field, form, meta }) => (
                          <>
                            <Currency
                              Lable='Monthly Amount'
                              error={meta.touched && meta.error}
                              onChange={(e, value) =>
                                onChangeInput(e, value, field)
                              }
                              name={field.name}
                              value={meta.value}
                            />
                          </>
                        )}
                      </Field>
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Box className={classes.Total} style={{ marginBottom: '3rem' }}>
                <Total total={total} />
                <small style={{ color: 'red', fontFamily: 'gillsansMedium' }}>
                  {total < 900 && (
                    <b>
                      * Please note that it must be equal to or greater than 900
                    </b>
                  )}
                </small>
              </Box>
              <Stack
                className={classes.RadioBtn}
                direction='row'
                alignItems='center'
                mt={4}
              >
                <Radio
                  checked={isChecked}
                  onClick={() => setChecked(!isChecked)}
                />

                <InputLabel className={classes.RadioLabel}>
                  I confirm that all information provided above is true and
                  proof of income can be provided upon request
                </InputLabel>
              </Stack>
              <Stack className={classes.otherErrorMessage}>
                <Typography>{radioButtonError}</Typography>
              </Stack>
            </Box>
          </Form>
        </FormikProvider>
        <ArrowButton
          // link={isValid ? '/CC/monthly-expenses' : ''}
          back={'/credit-card/additional-applicants'}
          onClick={() => formik.submitForm()}
        />
      </Box>
    </React.Fragment>
  )
}

export default MonthlyIncomeCC
