/*
mockData = [
    {
        "id": 8,
        "applicationID": 10,
        "expenseAmmount": 1222,
        "bankDetails": {
            "id": 2,
            "name": "Heritage Bank"
        },
        "typeDetails": {
            "key": "credit-card-payment",
            "name": "Monthly Credit Card Payment"
        },
        "applicationDocsID": null,
        "module": "expenses",
        "docDetails": null
    },
    {
        "id": 9,
        "applicationID": 10,
        "expenseAmmount": 333,
        "bankDetails": {
            "id": 3,
            "name": "National Bank"
        },
        "typeDetails": {
            "key": "credit-card-payment",
            "name": "Monthly Credit Card Payment"
        },
        "applicationDocsID": null,
        "module": "expenses",
        "docDetails": null
    },
    {
        "id": 10,
        "applicationID": 10,
        "expenseAmmount": 6667,
        "bankDetails": {
            "id": 4,
            "name": "Credit Union"
        },
        "typeDetails": {
            "key": "loan-payment",
            "name": "Monthly Loan Payment"
        },
        "applicationDocsID": null,
        "module": "expenses",
        "docDetails": null
    },
    {
        "id": 11,
        "applicationID": 10,
        "expenseAmmount": 12212,
        "bankDetails": {
            "id": 1,
            "name": "Atlantic Bank"
        },
        "typeDetails": {
            "key": "overdraft-payment",
            "name": "Monthly Overdraft Payment"
        },
        "applicationDocsID": null,
        "module": "expenses",
        "docDetails": null
    }
]

*/

const extractPropsUnique = (data = []) => {
    const unique = [...new Set(data.map(item => item.typeDetails.key))]
    return unique
}

const groupByKeys = (data = [], keys) => {

  const result = keys.reduce((acc, key) => {
    acc[key] = data.filter(item => item.typeDetails.key === key).map(item => {
      return {
        id: item.id,
        applicationID: item.applicationID,
        typeID: item.typeDetails.key,
        amount: item.expenseAmmount,
        bank: item.bankDetails,
        file: {
          name: item?.docDetails?.fileName || '',
          base64: item?.docDetails?.fileBase64 || '',
          error: null
        },
        errors: {
          amount: '',
          bank: ''
      },
      nextAction: 'UPDATE',
      module: item.module
    }
    })
    return acc
  }, {})

  return result
}

export const expensesAdapter = (data = []) => {
    const keys = extractPropsUnique(data)
    const group = groupByKeys(data, keys)

    return group
}