import * as yup from "yup";
function isAmountValid(value) {
  if (value) {
    return parseFloat(value) < 100000000;
 } else {
   return true;
 }
}
export const validation = yup.object({
  employmentAmmount: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
       return isAmountValid(value)
    }),
    pensionAmmount: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
    rentalAmmount: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
    businessAmmount: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
    alimonyAmmount: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
    consultancyAmmount: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
    allowancesAmmount: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
    commissionsAmmount: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
    otherAmmount: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
});
