import React from 'react'
import CurrentApplication from './CurrentApplication'
import { useDispatch } from 'react-redux'
import { getApplicationStep } from '../../context/global-application'

const MyApplications = () => {

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getApplicationStep())
  }, [])

  return (
    <React.Fragment>
      <CurrentApplication />
    </React.Fragment>
  )
}

export default MyApplications