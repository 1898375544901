import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  ButtonTitle: {
    backgroundColor: '#0066A4',
    color: 'white',
    width: '100%',
    padding: '0.9rem 0',
    textTransform: 'capitalize',
    fontSize: '24px',
    marginBottom: '3rem',
    textAlign: 'center',
    borderRadius: '8px',
    fontFamily: theme.fonts.family.primary,
    '&:hover': {
      backgroundColor: '#0066A4',
      color: 'white'
    }
  },
  ProofOfIncomeContainer: {
    width: '100%',
    marginBottom: '32px'
  },

  UploadFiles: {
    '&.MuiButtonBase-root': {
      marginTop: '8px',
      width: '445px',
      justifyContent: 'space-between',
      textTransform: 'capitalize',
      height: '43px',
      border: ' 1px solid #DADADA',
      color: '#B2B2B2',
      fontSize: '13px',
      fontWeight: '500',
      letterSpacing: '0.1em',
      lineHeight: '17.76px',
      fontFamily: theme.fonts.family.tertiary,
      '@media (max-width: 600px)': {
        marginTop: '4px'
      },
      '@media (max-width: 445px)': {
        width: 'auto'
      }
    }
  },
  UploadFileText: {
    '&.MuiTypography-root': {
      marginTop: '6px',
      color: '#706F6F',
      fontSize: '13px',
      fontWeight: '400',
      lineHeight: '18px',
      fontFamily: theme.fonts.family.tertiary
    }
  },
  deleteFileIcon: {
    '&.MuiSvgIcon-root': {
      color: ' red',
      marginTop: 0,
      fontSize: 'large',
      marginLeft: 10,
      position: 'absolute',
      cursor: 'pointer'
    }
  },
  uploadFileName: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 15,
    fontWeight: 400,
    color: theme.colors.secondary.Green,
    // width:500,
    marginBottom: 15
  },
  errorMessage: {
    '&.MuiTypography-root': {
      fontSize: '15px',
      fontWeight: 400,
      fontFamily: theme.fonts.family.secondary,
      color: 'red'
    }
  },
  SelectLable: {
    '&.MuiTypography-root': {
      fontFamily: theme.fonts.family.secondary,
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '30px',
      color: '#666C89'
    }
  },
  Select: {
    '&.MuiAutocomplete-root': {
      marginTop: 24,
      '& .MuiAutocomplete-input': {
        fontFamily: theme.fonts.family.secondary,
        height: 9,
        fontSize: 13,
        letterSpacing: '0.1em',
        '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
          {
            borderColor: '#DADADA'
          }
      }
    },
    '& .MuiInputBase-root': {
      '&.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
        {
          borderColor: '#DADADA'
        }
    }
  },
  paper: {
    '&.MuiAutocomplete-paper': {
      backgroundColor: '#616164'
    },
    '& .MuiAutocomplete-listbox': {
      fontFamily: theme.fonts.family.secondary,
      fontWeight: 400,
      fontSize: 13,
      margin: '0px 10px',
      color: 'white',
      '& .MuiAutocomplete-option': {
        '&:hover': {
          backgroundColor: '#679CE5',
          borderRadius: 8
        }
      }
    },
    '& .MuiAutocomplete-noOptions': {
      color: 'white'
    }
  }
}))
