import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../auth/jwt/JwtCreditCard'

export const authenticate = createAsyncThunk('appHomeCreditCard/authenticate', async (code) => {
  console.log('init authenticate')
  const response = await UseJwt.authenticate(code)
  return response.data
})

export const appHomeCreditCard = createSlice({
  name: 'appHomeCreditCard',
  initialState: {
    token: '',
    code: '',
    pending: true,
  },
  reducers: {
  },
  extraReducers: builder =>
    builder.addCase(authenticate.fulfilled, (state, action) => {
      state.token = action.payload.token
      state.pending = false
    }
  )
})

export default appHomeCreditCard.reducer