import { Button, Container, Typography } from '@mui/material'
import React, { useState } from 'react'
import bgImage from '../../../../assets/CCImages/bg-title-page.png'
import { personalCreditCardAdapter } from '../auxiliarData/personalCreditCardInfo'
import Business from './Business'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCardInfoById,
  setComparison,
  setInitialCard,
  setOpenModal,
  setSelectedCard,
  setTableComparison,
  startNewApplication
} from './store'
import { CARD_POSITION } from './options'
import { comparisonAdapter } from '../../../adapters'
import AAdvantageModal from './AAdvantageModal'

const CreditCardComparison = ({ setComparisson }) => {
  const navigate = useNavigate()
  const { selectedCC, catalog } = useSelector(
    (state) => state.availableCreditCard
  )
  const { cardOne, cardTwo, cardThree, comparison } = useSelector(
    (state) => state.comparison
  )

  const dispatch = useDispatch()

  const [cards, setCards] = useState([])

  React.useEffect(() => {
    if (catalog) {
      const cards = personalCreditCardAdapter(catalog.options)
      setCards(cards)
    }
  }, [catalog])

  React.useEffect(() => {
    selectedCC.map((cc, position) =>
      dispatch(
        getCardInfoById({ id: cc, carPosition: CARD_POSITION[position + 1] })
      )
    )
  }, [selectedCC])

  React.useEffect(() => {
    const comparison = [cardOne, cardTwo, cardThree].filter(
      (cc) => cc?.id !== null && cc?.id !== undefined
    )
    dispatch(setComparison(comparison))
  }, [cardOne, cardTwo, cardThree])

  React.useEffect(() => {
    const comparisonFiltered = comparison.filter((cc) => cc?.id !== null)
    if (comparisonFiltered.length >= 2) {
      const comparisonData = comparisonAdapter(comparisonFiltered)
      dispatch(setTableComparison(comparisonData))
    }
  }, [comparison])

  const handleSelectCard = (card) => {
    dispatch(setSelectedCard(card))

    if (card.requiredAA === false) {
      dispatch(startNewApplication({ selectedCardId: card.id })).then(
        (response) => {
          if (response.error) return
          navigate('/credit-card/personal-information-review')
        }
      )
      return
    }

    dispatch(setOpenModal(true))
  }

  return (
    <>
      <Container>
        <div
          style={{
            backgroundImage: `url(${bgImage})`,
            height: '250px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '2rem'
          }}
        >
          <Typography color='#fff' fontSize={36}>
            Compare credit cards
          </Typography>
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${comparison.length + 1}, 1fr)`
          }}
        >
          <div></div>
          {cards
            .filter((cc) => selectedCC.includes(cc.id))
            .map((selected) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '0.5rem'
                }}
                key={selected.id}
              >
                <img height={162} width={253} src={selected.cardImage} alt='' />
                <Button
                  style={{
                    background: '#005FAA',
                    color: '#fff',
                    fontSize: '12px',
                    fontWeight: '600',
                    borderRadius: '2rem',
                    padding: '0.5rem 3rem'
                  }}
                  onClick={() => handleSelectCard(selected)}
                >
                  Select This Card
                </Button>
              </div>
            ))}
        </div>

        {comparison.length > 0 && <Business />}

        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <Button
            style={{
              color: '#2F80ED',
              border: '1px solid #2F80ED',
              borderRadius: '1.5rem'
            }}
            onClick={() => {
              setComparisson(false)
              dispatch(setInitialCard())
            }}
          >
            GO BACK TO ALL CREDIT CARDS
          </Button>
        </div>
      </Container>

      <AAdvantageModal />
    </>
  )
}

export default CreditCardComparison
