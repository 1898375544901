import React, { useState, useLayoutEffect, useEffect, Fragment } from 'react'
import { useStyles } from './ProcessStepsStyle'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { ProcessArray } from './ProcessArray'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
// import { useSelector } from 'react-redux'

const ListItem = ({ steps, setProgrress, currentProcess }) => {
  const classes = useStyles()
  const Location = useLocation()

  const [currentClass, setCurrentClass] = useState({
    boxContainer: classes.StepWrapper,
    imgContainer: classes.IconWrapper,
    titleClass: classes.Title
  })

  useEffect(() => {
    if (Location.pathname.includes(steps.Link)) {
      steps.id !== 1 &&
        setProgrress((steps.id - 1) * Number(100 / (ProcessArray.length - 1)))
    }
  }, [Location])

  useEffect(() => {
    if (currentProcess.index === currentProcess.current) {
      setCurrentClass({
        boxContainer: classes.ActiveBox,
        imgContainer: classes.Active,
        titleClass: classes.ActiveTitle
      })
      return
    } else if (currentProcess.index > currentProcess.current) {
      setCurrentClass({
        boxContainer: classes.StepWrapper,
        imgContainer: classes.IconWrapper,
        titleClass: classes.Title
      })
      return
    } else if (currentProcess.index < currentProcess.current) {
      setCurrentClass({
        boxContainer: classes.StepWrapper,
        imgContainer: classes.CompletedImg,
        titleClass: classes.CompletedTitle
      })
      return
    }
  }, [currentProcess.current])

  return (
    <Box key={steps} className={clsx(currentClass.boxContainer)}>
      <div className={clsx(currentClass.imgContainer)}>
        <img src={steps.Icon} alt='' className={classes.Icons} />
      </div>
      <Typography variant='subtitle1' className={clsx(currentClass.titleClass)}>
        {steps.Title}
      </Typography>
      {/* <ArrowDropDownIcon className={classes.DownArrowIcon} /> */}
    </Box>
  )
}

const ProcessSteps = ({ setProgrress, steps, tabs }) => {
  const classes = useStyles()
  const Location = useLocation()
  const { height, width } = useWindowDimensions()

  const [currentProcessIndx, setCurrentProcessIndx] = useState(0)

  // add more links if there is another 'final link' on credit-card
  const FINAL_LINKS = ['/credit-card/upload-documents']

  React.useEffect(() => {
    FINAL_LINKS.forEach((link) => {
      if (Location.pathname.includes(link)) {
        setProgrress(100)
      }
    })

    setCurrentProcessIndx(
      ProcessArray.findIndex((item) => Location.pathname.includes(item.Link)) ||
        0
    )
  }, [Location])

  return (
    <Box className={classes.MainContainer} style={{ position: 'relative' }}>
      {ProcessArray.map((steps, index) => {
        return (
          <Fragment key={steps.id}>
            {width > 1100 ? (
              <ListItem
                steps={steps}
                setProgrress={setProgrress}
                currentProcess={{ current: currentProcessIndx, index }}
              />
            ) : (
              <>
                {width <= 1100 && Location.pathname.includes(steps.Link) && (
                  <ListItem
                    steps={steps}
                    setProgrress={setProgrress}
                    currentProcess={{ current: currentProcessIndx, index }}
                  />
                )}
              </>
            )}
          </Fragment>
        )
      })}
    </Box>
  )
}

export default ProcessSteps

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
