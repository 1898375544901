import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  BackButton: {
    '& .MuiTypography-root': {
      color: '#005FAA',
      fontSize: '13px',
      lineHeight: '15.6px',
      fontWeight: '700',
      fontFamily: theme.fonts.family.quaternary,
      border: '1px solid #9FC3DF',
      padding: '1rem 4rem',
      borderRadius: '2rem'
    }
  },
  BackArrow: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    // backgroundColor: "#005FAA",
    color: '#005FAA',
    padding: '2px'
  },
  NextButton: {
    '& .MuiTypography-root': {
      color: '#fff',
      fontSize: '13px',
      lineHeight: '15.6px',
      fontWeight: '700',
      fontFamily: theme.fonts.family.quaternary
    }
  },
  NextArrow: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    padding: '2px',
    // backgroundColor: "#F07F3C",
    color: '#fff'
  },
  Links: {
    textDecoration: 'none',
    border: '1px solid #9FC3DF',
    padding: '0.5rem 4rem',
    borderRadius: '2rem'
  },
  Links2: {
    textDecoration: 'none',
    background: '#005FAA',
    border: '1px solid #9FC3DF',
    padding: '0.5rem 4rem',
    borderRadius: '2rem'
  },
  MsgIcon: {
    width: '80px',
    height: '80px',
    backgroundColor: '#F07F3C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    position: 'relative',
    marginRight: '20px',
    marginBottom: '20px',
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      color: '#ffff',
      fontSize: '50px'
    },
    '&:hover': {
      backgroundColor: '#005FAA',
      '& .MuiBox-root': {
        borderRight: '26px solid #005FAA'
      }
    }
  },
  MsgIconTwo: {
    width: '80px',
    height: '80px',
    backgroundColor: '#F07F3C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    position: 'relative',

    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      color: '#ffff',
      fontSize: '50px'
    },
    '&:hover': {
      backgroundColor: '#005FAA',
      '& .MuiBox-root': {
        borderRight: '26px solid #005FAA'
      }
    }
  },
  TriangleLeft: {
    content: '',
    position: 'absolute',
    left: ' 55%',
    top: '60px',
    width: '0',
    height: '0',
    borderTop: '13px solid transparent',
    borderRight: '26px solid #F07F3C',
    borderBottom: '13px solid transparent'
  }
}))
