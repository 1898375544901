import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from './LoanApprovedStyle'
import Approved from '../../../assets/Images/Grupo 999.svg'
// import Progress from '../../ProgressBar/ProgressBar'

const ApprovedBelow7k = () => {
  const classes = useStyles()

  return (
    <Stack className={classes.container}>
    {/* <Progress progrress={100}/> */}
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={classes.ApprovedPoppup}>
          <Box className={classes.ApprovedPoppupImage}>
            <img className={classes.ApprovedImage} src={Approved} alt='' />
          </Box>
          <Typography className={classes.ApprovedText}>
            <strong> Your approved facility is below BZ$7,000.</strong>
            <br />
            You can sign all loan documents digitally. To sign the loan
            agreements, please wait for us to upload the documents, we will
            contact you very soon.
          </Typography>
        </Box>
      </Box>
    </Stack>
  )
}

export default ApprovedBelow7k
