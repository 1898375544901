import React from 'react'
import { Stack } from '@mui/system'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ClearIcon from '@mui/icons-material/Clear'
import Currency from '../../../Components/Inputs/currency2'
import Select from '../../../Components/Inputs/SelectWithAbsolute'
import Button from '../../../Components/Button/Button'
import { baseUrl } from '../../../../baseUrl'

const ObligationItem = ({
  item,
  indexID,
  configuration,
  classes,
  handleCurrencyChange,
  handleBankChange,
  handleFileChange,
  handleFileRemove,
  handleRemoveObligation,
  obligations,
  setObligations,
  limit
}) => {
  const loanPaymentFileRef = React.useRef(null)

  const handleAddObligation = () => {
    if (obligations.length < limit) {
      setObligations([
        ...obligations,
        {
          ...configuration,
          amount: 0,
          nextAction: 'CREATE',
          bank: { id: null, name: '', position: null },
          file: {
            name: '',
            base64: '',
            error: null
          },
          errors: {
            amount: '',
            bank: ''
          }
        }
      ])
    }
  }

  return (
    <Stack mt={4} key={indexID}>
      <Stack direction='row' spacing={1} alignItems='center' mb={{ md: 4 }}>
        <Currency
          Lable={configuration.name}
          className={classes.MonthlyCreditInput}
          error={item.errors.amount}
          onChange={(event, value) =>
            handleCurrencyChange(event, value, indexID)
          }
          value={item.amount}
        />
        <AddCircleIcon
          className={classes.PlusIcon}
          onClick={handleAddObligation}
        />
        <HighlightOffIcon
          className={classes.RemoveIcon}
          onClick={() => handleRemoveObligation(indexID)}
        />
      </Stack>

      <Stack mt={4}>
        <Select
          placeholder='Select financial institution'
          ApiUrl={`${baseUrl}/banks?filter={"order":"position"}`}
          value={item.bank || null}
          error={item.errors.bank}
          onBlur={() => {}}
          onChange={(e, value, reason) => {
            if (reason === 'clear') {
              handleBankChange(
                e,
                { id: null, name: '', position: null },
                indexID
              )
            } else {
              handleBankChange(e, value, indexID)
            }
          }}
        />
      </Stack>

      <Stack direction='column' width={{ xs: '100%', sm: '49%' }} mt={2}>
        <Button
          Title='Upload Files'
          variant='h7'
          className={classes.UploadFiles}
          endIcon={<AttachFileIcon />}
          disabled={
            item.bank.id === null ||
            !item.amount ||
            Number(item.amount <= 0) ||
            false
          }
          onClick={(e) => {
            loanPaymentFileRef.current.click()
          }}
        />
        <input
          type='file'
          accept='application/pdf,image/*'
          onChange={(event) => {
            handleFileChange(event, indexID)
          }}
          className={classes.MonthlyCreditInput}
          value={''}
          style={{ display: 'none' }}
          ref={loanPaymentFileRef}
        />
        <p className={classes.fileText}>
          Upload your loan statement from the financial institution selected.
          Statements retrieved from online banking are accepted.
        </p>
        {item.file.error ? (
          <p
            className={classes.fileText}
            style={{ color: 'red', marginTop: 0 }}
          >
            {item.file.error.message}
          </p>
        ) : (
          item.file.name && (
            <p className={classes.uploadFileName}>
              {`${String(item.file.name).substring(0, 20)} ...`}
              <span>
                <ClearIcon
                  className={classes.deleteFileIcon}
                  onClick={() => handleFileRemove(indexID)}
                />
              </span>
            </p>
          )
        )}
      </Stack>
    </Stack>
  )
}

export default ObligationItem
