import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../../auth/jwt/JwtCreditCard'

export const hasApplicationInProgress = createAsyncThunk('appSelectApplication/hasApplicationInProgress', async () => {
  console.log('init hasApplicationInProgress')
  const response = await UseJwt.hasApplicationInProgress()
  return response.data
})

export const appSelectApplication = createSlice({
  name: 'appSelectApplication',
  initialState: {
    selectedApplication: '',
    hasCurrentApplicationInProgress: true,
    pending: true,
  },
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(hasApplicationInProgress.fulfilled, (state, action) => {
        console.log('fulfilled hasApplicationInProgress')
        state.hasCurrentApplicationInProgress = action.payload?.hasApplicationInProgress
        state.pending = false

      })
      .addCase(hasApplicationInProgress.rejected, (state, action) => {
        console.log('rejected hasApplicationInProgress')
        state.hasCurrentApplicationInProgress = true
        state.pending = false
      })
})

export default appSelectApplication.reducer