/*

{
    "id": 1,
    "creditCardId": 1,
    "features": [
        {
            "title": "Interest rate",
            "value": "22%"
        },
        {
            "title": "Minimum payment",
            "value": "5% of outstanding"
        },
        {
            "title": "Annual membership fee-primary card",
            "value": "BZ$100"
        },
        {
            "title": "Annual membership fee - additional card",
            "value": "BZ$50"
        },
        {
            "title": "Transaction Limit",
            "value": "Number of Transactions: 10 daily, 40 weekly"
        },
        {
            "title": "US Spending Limit",
            "value": "US$7,500 monthly / US$35,000 annually"
        },
        {
            "title": "Monthly Statement Cycle",
            "value": "every 16th"
        },
        {
            "title": "Insurance",
            "value": [
                "Personal Credit Cards (premium is charged monthly per $100 of outstanding balance)",
                "-Credit Life- Single coverage is $0.29 & Joint is $0.52",
                "-Credit Life & Critical Illness (combo) – Single coverage is $0.53 and Joint is $0.95 "
            ]
        },
        {
            "title": "Service Provider Fees ",
            "value": [
                "1% per Visa transaction",
                ".0175% stamp duty for both Visa and MasterCard credit cards"
            ]
        },
        {
            "title": "Standing Order",
            "value": true
        }
    ],
    "benefits": [
        {
            "title": "AA miles collection",
            "value": false
        },
        {
            "title": "Mastercard Global Services",
            "value": false
        },
        {
            "title": "Price Protection",
            "value": false
        },
        {
            "title": "Purchase protection",
            "value": true
        },
        {
            "title": "MasterRental",
            "value": false
        },
        {
            "title": "Concierge Service",
            "value": false
        },
        {
            "title": "Mastercard Travel Services",
            "value": false
        },
        {
            "title": "Travel Assistance",
            "value": false
        }
    ]
}

*/




export const creditCardInfoAdapter = (data) => {

  if (!data) return {}

    const creditCardInfo = {
        id: data.id,
        creditCardId: data.creditCardId,
        features: data.features.reduce((acc, feature) => {
            acc[feature.key] = {
                title: feature.title,
                value: feature.value
            }
            return acc
        }, {}),
        benefits: data.benefits.reduce((acc, benefit) => {
            acc[benefit.key] = {
                title: benefit.title,
                value: benefit.value
            }
            return acc
        }, {})
    }

    return creditCardInfo

}