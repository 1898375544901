import { makeStyles } from '@material-ui/core/styles'
import BgImage from '../../../assets/CCImages/bg-title-page.png'

export const useStyles = makeStyles((theme) => ({
  widthVerifyBtn: {
    width: '15%',
    '@media (max-width: 900px)': {
      width: '100%'
    }
  },
  wrapFlex: {
    '@media (max-width: 900px)': {
      flexWrap: 'wrap',
      // marginTop: '1.5rem',
      gap: '1rem'
    }
  },
  container: {
    // marginTop: '20px',
    // background: '#56AF31',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${BgImage})`,
    backgroundPosition: 'center',
    textAlign: 'center',
    padding: 20,
    height: '114px',
    borderRadius: '8px'
  },
  MovilToast: {
    '@media (max-width: 768px)': {
      left: '50%',
      transform: 'translateX(-50%)'
    }
  },
  Header: {
    '&.MuiTypography-root': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '29.677px',
      fontFamily: theme.fonts.family.secondary,
      color: '#ffff'
    }
  },
  HeaderSubtitle: {
    '&.MuiTypography-subtitle1': {
      // fontFamily: theme.fonts.family.secondary,
      color: '#ffff',
      fontFamily: 'avenirMedium !important',
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '16px',
      margin: 'auto'
      // lineHeight: '21px'
    }
  },
  FormContainer: {
    padding: '20px',
    background: '#FFFFFF',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: '20px'
    // display: 'flex',
    // width: '100%',
    // gap: '1rem'
  },
  Divider: {
    marginTop: '35px !important',
    border: '1px solid #DADADA'
  },
  Text: {
    '&.MuiTypography-subtitle1': {
      fontFamily: theme.fonts.family.secondary,
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.1em',
      color: '#868686',
      marginBottom: '20px',
      marginTop: '20px'
    }
  },
  TextConfirmation: {
    '&.MuiTypography-subtitle1': {
      fontFamily: theme.fonts.family.secondary,
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.1em',
      color: '#0253EC',
      marginBottom: '18px',
      marginTop: 1
    }
  },
  TextConfirmationError: {
    '&.MuiTypography-subtitle1': {
      fontFamily: theme.fonts.family.secondary,
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.1em',
      color: 'red',
      marginBottom: '20px',
      marginTop: '20px'
    }
  },
  MonthlyCreditInput: {
    '& .MuiInputLabel-root': {
      fontSize: '16px',
      '@media (min-width: 600px)': {
        fontSize: '19px'
      },
      fontWeight: '500',
      lineHeight: '17.76px',
      letterSpacing: '0.1em',
      color: '#706F6F',
      fontWeight: 800,
      fontFamily: theme.fonts.family.secondary
    }
  },

  UploadFiles: {
    width: '100%',
    '&.MuiButtonBase-root': {
      justifyContent: 'space-between',
      height: '38.06px',
      border: ' 1px solid #DADADA',
      color: '#B2B2B2',
      fontSize: '13px',
      fontWeight: '500',
      letterSpacing: '0.1em',
      lineHeight: '17.76px',
      fontFamily: theme.fonts.family.tertiary
    }
  },
  fileText: {
    marginTop: 6,
    marginBottom: 0,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '14px',
    fontFamily: theme.fonts.family.secondary,
    color: 'red',
    '@media (max-width: 1200px)': {
      width: '100%'
    }
  },
  uploadFileName: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '15px',
    color: theme.colors.secondary.Green,
    marginTop: 6,
    marginBottom: 0
  },
  NextButton: {
    width: '20%',
    height: 39.01,
    borderRadius: '36px !important',
    '&.MuiButtonBase-root': {
      backgroundColor: theme.colors.primary.Darkblue,
      '&:hover': {
        backgroundColor: theme.colors.secondary.Green
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        color: 'White',
        fontWeight: 600,
        letterSpacing: '0.2em'
      }
    },
    '@media (max-width: 600px)': {
      width: '100%'
    }
  },
  noSSNBtn: {
    width: '100%',
    height: 39.01,
    '&.MuiButtonBase-root': {
      backgroundColor: theme.colors.primary.Darkblue,
      marginTop: 'auto',
      '&:hover': {
        backgroundColor: theme.colors.secondary.Green
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        color: 'White',
        fontWeight: 600,
        letterSpacing: '0.2em',
        '@media (max-width: 600px)': {
          width: '100%',
          marginLeft: '0% !important',
          height: 30,
          fontSize: 12
        },
        '@media (max-width: 435px)': {
          fontSize: 9
        }
      }
    }
  },
  noSSNBtnNo: {
    width: '60%',
    marginLeft: '20% !important',
    height: 39.01,
    '&.MuiButtonBase-root': {
      backgroundColor: theme.colors.primary.Darkblue,
      marginTop: 'auto',
      '&:hover': {
        backgroundColor: theme.colors.secondary.Green
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        color: 'White',
        fontWeight: 600,
        letterSpacing: '0.2em'
      }
    },
    '@media (max-width: 600px)': {
      width: '100%',
      marginLeft: '0% !important',
      height: 45,
      fontSize: 12
    }
  },
  deleteFileIcon: {
    '&.MuiSvgIcon-root': {
      color: ' red',
      marginTop: 0,
      fontSize: 'large',
      marginLeft: 10,
      position: 'absolute',
      cursor: 'pointer'
    }
  }
}))
