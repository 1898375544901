import React from 'react'
import { Button, Typography } from '@mui/material'
import { Input, useMediaQuery } from '@material-ui/core'
import AAdvantage from '../../../assets/CCImages/aadvantage.png'
import ModalContainer from '../../../Components/AtomicDesign/atoms/ModalContainer/ModalContainer'

const STEP_A_ADVANTAGE = {
  hasAAdvantage: 'hasAAdvantage',
  aAdvantageNumber: 'aAdvantageNumber',
  skipAAdvantage: 'skipAAdvantage'
}

const RequestAAdvantageModal = ({
  openModal,
  setOpenModal,
  startApplication
}) => {
  const [step, setStep] = React.useState(STEP_A_ADVANTAGE.hasAAdvantage)
  const [aAdvantageValue, setAAdvantageValue] = React.useState('')

  const isSmallScreen = useMediaQuery('(max-width: 464px)')

  React.useEffect(() => {
    setStep(STEP_A_ADVANTAGE.hasAAdvantage)
  }, [])

  return (
    <ModalContainer
      open={openModal}
      width={432}
      onClose={() => {
        setOpenModal(false)
      }}
    >
      {[STEP_A_ADVANTAGE.hasAAdvantage].includes(step) && (
        <div style={{ padding: '2rem', textAlign: 'center' }}>
          <img
            src={AAdvantage}
            alt=''
            style={{ width: !isSmallScreen ? 332 : 186 }}
          />
          <Typography fontSize={24} margin={'0 auto'}>
            Are you already an AAdvantage® member?
          </Typography>
          <Button
            fullWidth
            style={{
              color: '#fff',
              background: '#0066A4',
              borderRadius: '1.5rem',
              marginTop: '2rem'
            }}
            onClick={() => setStep(STEP_A_ADVANTAGE.aAdvantageNumber)}
          >
            Yes
          </Button>
          <Button
            fullWidth
            style={{
              color: '#0066A4',
              background: '#fff',
              border: '2px solid #0066A4',
              borderRadius: '1.5rem',
              marginTop: '1rem'
            }}
            onClick={() => setStep(STEP_A_ADVANTAGE.skipAAdvantage)}
          >
            No
          </Button>
        </div>
      )}

      {[STEP_A_ADVANTAGE.aAdvantageNumber].includes(step) && (
        <div style={{ padding: '2rem', textAlign: 'center' }}>
          <Typography color={'#706F6F'} fontSize={16} margin={'0 auto'}>
            Great! Please let us know your existing AAdvantage® number so that
            all the AAdvantage® miles earned are merged into one account for you
            to take full advantage of all its benefits.
          </Typography>
          <Input
            onChange={(e) => setAAdvantageValue(e.target.value)}
            value={aAdvantageValue}
            placeholder='234129910'
            style={{
              border: '1px solid rgba(0,0,0,0.3)',
              borderRadius: '8px',
              width: '100%',
              padding: '0.4rem 1rem',
              marginTop: '1.5rem',
              letterSpacing: '2rem',
              textAlign: 'center'
              // margin: '0 auto'
            }}
            type='number'
          />
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around'
            }}
          >
            <Button
              style={{
                color: '#fff',
                background: '#0066A4',
                borderRadius: '1.5rem',
                marginTop: '2rem',
                padding: '8px 32px'
              }}
              onClick={() => {
                setStep(STEP_A_ADVANTAGE.hasAAdvantage)
                setAAdvantageValue('')
              }}
            >
              Go Back
            </Button>
            <Button
              style={{
                color: '#0066A4',
                background: '#fff',
                border: '2px solid #0066A4',
                borderRadius: '1.5rem',
                marginTop: '2rem',
                padding: '8px 32px'
              }}
              onClick={() => {
                setOpenModal(false)
                startApplication(aAdvantageValue)
              }}
            >
              Proceed
            </Button>
          </div>
        </div>
      )}

      {[STEP_A_ADVANTAGE.skipAAdvantage].includes(step) && (
        <div style={{ padding: '2rem', textAlign: 'center' }}>
          <Typography color={'#706F6F'} fontSize={16} margin={'0 auto'}>
            Upon approval of your credit card, you will obtain a AAdvantage
            member number. Please download the American Airline app to view your
            earned miles and manage your account.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              fullWidth
              style={{
                color: '#fff',
                background: '#0066A4',
                borderRadius: '1.5rem',
                marginTop: '2rem',
                padding: '0.5rem 4rem'
              }}
              onClick={() => {
                setOpenModal(false)
                startApplication(null)
              }}
            >
              Okay
            </Button>
          </div>
        </div>
      )}
    </ModalContainer>
  )
}

export default RequestAAdvantageModal
