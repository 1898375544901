import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  svgIcon: {
    path: {
      fill: '#fff'
    }
  },
  MainContainer: {
    marginTop: '2.5rem',
    display: 'flex',
    width: '100%',
    '@media (max-width: 1100px)': {
      flexDirection: 'column'
    },
    justifyContent: 'space-between'
    // gap: '7.5rem'
  },
  StepWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    alignItems: 'center',
    width: 90,
    height: 130.45
    // cursor: 'pointer'
  },
  ActiveBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    alignItems: 'center',
    width: 90,
    height: 130.45,
    '@media (max-width: 1100px)': {
      width: 'auto',
      height: '31px',
      marginBottom: '10px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '26px',
      border: '2px solid #0066A4',
      borderRadius: 12
    }
  },
  Active: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 34,
    height: 34,
    backgroundColor: '#0066A4',
    borderRadius: '50%',
    marginBottom: 4,
    '@media (max-width: 1100px)': {
      width: 32,
      height: 32,
      padding: '18px'
    },
    '& img': {
      filter: 'contrast(900%) invert(1)'
    }
  },
  CompletedImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 34,
    height: 34,
    backgroundColor: '#38B54A',
    borderRadius: '50%',
    marginBottom: 4,
    '& img': {
      filter: 'contrast(900%) invert(1)'
    }
  },
  IconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 34,
    height: 34,
    backgroundColor: '#F2F2F2',
    borderRadius: '50%',
    marginBottom: 4
  },
  Icons: {
    width: 20,
    height: 20,
    objectFit: 'cover',
    '@media (max-width: 900px)': {
      marginTop: '0'
    }
  },
  Title: {
    width: '84px',
    textAlign: 'center',
    '&.MuiTypography-root': {
      fontSize: 11,
      fontFamily: theme.fonts.family.tertiary,
      color: theme.colors.primary.Grey,
      fontWeight: 500,
      letterSpacing: '0.1em'
    }
  },
  ActiveTitle: {
    width: '84px',
    textAlign: 'center',
    '&.MuiTypography-root': {
      fontSize: 10,
      fontFamily: theme.fonts.family.tertiary,
      color: '#0066A4',
      fontWeight: 500,
      letterSpacing: '0.1em',
      '@media (max-width: 1100px)': {
        fontSize: 12
      }
    },
    '@media (max-width: 1100px)': {
      width: 'auto'
    }
  },
  CompletedTitle: {
    width: '84px',
    textAlign: 'center',
    '&.MuiTypography-root': {
      fontSize: 10,
      fontFamily: theme.fonts.family.tertiary,
      color: '#38B54A',
      fontWeight: 600,
      letterSpacing: '0.08em'
    }
  },
  DownArrowIcon: {
    '&.MuiSvgIcon-root': {
      display: 'none',
      '@media (max-width: 1100px)': {
        display: 'block'
      }
    }
  },
  Links: {
    textDecoration: 'none'
  }
}))
