import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  GridTemplate: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '2rem',
    '@media (max-width: 500px)': {
      gridTemplateColumns: '1fr'
    }
  },
  FieldLabel: {
    color: '#706F6F',
    fontSize: '14px',
    fontFamily: 'avenirLight !important',
    fontWeight: '700',
    lineHeight: '20px',
    textTransform: 'uppercase',
    marginBottom: '.5rem !important',
    display: 'block'
  },
  RedLabel: {
    color: 'red'
  },
  TextFieldStyles: {
    '& .MuiInputBase-input': {
      fontSize: '0.9rem !important',
      padding: '0.7rem 1rem !important',
      marginTop: 0
    }
  },
  TextFieldWithSpacingStyle: {
    '& .MuiInputBase-input': {
      fontSize: '0.9rem !important',
      padding: '0.7rem 1rem !important',
      marginTop: 0,
      letterSpacing: '1.32px !important'
    }
  },
  NumericFieldStyle: {
    width: 'calc(100% - 2rem)',
    fontSize: '0.9rem',
    padding: '0.7rem 1rem',
    marginTop: 0,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '5px'
  },
  ButtonTitle: {
    backgroundColor: '#0066A4',
    color: 'white',
    width: '100%',
    padding: '0.9rem 0',
    textTransform: 'capitalize',
    fontSize: '24px',
    marginBottom: '3rem',
    textAlign: 'center',
    borderRadius: '8px',
    fontFamily: theme.fonts.family.primary,
    '&:hover': {
      backgroundColor: '#0066A4',
      color: 'white'
    }
  },
  Container: {
    width: '100%',
    marginBottom: '186px'
  },
  CreditCardLimitContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '4rem'
  },
  CreditCardInfo: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #BDBDBD',
    borderRadius: '8px',
    maxWidth: '329px',
    width: '100%',
    padding: '1rem 0'
  },
  CreditCardInfoBody: {
    padding: '0 1.35rem'
  },
  CreditCardLimit: {
    width: '100%',
    maxWidth: '700px'
  },
  PercentageLimit: {
    width: '100%',
    maxWidth: '475px',
    border: '1px solid rgba(0,0,0,0.5)',
    borderRadius: '8px',
    textAlign: 'center',
    padding: '1rem',
    margin: '1rem 0'
  },
  InputPromoCode: {
    border: '1px solid rgba(0,0,0,0.5)',
    width: '100%',
    maxWidth: '285px',
    height: '40px',
    borderRadius: '8px'
  },
  BtnPromoCode: {
    backgroundColor: '#0066A4',
    color: 'white',
    borderRadius: '1.5rem',
    fontSize: '17px',
    fontWeight: '600',
    textTransform: 'capitalize',
    width: '156px',
    height: '40px',
    marginLeft: '1rem'
  },
  BtnConfirm: {
    background: '#0066A4',
    color: '#fff',
    padding: '0.7rem 5rem',
    borderRadius: '2rem',
    fontSize: 14,
    fontWeight: 600,
    margin: '2.4rem auto',
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #0066A4',
      color: '#0066A4'
    },
    '&.Mui-disabled': {
      backgroundColor: '#d3d3d3',
      color: '#a9a9a9'
    }
  },
  BtnReject: {
    backgroundColor: '#fff',
    border: '1px solid #0066A4',
    color: '#0066A4',
    width: '245px',
    height: '40px',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: '#0066A4',
      color: '#fff'
    }
  },
  errorMessage: {
    marginLeft: 3,
    marginTop: 6,
    marginBottom: 0,
    fontSize: 15,
    fontWeight: 400,
    fontFamily: theme.fonts.family.secondary,
    color: 'red'
  },
  SelectAddAppStyle: {
    width: '100%',
    my: 2,
    display: 'block',
    cursor: 'default',
    height: '2.7rem',
    marginTop: '0'
  },
  formAddAppStyle: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'flex-start'
  }
}))
