import React from 'react'
import { useStyles } from '../MonthlyExpensesStyle'
import { Box } from '@material-ui/core'
import { transformFileToBase64 } from '../../../utils'
import toast from 'react-hot-toast'
import ObligationItem from './ObligationItem'
import { useDispatch, useSelector } from 'react-redux'
import {
  createExpenseRecord,
  deleteExpenseRecord,
  updateExpenseRecord
} from '../store'
import { uploadDocument } from '../../../context/global-application'


const initialConfiguration = {
  amount: 0,
    nextAction: 'CREATE',
    bank: { id: null, name: '', position: null },
    file: {
      name: '',
      base64: '',
      error: null
    },
    errors: {
      amount: '',
      bank: ''
    }
}

const FormCreditObligation = ({
  configuration = {},
  limit = 5,
  index,
  typeID
}) => {
  const classes = useStyles()

  const { data } = useSelector((state) => state.ccMonthlyExpenses)

  const dispatch = useDispatch()

  const [obligations, setObligations] = React.useState([
    {
      ...configuration,
      typeID,
      ...initialConfiguration
    }
  ])

  // console.log(obligations)

  React.useEffect(() => {
    if (data[typeID]?.length > 0) {
      setObligations(data[typeID])
    }
  }, [data])

  const saveCreditObligation = (amount, bankID, expenseType) => {
    if (!amount || !bankID) {
      return
    }
    dispatch(
      createExpenseRecord({
        expenseAmmount: Number(amount),
        bankId: bankID,
        expenseType
      })
    )
  }

  const updateCreditObligation = (amount, bankID, file, id) => {
    if (!amount || !bankID) {
      return
    }
    dispatch(
      updateExpenseRecord({
        id,
        expenseAmmount: Number(amount),
        bankId: bankID,
        expenseType: typeID
        // file: file.base64
      })
    )
  }

  const handleOnSubmit = (
    amount,
    bankID,
    file = { base64: null, name: null },
    index
  ) => {
    if (!amount || !bankID) {
      return
    }
    // console.log(amount, bankID, typeID, file, obligations[index].id)
    if (obligations[index].nextAction === 'CREATE')
      saveCreditObligation(amount, bankID, typeID)
    if (obligations[index].nextAction === 'UPDATE')
      updateCreditObligation(amount, bankID, file, obligations[index].id)
  }

  const handleRemoveObligation = (index) => {
    const id = obligations[index].id
    dispatch(deleteExpenseRecord({ id }))
    const newObligations = obligations.filter((item, i) => i !== index)
    const length = newObligations.length
    if (length === 0) {
      setObligations([
        {
          ...configuration,
          typeID,
          ...initialConfiguration
        }
      ])
    } else {
      setObligations(newObligations)
    }
  }

  const handleCurrencyChange = (event, value, index) => {
    const newObligations = [...obligations]
    const updatedObligation = { ...newObligations[index], amount: value }
    newObligations[index] = updatedObligation
    checkErrors()
    setObligations(newObligations)
    handleOnSubmit(
      value,
      updatedObligation.bank.id,
      updatedObligation.file,
      index
    )
  }

  const handleBankChange = (event, value, index) => {
    const newObligations = [...obligations]
    const updatedObligation = { ...newObligations[index], bank: value }
    newObligations[index] = updatedObligation
    checkErrors()
    setObligations(newObligations)
    handleOnSubmit(
      updatedObligation.amount,
      value.id,
      updatedObligation.file,
      index
    )
    // handleOnSubmit(newObligations[index].amount, value.id, newObligations[index].file, index)
  }

  const handleFileChange = (event, index) => {
    const newObligations = [...obligations]

    const file = event.target.files[0]
    transformFileToBase64(file)
      .then((base64) => {
        console.log(base64)
        dispatch(
          uploadDocument({
            documentType: typeID,
            documentName: file.name,
            documentFile: base64,
            module: newObligations[index].module,
            moduleId: newObligations[index].id
          })
        )
        newObligations[index] = {
          ...newObligations[index],
          file: {
            name: file.name,
            base64
          }
        }
        setObligations(newObligations)
      })
      .catch((error) => {
        toast.error(error.message)
        newObligations[index] = {
          ...newObligations[index],
          file: {
            name: '',
            base64: '',
            error: error?.message || 'Error uploading file'
          }
        }
      })
  }

  const handleFileRemove = (index) => {
    const id = obligations[index].id
    dispatch(deleteExpenseRecord({ id, deleteOnlyDoc: true }))
  }

  const checkErrors = () => {
    const newObligations = obligations.map((item) => {
      const newItem = { ...item, errors: { ...item.errors } }
      newItem.errors.amount =
        newItem.amount <= 0 && (newItem.bank.id || newItem.file.name)
          ? 'Amount is required'
          : ''
      newItem.errors.bank =
        newItem.bank.id === null && (newItem.amount > 0 || newItem.file.name)
          ? 'Bank is required'
          : ''
      return newItem
    })
    setObligations(newObligations)
  }

  // React.useEffect(() => {

  // }, [])

  return (
    <React.Fragment>
      <Box>
        <div
          style={{
            padding: '10px 10px 60px 10px',
            backgroundColor: index % 2 === 0 ? '#f5f4f4' : '#fbfbfb'
          }}
        >
          {obligations.map((item, indexID) => (
            <ObligationItem
              // id={item.id}
              item={item}
              key={indexID}
              limit={limit}
              classes={classes}
              indexID={indexID}
              obligations={obligations}
              configuration={configuration}
              setObligations={setObligations}
              handleFileRemove={handleFileRemove}
              handleBankChange={handleBankChange}
              handleFileChange={handleFileChange}
              handleCurrencyChange={handleCurrencyChange}
              handleRemoveObligation={handleRemoveObligation}
            />
          ))}
        </div>
      </Box>
    </React.Fragment>
  )
}

export default FormCreditObligation
