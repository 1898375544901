import { Alert, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
// import Cross from "../../assets/Images/Cross.png";
import { Box } from "@mui/system";
import React, {useState,useEffect} from "react";
import { useStyles } from "./IdleTimeOutModalStyle"

const IdleTimeOutModal  = ({showModal, handleClose, getRemainingTime}) => {
  const classes = useStyles();
  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }
  const [timeVal, setTimeVal] = useState(null); 
  useEffect(() => {
    setInterval(() => {
      setTimeVal(getRemainingTime())
      }, 1000)
  },[])
  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={showModal}
      aria-labelledby="draggable-dialog-title"
    >
      <Box className={classes.AlertBoxHeader}>Oh No!</Box>
       <Box className={classes.AlertMainSection} mt={4}>
         <Typography className={classes.AlertText}>
          You Session will be timeout in {`${millisToMinutesAndSeconds(timeVal)}`}
        </Typography>
        <DialogActions>
          <Button
            className={classes.AlertBtn}
            autoFocus
            onClick={handleClose}
          >
            Stay
          </Button>
    
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default IdleTimeOutModal;
