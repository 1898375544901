import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../../auth/jwt/JwtCreditCard'

// getMonthlyIncome
export const getMonthlyIncome = createAsyncThunk(
  'appMonthlyIncome/getMonthlyIncome',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getMonthlyIncome()
      return response.data
    } catch (e) {
      return rejectWithValue(e.response?.data)
    }
  }
)

// createMonthlyIncome
export const createMonthlyIncome = createAsyncThunk(
  'appMonthlyIncome/createMonthlyIncome',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UseJwt.createMonthlyIncome(data)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response?.data)
    }
  }
)

// updateMonthlyIncome
export const updateMonthlyIncome = createAsyncThunk(
  'appMonthlyIncome/updateMonthlyIncome',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UseJwt.updateMonthlyIncome(data)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response?.data)
    }
  }
)

const initialState = {
  pending: true,
  data: {
    monthlyIncome: {
      employmentAmmount: 0.0,
      rentalAmmount: 0.0,
      pensionAmmount: 0.0,
      businessAmmount: 0.0,
      alimonyAmmount: 0.0,
      consultancyAmmount: 0.0,
      allowancesAmmount: 0.0,
      commissionsAmmount: 0.0,
      otherIncome: '',
      otherAmmount: 0.0
    }
  },
  nextAction: 'GET' // GET, CREATE, UPDATE
}

export const appMonthlyIncomeSlice = createSlice({
  name: 'appMonthlyIncome',
  initialState,
  reducers: {
    setNextAction: (state, action) => {
      state.nextAction = action.payload
    }
  },
  extraReducers: {
    [getMonthlyIncome.fulfilled]: (state, action) => {
      state.data = action.payload
      state.nextAction = 'UPDATE'
      state.pending = false
    },
    [getMonthlyIncome.pending]: (state, action) => {
      state.pending = true
    },
    [getMonthlyIncome.rejected]: (state, action) => {
      const statusError = [404]
      if (statusError.includes(action.payload.error.statusCode)) {
        state.pending = false
        state.data = { ...initialState.data.monthlyIncome }
        state.nextAction = 'CREATE'
      }
    },
    [createMonthlyIncome.pending]: (state, action) => {
      state.pending = true
    },
    [createMonthlyIncome.fulfilled]: (state, action) => {
      state.nextAction = 'UPDATE'
      state.pending = false
    },
    [updateMonthlyIncome.fulfilled]: (state, action) => {
      state.nextAction = 'UPDATE'
      state.pending = false
    },
    [updateMonthlyIncome.pending]: (state, action) => {
      state.pending = true
    }
  }
})

export const { setNextAction } = appMonthlyIncomeSlice.actions

export default appMonthlyIncomeSlice.reducer
