import React, { useState } from 'react'
import { Button, CardContent, Typography } from '@mui/material'
import ModalContainer from '../../../../Components/AtomicDesign/atoms/ModalContainer/ModalContainer'
import { PersonalCreditCardList } from '../auxiliarData/personalCreditCardInfo'
import { BusinessCreditCardList } from '../auxiliarData/businessCreditCardInfo'
import AAdvantage from '../../../../assets/CCImages/aadvantage.png'
import { Input } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'

const ModalCreditCard = ({ isOpen, onClose, modalInfo, setOpenModal }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isAAdvantage, setIsAAdvantage] = useState(false)
  const [isSelectedCard, setIsSelectedCard] = useState(false)
  const [lastOpen, setLastOpen] = useState(false)
  const [aAdvantageValue, setAAdvantageValue] = useState(0)

  return (
    <ModalContainer open={isOpen} onClose={onClose} width={'100%'}>
      {!isSelectedCard && !isAAdvantage && !lastOpen && (
        <div style={{ padding: '3rem', overflowY: 'auto', height: '600px' }}>
          {modalInfo.cardType === 'personal' && (
            <CardContent
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                gap: '5rem',
                flexWrap: 'wrap'
              }}
            >
              <div style={{ minWidth: '400px', maxWidth: '539px' }}>
                <Typography fontSize={24} fontWeight={700} color='#091242'>
                  {PersonalCreditCardList[modalInfo.id - 1].title}
                </Typography>
                <ul>
                  {PersonalCreditCardList[modalInfo.id - 1].bulletPoints.map(
                    (points) => (
                      <li key={points.id}>
                        <Typography fontSize={14}>{points.text}</Typography>
                      </li>
                    )
                  )}
                </ul>
                <hr />
                {PersonalCreditCardList[modalInfo.id - 1].benefits.map(
                  (benefit) => (
                    <div
                      key={benefit.id}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.7rem',
                        marginBottom: '1rem'
                      }}
                    >
                      <img src={benefit.icon} alt='' />
                      <Typography color='#868686' fontSize={14}>
                        {benefit.text}
                      </Typography>
                    </div>
                  )
                )}
              </div>
              <div style={{ marginTop: '2rem' }}>
                <img
                  width={359}
                  height={228}
                  src={PersonalCreditCardList[modalInfo.id - 1].cardImage}
                  alt=''
                />
                <div
                  style={{
                    marginTop: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '3rem'
                  }}
                >
                  {!location.pathname.includes('/CC/credit-limit-request') && (
                    <Button
                      style={{
                        // width: '255px',
                        backgroundColor: '#005FAA',
                        borderRadius: '2rem'
                      }}
                      variant='contained'
                      fullWidth
                    >
                      Select This Card
                    </Button>
                  )}
                </div>
              </div>
            </CardContent>
          )}

          {modalInfo.cardType === 'business' && (
            <CardContent
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                gap: '5rem',
                flexWrap: 'wrap'
              }}
            >
              <div style={{ minWidth: '400px', maxWidth: '539px' }}>
                <Typography fontSize={24} fontWeight={700} color='#091242'>
                  {BusinessCreditCardList[modalInfo.id - 1].title}
                </Typography>
                <ul>
                  {BusinessCreditCardList[modalInfo.id - 1].bulletPoints.map(
                    (points) => (
                      <li key={points.id}>
                        <Typography fontSize={14}>{points.text}</Typography>
                      </li>
                    )
                  )}
                </ul>
                <hr />
                {BusinessCreditCardList[modalInfo.id - 1].benefits.map(
                  (benefit) => (
                    <div
                      key={benefit.id}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.7rem',
                        marginBottom: '1rem'
                      }}
                    >
                      <img src={benefit.icon} alt='' />
                      <Typography color='#868686' fontSize={14}>
                        {benefit.text}
                      </Typography>
                    </div>
                  )
                )}
              </div>
              <div style={{ marginTop: '2rem' }}>
                <img
                  width={359}
                  height={228}
                  src={BusinessCreditCardList[modalInfo.id - 1].cardImage}
                  alt=''
                />
                <div
                  style={{
                    marginTop: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '3rem'
                  }}
                >
                  {!location.pathname.includes('CC/credit-limit-request') && (
                    <Button
                      style={{
                        // width: '255px',
                        backgroundColor: '#005FAA',
                        borderRadius: '2rem'
                      }}
                      variant='contained'
                      fullWidth
                      onClick={() => {
                        if (
                          BusinessCreditCardList[
                            modalInfo.id - 1
                          ].title.includes('AAdvantage')
                        ) {
                          setIsSelectedCard(true)
                          setIsAAdvantage(true)
                        } else {
                          navigate('/credit-card/personal-information-review')
                        }
                      }}
                    >
                      Select This Card
                    </Button>
                  )}
                </div>
              </div>
            </CardContent>
          )}
        </div>
      )}

      {isSelectedCard && (
        <div style={{ padding: '2rem', textAlign: 'center' }}>
          <img src={AAdvantage} alt='' />
          <Typography
            width={277}
            color={'#091242'}
            fontSize={24}
            margin={'0 auto'}
          >
            Are you already an AAdvantage® member
          </Typography>
          <Button
            fullWidth
            style={{
              color: '#fff',
              background: '#0066A4',
              borderRadius: '1.5rem',
              marginTop: '2rem'
            }}
            onClick={() => {
              setIsSelectedCard(false)
              setIsAAdvantage(true)
            }}
          >
            Yes
          </Button>
          <Button
            fullWidth
            style={{
              color: '#0066A4',
              background: '#fff',
              border: '2px solid #0066A4',
              borderRadius: '1.5rem',
              marginTop: '1rem'
            }}
            onClick={() => {
              setIsSelectedCard(false)
              setIsAAdvantage(false)
              setLastOpen(true)
            }}
          >
            No
          </Button>
        </div>
      )}

      {isAAdvantage && !isSelectedCard && (
        <div style={{ padding: '2rem', textAlign: 'center' }}>
          <Typography
            width={534}
            color={'#706F6F'}
            fontSize={16}
            margin={'0 auto'}
          >
            Great! Please let us know your existing AAdvantage® number so that
            all the AAdvantage® miles earned are merged into one account for you
            to take full advantage of all its benefits.
          </Typography>
          <Input
            onChange={(e) => setAAdvantageValue(e.target.value)}
            value={aAdvantageValue}
            style={{
              border: '1px solid rgba(0,0,0,0.3)',
              borderRadius: '8px',
              width: '100%',
              padding: '0.4rem 1rem',
              marginTop: '1.5rem',
              letterSpacing: '2rem',
              textAlign: 'center'
              // margin: '0 auto'
            }}
            type='number'
          />
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              style={{
                color: '#fff',
                background: '#0066A4',
                borderRadius: '1.5rem',
                marginTop: '2rem',
                padding: '0.5rem 4rem'
              }}
              onClick={() => {
                setOpenModal(false)
                setIsSelectedCard(false)
                setIsAAdvantage(false)
                setLastOpen(false)
              }}
            >
              Go Back
            </Button>
            <Button
              disabled={aAdvantageValue.length < 8}
              style={{
                color: '#0066A4',
                background: '#fff',
                border: '2px solid #0066A4',
                borderRadius: '1.5rem',
                marginTop: '2rem',
                padding: '0.5rem 4rem'
              }}
              onClick={() =>
                navigate('/credit-card/personal-information-review')
              }
            >
              Proceed
            </Button>
          </div>
        </div>
      )}

      {!isAAdvantage && !isSelectedCard && lastOpen && (
        <div style={{ padding: '2rem', textAlign: 'center' }}>
          <Typography
            width={351}
            color={'#706F6F'}
            fontSize={16}
            margin={'0 auto'}
          >
            Upon approval of your credit card, you will obtain a AAdvantage
            member number. Please download the American Airline app to view your
            earned miles and manage your account.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              fullWidth
              style={{
                color: '#fff',
                background: '#0066A4',
                borderRadius: '1.5rem',
                marginTop: '2rem',
                padding: '0.5rem 4rem'
              }}
              onClick={() => {
                setOpenModal(false)
                navigate('/credit-card/personal-information-review')
              }}
            >
              Okay
            </Button>
          </div>
        </div>
      )}
    </ModalContainer>
  )
}

export default ModalCreditCard
