import React from 'react'
import MonthlyLoanPayment from './components/MonthlyLoanPayment'
import { Stack } from '@mui/material'
import { useStyles } from './MonthlyExpensesStyle'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../Components/BackDrop/Spinner'
import Currency from '../../../Components/Inputs/currency2'
import {
  createMonthlyRentObligation,
  deleteMonthlyRentObligation,
  updateExpenseRecord
} from './store'
import useDebounce from '../../../Components/LoanCalculator/useDebounce'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

const MonthlyCreditData = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { pending, data } = useSelector((state) => state.ccMonthlyExpenses)
  const [rent, setRent] = React.useState(0)
  const [currentRent, setCurrentRent] = React.useState({
    amount: 0.0,
    nextAction: 'CREATE'
  })

  const handleCurrencyChange = () => {
    if (rent === 0 || !rent) return
    if (currentRent.nextAction === 'CREATE')
      dispatch(
        createMonthlyRentObligation({
          expenseAmmount: Number(rent),
          expenseType: 'rent'
        })
      )
    if (currentRent.nextAction === 'UPDATE')
      dispatch(
        updateExpenseRecord({
          id: currentRent.id,
          expenseAmmount: Number(rent),
          expenseType: currentRent.typeID
        })
      )
  }
  const debouncedRent = useDebounce(rent, 1_500)

  React.useEffect(() => {
    handleCurrencyChange()
  }, [debouncedRent])

  React.useEffect(() => {
    const length = data.rent?.length
    if (length > 0) {
      setRent(data.rent[0].amount)
      setCurrentRent(data.rent[0])
    }
    // setRent(data.amount)
  }, [data])

  const handleRemoveObligation = () => {
    if (!currentRent.id) return
    dispatch(deleteMonthlyRentObligation({ id: currentRent.id }))
    setRent(0)
  }

  return (
    <React.Fragment>
      <Spinner open={pending} />
      <Stack
        direction={{ xs: 'row', sm: 'column' }}
        spacing={7}
        className={classes.MonthlyCreditDataContainer}
      >
        <MonthlyLoanPayment />
      </Stack>
      <Stack mt={4}>
        <Stack direction='row' spacing={1} alignItems='center' mb={{ md: 4 }}>
          <Currency
            Lable={'Rent'}
            className={classes.MonthlyCreditInput}
            // error={item.errors.amount}
            onChange={(event, value) => {
              setRent(value)
            }}
            value={rent}
          />
          <HighlightOffIcon
            className={classes.RemoveIcon}
            onClick={handleRemoveObligation}
          />
        </Stack>
      </Stack>
    </React.Fragment>
  )
}

export default MonthlyCreditData
