import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import VerificationInput from '../Inputs/VerificationInput'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import AlertInfo from '../Alert'
import {
  checkEmailCall,
  confirmationEmailCall,
  resendCodeEmailCall
} from '../../Pages/PersonalInformationReviewForm/store/index.js'
import { useStyles } from './EmailOtpDialogStyle.js'
import Shield from '../../../assets/CCImages/shield.png'

const EmailOtpDialog = ({
  open = false,
  setOpenDialog,
  newEmail,
  refreshData
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [pin, setPin] = useState('')

  const { pending, error } = useSelector(
    (state) => state.personalInformationReview.checkEmailDialog
  )

  const { dataEmail } = useSelector(
    (state) => state.personalInformationReview.application
  )

  useEffect(() => {
    if (open) {
      dispatch(checkEmailCall({ body: { email: newEmail } }))
    }
  }, [open])

  useEffect(() => {
    if (error.message && error.message.length > 0 && !pending) {
      setTimeout(() => {
        setOpenDialog(false)
      }, 2400)
    }
  }, [error])

  useEffect(() => {
    if (dataEmail.verifiedEmail && !pending) {
      setTimeout(() => {
        setOpenDialog(false)
      }, 2400)
    }
  }, [dataEmail])

  const handleResendCode = () => {
    dispatch(resendCodeEmailCall()).then((res) => {
      if (res && res.payload && res.payload.sent) {
        toast.success('The code resend was successful', {
          duration: 4200
        })
      }
    })
  }

  const handleConfirmCode = () => {
    dispatch(confirmationEmailCall({ body: { code: pin } })).then((res) => {
      if (res && res.payload && res.payload.verifiedEmail) {
        refreshData()
        toast.success('Email successfully verified', {
          duration: 4200
        })
      }
    })
  }

  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={open}
      onClose={() => setOpenDialog(false)}
      aria-labelledby='draggable-dialog-title'
    >
      {!pending && !dataEmail.verifiedEmail && !(error.message.length > 0) ? (
        <Box>
          {/* <PopupAlert newPhone={newEmail} previousNumber={data.email} /> */}
          <Box className={classes.PopupContainer}>
            <Box className={classes.PopupHeader}></Box>

            <Box className={classes.PopupMainSection}>
              <Box className={classes.PopupHeaderLogo}>
                <img className={classes.Logo} src={Shield} alt='Logo' />
              </Box>

              <Typography className={classes.PopupTitle}>
                Email confirmation required
              </Typography>

              <Typography className={classes.PopupText}>
                We need to validate your email address as it will be required to
                complete the application. An email confirmation was sent to the
                email address provided at the beginning of your application.
              </Typography>

              <Box className={classes.PopupExpireTime}>
                <Typography className={classes.ExpireTimeText}>
                  Enter the confirmation code now.
                </Typography>
              </Box>

              <VerificationInput
                length={6}
                onChange={(e) => setPin(e)}
                sx={{
                  borderRadius: '8px',
                  fontSize: '16px'
                }}
              />

              {/* <Box className={classes.PopupExpireTime}>
                <img className={classes.ClockImage} src={clock} alt="clock" />
                <Typography className={classes.ExpireTimeText}>
                  The code will expire in 15 minutes
                </Typography>
              </Box> */}

              <Box className={classes.PopupBtn} style={{ marginTop: '2rem' }}>
                <Button
                  disabled={pin.length < 6}
                  className={classes.PopupButton}
                  onClick={handleConfirmCode}
                >
                  VALIDATE CODE
                </Button>
              </Box>

              <Box
                style={{ margin: '0' }}
                className={classes.PopupExpireTime}
                onClick={handleResendCode}
              >
                <Typography
                  className={classes.ExpireTimeText}
                  style={{
                    textDecoration: 'underline',
                    fontWeight: '700',
                    cursor: 'pointer'
                  }}
                >
                  RESEND CODE
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box className={classes.PopupContainer}>
            <Box className={classes.PopupMainSection}>
              {pending && <p>...</p>}

              {error.message.length > 0 && (
                <AlertInfo
                  content={error.message}
                  style={{ marginBottom: 0 }}
                />
              )}

              {dataEmail.verifiedEmail && (
                <AlertInfo
                  content={'Email number has been verified'}
                  severity='success'
                  style={{ marginBottom: 0 }}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}

      {/* <Box className={classes.AlertMainSection}>
        <Box className={classes.HeaderLogoContainer}>
          <Box className={classes.HeaderLogoImage}>
            <img className={classes.CrossImage} src={Cross} alt="Cross" />
          </Box>
        </Box>
        <Typography className={classes.AlertText}>
          Invalid One Time Password
        </Typography>
       
      </Box> */}
    </Dialog>
  )
}

export default EmailOtpDialog
