import React from 'react'
import { Box } from '@mui/system'
import { useStyles } from './Style'
import InputLabel from '@mui/material/InputLabel'
import { Typography } from '@mui/material'
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'BZD',
})
const Total = ({ total }) => {
  const classes = useStyles()

  return (
    <Box>
      <InputLabel style={{marginTop: '4rem', textAlign: 'center'}}>
        Total
      </InputLabel>
      <Box className={classes.MainContainer}>
        <Typography variant="h6" className={classes.value}>
          {formatter.format(total)}
        </Typography>
      </Box>
    </Box>
  )
}

export default Total
