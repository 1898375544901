import React, { Fragment, useEffect, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { useStyles } from './MonthlyExpensesStyle'
// import MonthlyCreditData from './Monthly'
import Total from '../../Components/Inputs/Total2'
import Radio from '@mui/material/Radio'
import { Stack } from '@mui/material'
import { InputLabel, Typography, Button } from '@material-ui/core'
import ArrowButton from '../../Components/ArrowButton/ArrowButton'
// import { useDispatch } from 'react-redux'
import { useRef } from 'react'

import LoanWithUs from './LoanWithUs'
import MonthlyCreditData from './MonthlyCreditData'
import { updateApplicationStep } from '../../context/global-application'
import { useDispatch, useSelector } from 'react-redux'
import { APPLICATION_STEP } from '../../options'
import { useNavigate } from 'react-router-dom'

const MonthlyExpensesCC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isValid, setIsValid] = useState(true)
  const [isChecked, setChecked] = useState(false)

  React.useEffect(() => {
    console.log('isChecked', isChecked)
  }, [isChecked])
  const [radioButtonError, setRadioButtonError] = useState('')
  const countPage = useRef(0)

  useEffect(() => {
    if (!isChecked) {
      setRadioButtonError(
        'You must confirm the information is true and accurate.'
      )
      setIsValid(false)
    }
    else {
      setRadioButtonError('')
      setIsValid(true)
    }
  }, [isChecked])


  const { totalObligations, totalCurrentObligations } = useSelector(
    (state) => state.ccMonthlyExpenses
  )

  React.useEffect(() => {
    dispatch(updateApplicationStep('monthly-expenses-cc'))
  }, [])

  React.useEffect(() => {
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])

  return (
    <Box>
      <Typography>
        PLEASE COMPLETE THE INFORMATION BELOW WITH YOUR MONTHLY CREDIT
        OBLIGATIONS
      </Typography>
      <Fragment>
        <LoanWithUs />
        <MonthlyCreditData />
        <Box className={classes.Total}>
          <Total total={totalCurrentObligations + totalObligations} />
        </Box>
        <Grid container spacing={2} alignItems='center' mt={1}>
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.RadioLabel}>
              <Radio
                checked={isChecked}
                onClick={() => {
                  setChecked(!isChecked)
                  // console.log()
                }}
              />
              I confirm that all the information provided above is true and
              accurate.
            </InputLabel>
          </Grid>
        </Grid>
        <Stack className={classes.otherErrorMessage}>
          <Typography>{radioButtonError}</Typography>
        </Stack>
        <ArrowButton
          link={isChecked ? APPLICATION_STEP['proof-of-income-cc'].path : '#'}
          back='/credit-card/monthly-income'
        />
      </Fragment>
    </Box>
  )
}

export default MonthlyExpensesCC
