import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  createCreditLimitRequest,
  getCreditLimitRequestData,
  getCreditLimitRequestSelectedCard,
  getInsuranceList,
  getSelectedCardID,
  updateCreditLimitRequest
} from './store'
import Insurance from './Insurance'
import SelectedCard from './SelectedCard'
import CreditLimitForm from './CreditLimitForm'
import { Box, Typography } from '@mui/material'
import ModalContainer from '../../../Components/AtomicDesign/atoms/ModalContainer/ModalContainer'
import { formatBelizeCurrency } from '../../../Utils/utils'
import ChangeCardModal from './modals/ChangeCardModal'
import toast, { Toaster } from 'react-hot-toast'

const CreditLimitRequest = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    pending,
    errors,
    cardID,
    hasCreditLimitRequestData,
    selectedCard,
    creditAmount,
    promoCode,
    selectedInsurance
  } = useSelector((state) => state.creditLimitRequest)

  const { innerWidth: windowWidthValue } = window

  const [openProccedModal, setOpenProccedModal] = useState(false)
  const [openChangeModal, setOpenChangeModal] = useState(false)
  const [showRejectMessage, setShowRejectMessage] = useState(false)
  const [isApplicantStep, setIsApplicantStep] = useState(false)

  const ensureSendData = () => {
    if (!creditAmount) return
    const data = {
      requestedLimit: creditAmount,
      selectedInsurances: []
    }

    if (
      selectedInsurance &&
      selectedInsurance.id &&
      showRejectMessage === false
    )
      data.selectedInsurances.push({ insuranceOptionID: selectedInsurance.id })

    if (promoCode && promoCode.promoCodeID) {
      data.promoCodeID = promoCode.promoCodeID
    }

    return data
  }

  const loadData = () => {
    try {
      dispatch(getCreditLimitRequestData())
      dispatch(getInsuranceList())
      dispatch(getSelectedCardID())
    } catch (error) {
    } finally {
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    loadData()
  }, [])

  React.useEffect(() => {
    if (!cardID) return
    dispatch(getCreditLimitRequestSelectedCard(cardID))
  }, [cardID])

  React.useEffect(() => {
    if (errors && errors.sendCreditLimit && errors.sendCreditLimit.error) {
      toast.error(
        'Something went wrong while sending Credit Limit, please try again later.'
      )
    }
  }, [errors?.sendCreditLimit])

  const onSubmit = () => {
    const sendData = ensureSendData()

    if (hasCreditLimitRequestData) {
      dispatch(updateCreditLimitRequest(sendData)).then((res) => {
        if (res && res.payload && res.payload.success) {
          setIsApplicantStep(true)
        }
      })
      return
    }

    dispatch(createCreditLimitRequest(sendData)).then((res) => {
      if (res && res.payload && res.payload.success) {
        setIsApplicantStep(true)
      }
    })
  }

  return (
    <Box>
      <div className={classes.Container}>
        <div className={classes.ButtonTitle}>Credit Limit Request</div>

        <div className={classes.CreditCardLimitContainer}>
          <SelectedCard />

          <div className={classes.CreditCardLimit}>
            <CreditLimitForm />
          </div>
        </div>

        <Insurance />

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '2rem'
          }}
        >
          <Button
            className={classes.BtnConfirm}
            disabled={!selectedInsurance}
            onClick={() => {
              setShowRejectMessage(false)
              setOpenProccedModal(true)
            }}
          >
            Confirm
          </Button>
          <Button
            className={classes.BtnReject}
            onClick={() => {
              setShowRejectMessage(true)
              setOpenProccedModal(true)
            }}
          >
            Reject
          </Button>
        </div>
      </div>

      <ModalContainer
        open={openProccedModal}
        width={'670px'}
        onClose={() => {
          if (!isApplicantStep) setOpenProccedModal(false)
        }}
      >
        <div style={{ padding: '2rem', maxHeight: '632px', overflow: 'auto' }}>
          {!isApplicantStep ? (
            <>
              <div
                style={{
                  textAlign: 'center',
                  margin: '0 auto',
                  marginBottom: '2rem'
                }}
              >
                <Typography fontSize='24px'>
                  {showRejectMessage ? 'Insurance rejected' : 'Resume'}
                </Typography>

                {showRejectMessage && (
                  <Typography>
                    Kindly note that you can choose to add the insurance to your
                    credit card at any point in time.
                  </Typography>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  gap: '16px',
                  padding: '1.8rem 24px',
                  border: '1px solid #D9D9D9',
                  borderRadius: '8px'
                }}
              >
                <div>
                  <Typography color={'#666C89'} fontSize={14}>
                    Selected Card
                  </Typography>
                  <Typography
                    color={'#091242'}
                    fontSize={16}
                    fontWeight={700}
                    maxWidth={'232px'}
                  >
                    {selectedCard.title}
                  </Typography>
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                      marginTop: '1rem'
                    }}
                  >
                    {selectedCard.benefits.map((item, indx) => (
                      <span key={indx} style={{ display: 'flex', gap: '1rem' }}>
                        <img width={16} height={16} src={item.icon} alt='' />
                        <Typography color='#868686' fontSize={12}>
                          {` ${item.title}`}
                        </Typography>
                      </span>
                    ))}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <img
                    style={{
                      width: windowWidthValue > 364 ? 215 : 170,
                      marginBottom: 12
                    }}
                    src={selectedCard.cardImage}
                    alt='selected card'
                  />
                  <Button
                    className={classes.BtnReject}
                    onClick={() => {
                      setOpenProccedModal(false)
                      setOpenChangeModal(true)
                    }}
                  >
                    Select a different card
                  </Button>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  padding: '1.5rem 24px',
                  border: '1px solid #D9D9D9',
                  borderRadius: '8px',
                  marginTop: '1rem'
                }}
              >
                <Typography color={'#666C89'} fontSize={16} fontWeight={400}>
                  Credit Limit Request:{' '}
                  <span className={classes.resumeSpan}>
                    {formatBelizeCurrency(creditAmount)}
                  </span>
                </Typography>

                {promoCode && promoCode.promoCode && (
                  <Typography color={'#666C89'} fontSize={16} fontWeight={400}>
                    Promo Code:{' '}
                    <span className={classes.resumeSpan}>
                      {promoCode.promoCode}
                    </span>
                  </Typography>
                )}

                {!showRejectMessage && selectedInsurance ? (
                  <Typography color={'#666C89'} fontSize={16} fontWeight={400}>
                    Insurance:{' '}
                    <span className={classes.resumeSpan}>
                      {selectedInsurance.title}
                    </span>
                  </Typography>
                ) : (
                  <Typography color={'#666C89'} fontSize={16} fontWeight={400}>
                    Not insurance.
                  </Typography>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1rem'
                }}
              >
                <Button
                  style={{ margin: '1rem auto' }}
                  className={classes.BtnConfirm}
                  onClick={onSubmit}
                  disabled={pending}
                >
                  {pending ? '...' : 'Proceed'}
                </Button>
              </div>
            </>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Typography color='#706F6F'>
                Do you wish to add any co-applicants to your credit card?
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  gap: '2rem',
                  marginTop: '2rem'
                }}
              >
                <Button
                  className={classes.BtnConfirm}
                  onClick={() => navigate('/credit-card/additional-applicants')}
                >
                  Yes
                </Button>
                <Button
                  className={classes.BtnReject}
                  onClick={() => navigate('/credit-card/monthly-income')}
                >
                  No
                </Button>
              </div>
            </div>
          )}
        </div>
      </ModalContainer>

      {openChangeModal && (
        <ChangeCardModal
          isOpen={openChangeModal}
          setOpenModal={setOpenChangeModal}
        />
      )}

      <Toaster />
    </Box>
  )
}

export default CreditLimitRequest
