import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../../auth/jwt/JwtCreditCard'

const initialError = (message = '') => ({
  statusCode: null,
  name: '',
  message
})

export const getProofOfIncomeInfoCall = createAsyncThunk('appProofOfIncome/info',  async () => {
    const response = await UseJwt.getProofOfIncome()
    return response.data
})

export const salaryCatalogCall = createAsyncThunk('appProofOfIncome/salaryCatalogCall', async () => {
    const response = await UseJwt.getSalaryCatalog()
    return response.data
})

export const jobLetterCatalogCall = createAsyncThunk('appProofOfIncome/jobLetterCatalogCall', async () => {
    const response = await UseJwt.getJobLetterCatalog()
    return response.data
})

export const bankCatalogCall = createAsyncThunk('appProofOfIncome/bankCatalogCall', async (_, { rejectWithValue }) => {
    const response = await UseJwt.getBankCatalog()
    return response.data
})

export const postProofOfIncomeCall = createAsyncThunk(
  'appProofOfIncome/postProofOfIncomeCall',
  async (body, { rejectWithValue }) => {
    try {
      const response = await UseJwt.postProofOfIncome(body)
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong while creating proof of income')
      )
    }
  }
)

export const updateProofOfIncomeCall = createAsyncThunk('appProofOfIncome/updateProofOfIncomeCall',
  async (body, { rejectWithValue }) => {
    try {
      const response = await UseJwt.updateProofOfIncome(body)
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong while updating proof of income')
      )
    }
  }
)

export const appProofOfIncome = createSlice({
  name: 'appProofOfIncome',
  initialState: {
    pending: true,
    error: initialError(),
    data: {},
    formData: {
      salaryId: '',
      jobLetterId: '',
      bankId: '',
      nextAction: 'CREATE'
    },
    catalogs: {
      salaryCatalog: [],
      jobLetterCatalog: [],
      bankCatalog: []
    }
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload }
    }
  },
  extraReducers: {
    [salaryCatalogCall.fulfilled]: (state, action) => {
      state.catalogs.salaryCatalog = action.payload
    },
    [jobLetterCatalogCall.fulfilled]: (state, action) => {
      state.catalogs.jobLetterCatalog = action.payload
    },
    [bankCatalogCall.fulfilled]: (state, action) => {
      state.catalogs.bankCatalog = action.payload
    },
  }
})

export const { setFormData } = appProofOfIncome.actions

export default appProofOfIncome.reducer
