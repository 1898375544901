import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../../auth/jwt/JwtCreditCard'

const initialError = (message = '') => ({
  statusCode: null,
  name: '',
  message
})

export const addApplicantInfoCall = createAsyncThunk(
  'appAdditionalApplicants/info',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getAdditionalApplicantsInfo()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError(
            'Something went wrong getting the additional applicants info'
          )
      )
    }
  }
)

export const applicantsTypeCatalogCall = createAsyncThunk(
  'appAdditionalApplicants/applicantsTypeCatalog',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getApplicantsTypeCatalog()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const districtsCatalogCall = createAsyncThunk(
  'appAdditionalApplicants/districtsCatalog',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getDistrictsCatalog()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const urbanAreasCatalogCall = createAsyncThunk(
  'appAdditionalApplicants/urbanAreasCatalog',
  async (param, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getUrbanAreasCatalog(param)
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError(
            'Something went wrong while getting the City/Urban catalog'
          )
      )
    }
  }
)

export const relationshipsCatalogCall = createAsyncThunk(
  'appAdditionalApplicants/relationshipsCatalog',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getRelationshipsCatalog()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const countriesCatalogCall = createAsyncThunk(
  'appAdditionalApplicants/countriesCatalog',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getCountriesCatalog()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const statesCatalogCall = createAsyncThunk(
  'appAdditionalApplicants/statesCatalog',
  async (param, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getStatesCatalog(param)
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError(
            'Something went wrong while getting the States/District catalog'
          )
      )
    }
  }
)

export const occupantsCatalogCall = createAsyncThunk(
  'appAdditionalApplicants/occupantsCatalog',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getOccupantsCatalog()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const titlePersonCatalogCall = createAsyncThunk(
  'appAdditionalApplicants/titlePersonCatalog',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getTitlePersonCatalog()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const postAdditionalApplicantCall = createAsyncThunk(
  'appAdditionalApplicants/postAdditionalApplicant',
  async (body, { rejectWithValue }) => {
    try {
      const response = await UseJwt.postAdditionalApplicants(body)
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError(
            'Something went wrong while creating additionals applicants'
          )
      )
    }
  }
)

export const updateAdditionalApplicantCall = createAsyncThunk(
  'appAdditionalApplicants/updateAdditionalApplicant',
  async (body, { rejectWithValue }) => {
    try {
      const response = await UseJwt.updateAdditionalApplicants(body)
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError(
            'Something went wrong while updating additionals applicants'
          )
      )
    }
  }
)

export const fetchPrimaryCatalogs = createAsyncThunk(
  'appAdditionalApplicants/fetchPrimaryCatalogs',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const results = await Promise.all([
        dispatch(applicantsTypeCatalogCall()).unwrap(),
        dispatch(titlePersonCatalogCall()).unwrap(),
        dispatch(occupantsCatalogCall()).unwrap(),
        dispatch(countriesCatalogCall()).unwrap(),
        dispatch(relationshipsCatalogCall()).unwrap(),
        dispatch(districtsCatalogCall()).unwrap()
      ])

      return {
        applicantsTypeCatalog: results[0],
        titlePersonCatalog: results[1],
        occupantsCatalog: results[2],
        countriesCatalog: results[3],
        relationshipsCatalog: results[4],
        districtsCatalog: results[5]
      }
    } catch (error) {
      return rejectWithValue(initialError('Failed to load catalogs'))
    }
  }
)

export const appAdditionalApplicants = createSlice({
  name: 'appAdditionalApplicants',
  initialState: {
    updateAdditionalsApplicants: false,
    data: {},
    additionalApplicantId: null,
    catalogs: {
      applicantsTypeCatalog: [],
      titlePersonCatalog: [],
      districtsCatalog: [],
      urbanAreasCatalog: [],
      relationshipsCatalog: [],
      countriesCatalog: [],
      statesCatalog: [],
      occupantsCatalog: []
    },
    pending: true,
    error: initialError()
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(addApplicantInfoCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(addApplicantInfoCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        state.data = action.payload
        state.updateAdditionalsApplicants = true
        state.additionalApplicantId =
          action.payload?.applicantDetails?.additionalApplicantDetailID || null
      })
      .addCase(addApplicantInfoCall.rejected, (state, action) => {
        state.pending = false
        if (
          action.payload.statusCode === 404 &&
          action.payload.message === 'Additional Applicants not found'
        ) {
          state.updateAdditionalsApplicants = false
        } else {
          state.error = action.payload
        }
      }) // fetchPrimaryCatalogs
      .addCase(fetchPrimaryCatalogs.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(fetchPrimaryCatalogs.fulfilled, (state) => {
        state.pending = false
        state.error = initialError()
      })
      .addCase(fetchPrimaryCatalogs.rejected, (state, action) => {
        state.pending = false
        state.error = action.payload
      })
      // individuals get catalog from fetchPrimaryCatalogs call
      .addCase(applicantsTypeCatalogCall.fulfilled, (state, action) => {
        state.catalogs.applicantsTypeCatalog = action.payload
      })
      .addCase(titlePersonCatalogCall.fulfilled, (state, action) => {
        state.catalogs.titlePersonCatalog = action.payload
      })
      .addCase(relationshipsCatalogCall.fulfilled, (state, action) => {
        state.catalogs.relationshipsCatalog = action.payload
      })
      .addCase(countriesCatalogCall.fulfilled, (state, action) => {
        state.catalogs.countriesCatalog = action.payload
      })
      .addCase(districtsCatalogCall.fulfilled, (state, action) => {
        state.catalogs.districtsCatalog = action.payload
      })
      .addCase(occupantsCatalogCall.fulfilled, (state, action) => {
        state.catalogs.occupantsCatalog = action.payload
      }) // statesCatalogCall
      .addCase(statesCatalogCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(statesCatalogCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        state.catalogs.statesCatalog = action.payload
      })
      .addCase(statesCatalogCall.rejected, (state, action) => {
        state.pending = false
        state.error = action.payload
      }) // urbanAreasCatalogCall
      .addCase(urbanAreasCatalogCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(urbanAreasCatalogCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        state.catalogs.urbanAreasCatalog = action.payload
      })
      .addCase(urbanAreasCatalogCall.rejected, (state, action) => {
        state.pending = false
        state.error = action.payload
      }) // postAdditionalApplicantCall
      .addCase(postAdditionalApplicantCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(postAdditionalApplicantCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        state.data = action.payload
      })
      .addCase(postAdditionalApplicantCall.rejected, (state, action) => {
        state.pending = false
        state.error = action.payload
      }) // updateAdditionalApplicantCall
      .addCase(updateAdditionalApplicantCall.pending, (state) => {
        state.pending = true
        state.error = initialError()
      })
      .addCase(updateAdditionalApplicantCall.fulfilled, (state, action) => {
        state.pending = false
        state.error = initialError()
        state.data = action.payload
      })
      .addCase(updateAdditionalApplicantCall.rejected, (state, action) => {
        state.pending = false
        state.error = action.payload
      })
})

export default appAdditionalApplicants.reducer
