import PersonalInfoReview from '../../../assets/CCImages/svg/pir.svg'
import CreditLimit from '../../../assets/CCImages/svg/CREDIT LIMIT REQUEST.svg'
import AdditionalApplicants from '../../../assets/CCImages/svg/ADDITIONAL APPLICANTS.svg'
import MonthlyIcome from '../../../assets/CCImages/svg/MONTHLY INCOME.svg'
import MonthlyExpenses from '../../../assets/CCImages/svg/MONTHLY EXPENSES.svg'
import ProofOfIncome from '../../../assets/CCImages/svg/PROOF OF INCOME.svg'
import UploadDocs from '../../../assets/CCImages/svg/UPLOAD DOCUMENTS.svg'

export const ProcessArray = [
  {
    id: 1,
    Title: 'PERSONAL INFORMATION REVIEW',
    Icon: PersonalInfoReview,
    Link: '/credit-card/personal-information-review'
  },
  {
    id: 2,
    Title: 'CREDIT LIMIT REQUEST',
    Icon: CreditLimit,
    Link: '/credit-card/credit-limit-request'
  },
  {
    id: 3,
    Title: 'ADDITIONAL APPLICANTS',
    Icon: AdditionalApplicants,
    Link: '/credit-card/additional-applicants'
  },
  {
    id: 4,
    Title: 'MONTHLY INCOME',
    Icon: MonthlyIcome,
    Link: '/credit-card/monthly-income'
  },
  {
    id: 5,
    Title: 'MONTHLY EXPENSES',
    Icon: MonthlyExpenses,
    Link: '/credit-card/monthly-expenses'
  },
  {
    id: 6,
    Title: 'PROOF OF INCOME',
    Icon: ProofOfIncome,
    Link: '/credit-card/proof-of-income'
  },
  {
    id: 7,
    Title: 'UPLOAD DOCUMENTS',
    Icon: UploadDocs,
    Link: '/credit-card/upload-documents'
  }
]
