import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home/Home";
import EnrollNowPage from "../Pages/EnrollNowPage/EnrollNowPage";
import EnrollLaterPage from "../Pages/EnrollLaterPage/EnrolLaterPage";
import NonCustomerPage from "../Pages/Customer/NonCustomerPage";
import PrivateGuard from "./PrivateGuard";
import PAGES from "./Pages";
import ErrorContainer from "../Components/ErrorContainer/ErrorContainer";
import MessageSection from "../Components/MessageSection/MessageSection";
import PersonalInformationReview from "../Pages/initial-loan-process/screen/personal-information-review/"
import { ProductsAndServices } from "../Pages/ProductsServices/ProductsAndServices";
import ThankYouPage from "../Pages/ThankYouPage/ThankYouPage";
import { ConfirmReference } from "../Components/ReferenceNumber/ReferencePopUp";
import LoanHistory from "../Components/LoanHistory/LoanHistory"
import Status from "../Components/Forms/LoanStatus/Status";
import FeedBack from "../Pages/feedback";
import AgeLimit from '../Pages/AgeLimit'
import InitialLoanProcess from "../Pages/initial-loan-process/"
import CashLoanCollateral from "../Pages/initial-loan-process/screen/loan-collateral/"
import IssuedOffer from "../Pages/IssuedOffer/IssuedOffer";
import CreditCardContainerPage from "../CreditCardPhaseOne/Pages/CreditCardContainer/CreditCardContainer";
import CC_PAGES from "./CCPages";
import SelectCreditCard from "../CreditCardPhaseOne/Pages/SelectCreditCard/SelectCreditCard";
import HomeCreditCardPage from '../CreditCardPhaseOne/Pages/home'
import PrivateGuardCC from './PrivateGuardCC'
import CreditCardApplication from '../CreditCardPhaseOne/Pages/CreditCardApplication/CreditCardApplication'
import MyApplications from '../CreditCardPhaseOne/Pages/MyApplications'

const Changes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/EnrollNowPage" element={<EnrollNowPage />} />
      <Route path="/EnrollLaterPage" element={<EnrollLaterPage />} />
      <Route path="/ProductsAndServices" element={<ProductsAndServices />}/>
      <Route exact path="/feedback" element={<PrivateGuard><FeedBack /></PrivateGuard>}/>
      <Route exact path="/initial-loan-process" element={<InitialLoanProcess />}/>
      <Route exact path="/cash-loan-collateral" element={<CashLoanCollateral />}/>
      <Route exact path='issued-offer' element={<IssuedOffer/>} />
      {/* <Route exact path="/feedback" element={<FeedBack />}/> */}
      {/* <Route path="/refer-a-friend" element={<ModalRefFriend open={true} />}/> */}
      <Route path="/ReferenceNumber" element={<ConfirmReference/>}/>
      <Route exact path="/Customer" element={<NonCustomerPage />}>
        {PAGES.map(
          ({
            key,
            yourLoanRequestpages,
            statuspage,
            Component,
            Protected,
          }) => {
            return (
              <Route
                key={key}
                exact
                path={`/Customer${key}`}
                element={
                  Protected ? (
                    <PrivateGuard>
                      <Component />
                    </PrivateGuard>
                  ) : (
                    <Component />
                  )
                }
              >
                {yourLoanRequestpages &&
                  yourLoanRequestpages.length &&
                  yourLoanRequestpages.map(({ key, Component, Protected }) => {
                    return (
                      <Route
                        key={key}
                        exact
                        path={`/Customer/yourLoanRequest${key}`}
                        element={
                          Protected ? (
                            <PrivateGuard>
                              <Component />
                           </PrivateGuard>
                          ) : (
                            <Component />
                          )
                        }
                      />
                    );
                  })}
              </Route>
            );
          }
        )}
      </Route>
      <Route path="/Customer/Congratulations" element={<ThankYouPage/>} />
      <Route exact path="/Loan-Status" element={ <Status /> } />
      <Route exact path="/CustomerChat" element={<MessageSection />} />
      <Route exact path="PersonalInformationReview" element={<PersonalInformationReview/>} />
      <Route exact path="/LoanHistory" element={<LoanHistory/>} />
      <Route exact path="/error-age-limit" element={<AgeLimit />} />
      <Route path="*" element={<ErrorContainer />} />
      {/* // ? Credit Card Private Pages */}
      <Route exact path="/credit-card-application" element={<HomeCreditCardPage />}/>
      <Route
        path="/available-credit-cards"
        element={
          <PrivateGuardCC>
            <SelectCreditCard />
          </PrivateGuardCC>
        }
      />
      <Route
        path="/select-options"
        element={
          <PrivateGuardCC>
            <CreditCardApplication />
          </PrivateGuardCC>
          }
      />
      <Route
        path="/my-applications"
        element={
          <PrivateGuardCC>
            <MyApplications />
          </PrivateGuardCC>
          }
      />
      <Route exact path="/credit-card" element={<CreditCardContainerPage />}>
      {CC_PAGES.map(
          ({
            key,
            yourLoanRequestpages,
            statuspage,
            Component,
            Protected,
          }) => {
            return (
              <Route
                key={key}
                exact
                path={`/credit-card/${key}`}
                element={
                  Protected ? (
                    <PrivateGuardCC>
                      <Component />
                    </PrivateGuardCC>
                  ) : (
                    <Component />
                  )
                }
              >
                {/* {yourLoanRequestpages &&
                  yourLoanRequestpages.length &&
                  yourLoanRequestpages.map(({ key, Component, Protected }) => {
                    return (
                      <Route
                        key={key}
                        exact
                        path={`/Customer/yourLoanRequest${key}`}
                        element={
                          Protected ? (
                            <PrivateGuard>
                              <Component />
                           </PrivateGuard>
                          ) : (
                            <Component />
                          )
                        }
                      />
                    );
                  })} */}
              </Route>
            );
          }
        )}
      </Route>
    </Routes>
  );
};

export default Changes;
