export const CARD_POSITION = {
  1: 'cardOne',
  2: 'cardTwo',
  3: 'cardThree'
}

export const STEP_A_ADVANTAGE = {
  hasAAdvantage: 'hasAAdvantage',
  aAdvantageNumber: 'aAdvantageNumber',
  skipAAdvantage: 'skipAAdvantage'
}