import * as React from 'react'
import { alpha, styled, withStyles } from '@mui/material/styles'
import { Box } from '@mui/system'
import InputLabel from '@mui/material/InputLabel'
import { FormControl, Typography } from '@mui/material'
import { useStyles } from './Style'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { Stack } from '@mui/system'
import TextField from '@mui/material/TextField'
import Currency from '../../Components/Inputs/currency'
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, backgroundColor = '#F07F3C' }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor,
    color: 'white',
    fontSize: 13,
    maxWidth: 350,
    filter: 'drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))',
    // marginLeft: "-13rem",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: backgroundColor,
    '&::before': {
      backgroundColor: backgroundColor,
    },
  },
}))
export default function CustomizedInputs({
  Lable,
  className,
  type,
  width,
  Tooltiptitle,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  id,
  Title,
  ...props
}) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  return (
    <Stack fullWidth={true} sx={{ width: '100%' }}>
      <FormControl
        sx={{ width: '100%' }}
        variant="standard"
        className={[classes.FormWrapper, className].join(' ')}
      >
        <InputLabel
          shrink
          // htmlFor="bootstrap-input"
          className={classes.InputLable}
        >
          {Lable}
          <span>
            <LightTooltip title="Income after expenses" placement="top" arrow open={open} onClick={() => setOpen(!open)}>
              <Typography variant={'p'} className={classes.btnTitle}>
                <InfoIcon style={{ marginLeft: 10, color: '#F07F3C' }} />
              </Typography>
            </LightTooltip>
          </span>
        </InputLabel>
        <Currency {...props} error={error} onChange={onChange} value={value} />
      </FormControl>
    </Stack>
  )
}
