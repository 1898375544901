import PersonalCC1 from '../../../../assets/CCImages/personalcc1.png'
import PersonalCC2 from '../../../../assets/CCImages/personalcc2.png'
import PersonalCC3 from '../../../../assets/CCImages/personalcc3.png'
import PersonalCC4 from '../../../../assets/CCImages/personalcc4.png'
import PersonalCC5 from '../../../../assets/CCImages/personalcc5.png'
import Coin from '../../../../assets/CCImages/coin.png'
import Airplane from '../../../../assets/CCImages/airplane.png'
import Globe from '../../../../assets/CCImages/global.png'
import Shield from '../../../../assets/CCImages/security-card.png'
import Car from '../../../../assets/CCImages/car.png'
import Medal from '../../../../assets/CCImages/medal-star.png'

export const PersonalCreditCardList = [
  {
    id: 1,
    cardType: 'personal',
    title: 'MC AA Standard',
    cardImage: PersonalCC1,
    bulletPoints: [
      {
        id: 1,
        text: '22% interest rate'
      },
      {
        id: 2,
        text: 'International spending limit: US$7,500 monthly'
      },
      {
        id: 3,
        text: 'Minimum payment: 3% of outstanding balance; minimum BZ $50'
      }
    ],
    benefits: [
      {
        id: 1,
        icon: Coin,
        text: 'Make automatic payments with standing order'
      },
      {
        id: 2,
        icon: Airplane,
        text: 'AAdvantage® Miles lets you earn AAdvantage® miles and Loyalty Points'
      },
      {
        id: 3,
        icon: Globe,
        text: 'Get emergency card-related assistance any time, anywhere with Master Card Global services.'
      },
      {
        id: 4,
        icon: Shield,
        text: 'Have access to Purchases Protection, which reimburses you for theft and/or accidental damage of purchased items.'
      }
    ],
    buttonText: 'Learn more',
    checkboxText: 'Add to comparasion',
    features: [
      {
        title: 'Interest',
        value: '22%'
      },
      {
        title: 'Minimum Payment',
        value: '5% of outstanding balance; minimum BZ $50'
      },
      {
        title: 'Annual membership fee-primary card',
        value: 'BZ$160'
      },
      {
        title: 'Transaction Limit',
        value: 'Number of Transactions: 10 daily, 40 weekly'
      },
      {
        title: 'US Spending Limit',
        value: 'US$7,500 monthly / US$35,000 annually'
      },
      {
        title: 'Monthly Statement Cycle',
        value: 'every 16th'
      }
    ]
  },
  {
    id: 2,
    cardType: 'personal',
    title: 'MC AA Platinum',
    cardImage: PersonalCC2,
    bulletPoints: [
      {
        id: 1,
        text: '22% interest rate'
      },
      {
        id: 2,
        text: 'International spending limit: US$7,500 monthly'
      },
      {
        id: 3,
        text: 'Minimum payment: 3% of outstanding balance; minimum BZ $50'
      }
    ],
    benefits: [
      {
        id: 1,
        icon: Coin,
        text: 'Make automatic payments with standing order'
      },
      {
        id: 2,
        icon: Airplane,
        text: 'AAdvantage® Miles lets you earn AAdvantage® miles and Loyalty Points'
      },
      {
        id: 3,
        icon: Globe,
        text: 'Get emergency card-related assistance any time, anywhere with Master Card Global services.'
      },
      {
        id: 4,
        icon: Car,
        text: 'Have access to Master Rental, which pays for damages up to US$75,000 to a rental vehicle.'
      },
      {
        id: 5,
        icon: Globe,
        text: 'Get reimburses on medical expenses, hotel convalescence, emergency family travel costs, and more with Master Assist Plus.'
      },
      {
        id: 6,
        icon: Medal,
        text: 'Enjoy personal assistance including recommendations and reservations during your travels with Concierge Service.'
      },
      {
        id: 7,
        icon: Shield,
        text: ' Receive upgrades, free breakfast, early check-in/late check-out and special amenities at 1300+ 4 and 5 star hotels & resorts worldwide with Mastercard Travel Services'
      },
      {
        id: 8,
        icon: Airplane,
        text: 'Obtain pre-trip destination information, referral information in case of medical assistance, and legal referrals with Travel Assistance.'
      }
    ],
    buttonText: 'Learn more',
    checkboxText: 'Add to comparasion'
  },
  {
    id: 3,
    cardType: 'personal',
    title: 'Mastercard (MC) Standard',
    cardImage: PersonalCC3,
    bulletPoints: [
      {
        id: 1,
        text: '22% interest rate'
      },
      {
        id: 2,
        text: 'International spending limit: US$7,500 monthly'
      },
      {
        id: 3,
        text: 'Minimum payment: 3% of outstanding balance; minimum BZ $50'
      }
    ],
    benefits: [
      {
        id: 1,
        icon: Coin,
        text: 'Make automatic payments with standing order'
      },
      {
        id: 2,
        icon: Globe,
        text: 'Get emergency card-related assistance any time, anywhere with Master Card Global services.'
      },
      {
        id: 3,
        icon: Shield,
        text: 'Have access to Purchases Protection, which reimburses you for theft and/or accidental damage of purchased items.'
      }
    ],
    buttonText: 'Learn more',
    checkboxText: 'Add to comparasion'
  },
  {
    id: 4,
    cardType: 'personal',
    title: 'Mastercard Platinum',
    cardImage: PersonalCC4,
    bulletPoints: [
      {
        id: 1,
        text: '22% interest rate'
      },
      {
        id: 2,
        text: 'International spending limit: US$7,500 monthly'
      },
      {
        id: 3,
        text: 'Minimum payment: 3% of outstanding balance; minimum BZ $50'
      }
    ],
    benefits: [
      {
        id: 1,
        icon: Car,
        text: 'Have access to Master Rental, which pays for damages up to US$75,000 to a rental vehicle.'
      },
      {
        id: 2,
        icon: Globe,
        text: 'Get reimburses on medical expenses, hotel convalescence, emergency family travel costs, and more with Master Assist Plus.'
      },
      {
        id: 3,
        icon: Medal,
        text: 'Enjoy personal assistance including recommendations and reservations during your travels with Concierge Service.'
      },
      {
        id: 4,
        icon: Shield,
        text: ' Receive upgrades, free breakfast, early check-in/late check-out and special amenities at 1300+ 4 and 5 star hotels & resorts worldwide with Mastercard Travel Services'
      },
      {
        id: 5,
        icon: Airplane,
        text: 'Obtain pre-trip destination information, referral information in case of medical assistance, and legal referrals with Travel Assistance.'
      }
    ],
    buttonText: 'Learn more',
    checkboxText: 'Add to comparasion'
  },
  {
    id: 5,
    cardType: 'personal',
    title: 'Visa Classic',
    cardImage: PersonalCC5,
    bulletPoints: [
      {
        id: 1,
        text: '22% interest rate'
      },
      {
        id: 2,
        text: 'International spending limit: US$7,500 monthly'
      },
      {
        id: 3,
        text: 'Minimum payment: 3% of outstanding balance; minimum BZ $50'
      }
    ],
    benefits: [
      {
        id: 1,
        icon: Coin,
        text: 'Make automatic payments with standing order'
      },
      {
        id: 2,
        icon: Shield,
        text: 'Price Protection provides you with up to USD $200 refund per year'
      }
    ],
    buttonText: 'Learn more',
    checkboxText: 'Add to comparasion'
  }
]

const ICON_GALLERY = {
  alarm: Coin,
  car: Car,
  coin: Coin,
  coverage: Globe,
  plane: Airplane,
  planet: Globe,
  reward: Medal,
  security: Shield,
  warranty: Medal
}

const TITLE_BENEFIT = {
  alarm: 'Fraud Alerts',
  car: 'Car Rental Insurance',
  coin: 'Standing Order',
  coverage: 'Purchase Protection',
  plane: 'Travel Insurance',
  planet: 'Travel Assistance',
  reward: 'Reward Points',
  security: 'MasterCard Global Services',
  warranty: 'Extended Warranty'
}

export const personalCreditCardAdapter = (data) => {
  if (!data) return []

  const bulletPointsAdapter = (data) => {
    return data?.map((point) => {
      return {
        id: point?.id,
        text: point?.name
      }
    })
  }

  const benefitsAdapter = (data) => {
    return data?.map((benefit) => {
      return {
        id: benefit?.id,
        icon: ICON_GALLERY[benefit?.iconType],
        title: TITLE_BENEFIT[benefit?.iconType],
        text: benefit?.name
      }
    })
  }

  const featuresAdapter = (data) => {
    return data?.map((feature) => {
      return {
        title: feature?.name,
        value: feature?.value
      }
    })
  }

  const adaptCard = (card) => ({
    id: card?.id,
    active: card?.active,
    title: `${card?.franchise} ${card?.category}`,
    cardType: card?.franchise,
    cardImage: card?.img,
    bulletPoints: bulletPointsAdapter(card?.features),
    benefits: benefitsAdapter(card?.benefits),
    buttonText: card?.buttonText,
    checkboxText: card?.checkboxText,
    features: featuresAdapter(card?.features),
    segment: card?.segment,
    category: card?.category,
    franchise: card?.franchise,
    requiredAA: card?.requiredAA
  })

  return Array.isArray(data) ? data.map(adaptCard) : adaptCard(data)
}
