import React from 'react'
import Button from '../../../Components/Button/Button'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useStyles } from './ArrowButtonStyle'
import { Link } from 'react-router-dom'

import { toast, Toaster } from 'react-hot-toast'

const ArrowButton = ({
  link,
  back,
  onClick,
  onClickBackBtn,
  disabled = false,
  msg = '',
  showBtnNext = true
}) => {
  const classes = useStyles()

  const errorMsg = () => toast.error(msg)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '36px',
        flexWrap: 'wrap',
        marginTop: '36px',
        marginBottom: '45px'
      }}
    >
      <Link to={back} className={classes.Links} replace>
        <Button
          onClick={onClickBackBtn}
          startIcon={<ArrowBackIosNewIcon className={classes.BackArrow} />}
          Title='Go Back'
          style={{ width: '124px' }}
        />
      </Link>

      {showBtnNext && (
        <Link
          to={disabled ? '#' : link}
          className={classes.Links2}
          onClick={disabled ? errorMsg : onClick}
          replace
        >
          <Button
            disabled={disabled}
            className={classes.NextButton}
            endIcon={<ArrowForwardIosIcon className={classes.NextArrow} />}
            Title='Next'
            style={{ width: '124px' }}
          />
        </Link>
      )}

      <Toaster />
    </div>
  )
}

export default ArrowButton
