import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../../auth/jwt/JwtCreditCard'

const initialError = (message = '') => ({
  statusCode: null,
  name: '',
  message
})

export const pirApplication1Call = createAsyncThunk(
  'appPersonalInformationReview/application1',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.personalInformationReview1()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong getting personal information')
      )
    }
  }
)

export const checkPhoneCall = createAsyncThunk(
  'appPersonalInformationReview/checkPhone',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await UseJwt.pirCheckPhone(body)
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong checking phone number')
      )
    }
  }
)

export const resendCodePhoneCall = createAsyncThunk(
  'appPersonalInformationReview/resendPhoneCode',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.pirResendCodePhone()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong while sending code')
      )
    }
  }
)

export const confirmationPhoneCall = createAsyncThunk(
  'appPersonalInformationReview/confirmationPhone',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await UseJwt.pirConfirmationPhone(body)
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong confirming phone')
      )
    }
  }
)

export const checkEmailCall = createAsyncThunk(
  'appPersonalInformationReview/checkEmail',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await UseJwt.pirCheckEmail(body)
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong checking email number')
      )
    }
  }
)

export const resendCodeEmailCall = createAsyncThunk(
  'appPersonalInformationReview/resendEmailCode',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.pirResendCodeEmail()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong while sending code')
      )
    }
  }
)

export const confirmationEmailCall = createAsyncThunk(
  'appPersonalInformationReview/confirmationEmail',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await UseJwt.pirConfirmationEmail(body)
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong confirming email')
      )
    }
  }
)

export const uploadSSNDocumentCall = createAsyncThunk(
  'appPersonalInformationReview/uploadSSN',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await UseJwt.pirUploadSSNDocument(body)
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong uploading the file')
      )
    }
  }
)

export const appPersonalInformationReview = createSlice({
  name: 'appPersonalInformationReview',
  initialState: {
    application: {
      data: {
        email: '',
        phone: '',
        hasSsb: false
      },
      dataPhone: {
        verifiedPhone: false
      },
      dataEmail: {
        verifiedEmail: false
      },
      pending: true,
      error: initialError()
    },
    checkPhoneDialog: {
      data: {
        sent: false
      },
      pending: true,
      error: initialError()
    },
    checkEmailDialog: {
      data: {
        sent: false
      },
      pending: true,
      error: initialError()
    }
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(pirApplication1Call.pending, (state) => {
        state.application.pending = true
        state.application.error = initialError()
        state.application.dataEmail.verifiedEmail = false
        state.application.dataPhone.verifiedPhone = false
      })
      .addCase(pirApplication1Call.fulfilled, (state, action) => {
        state.application.pending = false
        state.application.error = initialError()
        state.application.data = action.payload
      })
      .addCase(pirApplication1Call.rejected, (state, action) => {
        state.application.pending = false
        state.application.error = action.payload
      }) // checkPhoneCall
      .addCase(checkPhoneCall.pending, (state) => {
        state.checkPhoneDialog.pending = true
        state.checkPhoneDialog.error = initialError()
      })
      .addCase(checkPhoneCall.fulfilled, (state, action) => {
        state.checkPhoneDialog.pending = false
        state.checkPhoneDialog.error = initialError()
        state.checkPhoneDialog.data = action.payload
      })
      .addCase(checkPhoneCall.rejected, (state, action) => {
        state.checkPhoneDialog.pending = false
        state.checkPhoneDialog.error = action.payload
      }) // resendCodePhoneCall
      .addCase(resendCodePhoneCall.pending, (state) => {
        state.checkPhoneDialog.pending = true
        state.checkPhoneDialog.error = initialError()
      })
      .addCase(resendCodePhoneCall.fulfilled, (state, action) => {
        state.checkPhoneDialog.pending = false
        state.checkPhoneDialog.error = initialError()
        state.checkPhoneDialog.data = action.payload
      })
      .addCase(resendCodePhoneCall.rejected, (state, action) => {
        state.checkPhoneDialog.pending = false
        state.checkPhoneDialog.error = action.payload
      }) // confirmationPhoneCall
      .addCase(confirmationPhoneCall.pending, (state) => {
        state.checkPhoneDialog.pending = true
        state.checkPhoneDialog.error = initialError()
      })
      .addCase(confirmationPhoneCall.fulfilled, (state, action) => {
        state.checkPhoneDialog.pending = false
        state.checkPhoneDialog.error = initialError()
        state.application.dataPhone = action.payload
      })
      .addCase(confirmationPhoneCall.rejected, (state, action) => {
        state.checkPhoneDialog.pending = false
        state.checkPhoneDialog.error = action.payload
      }) // checkEmailCall
      .addCase(checkEmailCall.pending, (state) => {
        state.checkEmailDialog.pending = true
        state.checkEmailDialog.error = initialError()
      })
      .addCase(checkEmailCall.fulfilled, (state, action) => {
        state.checkEmailDialog.pending = false
        state.checkEmailDialog.error = initialError()
        state.checkEmailDialog.data = action.payload
      })
      .addCase(checkEmailCall.rejected, (state, action) => {
        state.checkEmailDialog.pending = false
        state.checkEmailDialog.error = action.payload
      }) // resendCodeEmailCall
      .addCase(resendCodeEmailCall.pending, (state) => {
        state.checkEmailDialog.pending = true
        state.checkEmailDialog.error = initialError()
      })
      .addCase(resendCodeEmailCall.fulfilled, (state, action) => {
        state.checkEmailDialog.pending = false
        state.checkEmailDialog.error = initialError()
        state.checkEmailDialog.data = action.payload
      })
      .addCase(resendCodeEmailCall.rejected, (state, action) => {
        state.checkEmailDialog.pending = false
        state.checkEmailDialog.error = action.payload
      }) // confirmationEmailCall
      .addCase(confirmationEmailCall.pending, (state) => {
        state.checkEmailDialog.pending = true
        state.checkEmailDialog.error = initialError()
      })
      .addCase(confirmationEmailCall.fulfilled, (state, action) => {
        state.checkEmailDialog.pending = false
        state.checkEmailDialog.error = initialError()
        state.application.dataEmail = action.payload
      })
      .addCase(confirmationEmailCall.rejected, (state, action) => {
        state.checkEmailDialog.pending = false
        state.checkEmailDialog.error = action.payload
      }) // uploadSSNDocumentCall
      .addCase(uploadSSNDocumentCall.pending, (state) => {
        state.application.pending = true
        state.application.error = initialError()
      })
      .addCase(uploadSSNDocumentCall.fulfilled, (state, action) => {
        state.application.pending = false
        state.application.error = initialError()
      })
      .addCase(uploadSSNDocumentCall.rejected, (state, action) => {
        state.application.pending = false
        state.application.error = action.payload
      })
})

export default appPersonalInformationReview.reducer
