import React from "react"
import { Navigate } from "react-router-dom"
import jwt_decode from "jwt-decode"
import { endpoints } from '../core/jwt/cc/endpoints'

const PrivateGuardCC = ({ children }) => {
  const accessToken = sessionStorage.getItem(endpoints.tokenKeyName)

  const isTokenValid = () => {
    if (accessToken) {
      try {
        const decoded = jwt_decode(accessToken)
        const currentTime = Math.floor(Date.now() / 1000)
        return decoded.exp >= currentTime
      } catch (error) {
        console.log("error en el token")
        return false
      }
    }
    return false
  }

  return isTokenValid() ? <>{children}</> : <Navigate to="/not-found" />
}
export default PrivateGuardCC
