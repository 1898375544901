export const extractKeys = (data = {}) => {
  const dataFiltered = data.filter(item => item)
  const keys = dataFiltered.reduce((acc, item) => acc.concat(Object.keys(item)), [])
  return [...new Set(keys)]
}


const formats = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']

export const transformFileToBase64 = (file) => {
  if (!formats.includes(file.type)) {
    return Promise.reject(
      {
        message: 'Invalid file format',
        code: 'INVALID_FILE_FORMAT',
      }
    )
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      resolve(event.target.result)
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsDataURL(file)
  })
}
