import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../auth/jwt/JwtCreditCard'
import { personalCreditCardAdapter } from '../SelectCreditCard/auxiliarData/personalCreditCardInfo'
import { SEGMENT_LIMITS } from './CreditLimitOptions'

export const getCreditLimitRequestData = createAsyncThunk(
  'creditLimitRequest/getCreditLimitRequestData',
  async (params, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getCreditLimitRequestData()
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getCreditLimitRequestPromoCode = createAsyncThunk(
  'creditLimitRequest/getCreditLimitRequestPromoCode',
  async (promoCode, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getCreditLimitRequestPromoCode(promoCode)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getCreditLimitRequestSelectedCard = createAsyncThunk(
  'creditLimitRequest/getCreditLimitRequestSelectedCard',
  async (cardId) => {
    const response = await UseJwt.getCreditLimitRequestSelectedCard(cardId)
    return response.data
  }
)

export const getInsuranceList = createAsyncThunk(
  'creditLimitRequest/getInsuranceList',
  async () => {
    const response = await UseJwt.getInsuranceList()
    return response.data || []
  }
)

export const getSelectedCardID = createAsyncThunk(
  'creditLimitRequest/getSelectedCardID',
  async () => {
    const response = await UseJwt.getSelectedCardID()
    return response.data
  }
)

export const createCreditLimitRequest = createAsyncThunk(
  'creditLimitRequest/createCreditLimitRequest',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await UseJwt.createCreditLimitRequest(requestData)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateCreditLimitRequest = createAsyncThunk(
  'creditLimitRequest/updateCreditLimitRequest',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await UseJwt.updateCreditLimitRequest(requestData)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getAllCardsAvaliable = createAsyncThunk(
  'creditLimitRequest/getCreditLimitCards',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getCreditCards()
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateCurrentCardType = createAsyncThunk(
  'creditLimitRequest/updateCreditCardType',
  async (body, { rejectWithValue }) => {
    try {
      const response = await UseJwt.updateCurrentCardTypeInApplication(body)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const initialState = {
  pending: true,
  creditLimitRequestData: {},
  insuranceList: [],
  allCards: [],
  changeCardResponse: null,
  promoCode: null,
  promoCodeString: null,
  cardID: null,
  hasAad: false,
  selectedCard: {
    features: [],
    benefits: []
  },
  hasCreditLimitRequestData: false,
  errors: {
    allCards: null,
    promoCode: null,
    sendCreditLimit: null
  },
  limits: SEGMENT_LIMITS.Business,
  creditAmount: 1000,
  selectedInsurance: null
}

export const appCreditLimitRequestSlice = createSlice({
  name: 'creditLimitRequest',
  initialState,
  reducers: {
    setCreditAmount: (state, action) => {
      state.creditAmount = Number(action.payload)
    },
    setSelectedInsurance: (state, action) => {
      state.selectedInsurance = {
        id: action.payload.insuranceOptionID,
        title: action.payload.title
      }
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(getSelectedCardID.fulfilled, (state, action) => {
        state.pending = false
        state.cardID = action.payload?.selectedCardId
        state.hasAad = action.payload?.hasAad
      })
      .addCase(getCreditLimitRequestSelectedCard.fulfilled, (state, action) => {
        state.pending = false
        state.selectedCard = personalCreditCardAdapter(action.payload)

        const toChangeLimit =
          SEGMENT_LIMITS[action.payload.segment] || SEGMENT_LIMITS.Business
        state.limits = toChangeLimit
        if (state.hasCreditLimitRequestData === false) {
          state.creditAmount = toChangeLimit.initial
        }
      }) // getCreditLimitRequestData
      .addCase(getCreditLimitRequestData.fulfilled, (state, action) => {
        state.pending = false
        state.creditLimitRequestData = action.payload
        state.hasCreditLimitRequestData = true
        state.creditAmount = action.payload.limitRequest.requestedLimit

        if (
          action.payload.selectedInsurances &&
          action.payload.selectedInsurances.length > 0
        ) {
          state.selectedInsurance = {
            id: action.payload.selectedInsurances[0]
              .creditCardInsuranceOptionID,
            title: null
          }
        }

        if (action.payload.promoCode && action.payload.promoCode.promoCodeID) {
          state.promoCode = action.payload.promoCode
          state.promoCodeString = action.payload.promoCode.promoCode
        }
      })
      .addCase(getCreditLimitRequestData.rejected, (state, action) => {
        state.pending = false
        const statusCodes = [404]
        if (!statusCodes.includes(action?.payload?.error?.statusCode)) return
        state.hasCreditLimitRequestData = false
      }) // getInsuranceList
      .addCase(getInsuranceList.fulfilled, (state, action) => {
        state.pending = false
        state.insuranceList = action.payload
      }) // getCreditLimitRequestPromoCode
      .addCase(getCreditLimitRequestPromoCode.fulfilled, (state, action) => {
        state.pending = false
        state.promoCode = action.payload
        state.errors.promoCode = null
      })
      .addCase(getCreditLimitRequestPromoCode.rejected, (state, action) => {
        state.pending = false
        state.errors.promoCode = action.payload?.error
        state.promoCode = null
      }) // getAllCardsAvaliable
      .addCase(getAllCardsAvaliable.pending, (state) => {
        state.pending = true
      })
      .addCase(getAllCardsAvaliable.fulfilled, (state, action) => {
        state.pending = false
        state.allCards = personalCreditCardAdapter(action.payload.options)
        state.errors.allCards = null
      })
      .addCase(getAllCardsAvaliable.rejected, (state, action) => {
        state.pending = false
        state.errors.allCards = action.payload
      }) // updateCurrentCardType
      .addCase(updateCurrentCardType.pending, (state) => {
        state.pending = true
      })
      .addCase(updateCurrentCardType.fulfilled, (state, action) => {
        setTimeout(() => {
          state.pending = false
          state.errors.allCards = null
        }, 332)

        state.changeCardResponse = action.payload.options
      })
      .addCase(updateCurrentCardType.rejected, (state, action) => {
        state.pending = false
        state.errors.allCards = action.payload
      }) // createCreditLimitRequest
      .addCase(createCreditLimitRequest.rejected, (state, action) => {
        state.pending = false
        state.errors.sendCreditLimit = action.payload
      })
      .addCase(createCreditLimitRequest.pending, (state) => {
        state.pending = true
        state.errors.sendCreditLimit = null
      })
      .addCase(createCreditLimitRequest.fulfilled, (state, action) => {
        state.pending = false
        state.creditLimitRequestData = action.payload
      }) // updateCreditLimitRequest
      .addCase(updateCreditLimitRequest.rejected, (state, action) => {
        state.pending = false
        state.errors.sendCreditLimit = action.payload
      })
      .addCase(updateCreditLimitRequest.pending, (state) => {
        state.pending = true
        state.errors.sendCreditLimit = null
      })
      .addCase(updateCreditLimitRequest.fulfilled, (state, action) => {
        state.pending = false
        state.creditLimitRequestData = action.payload
      })
})

export const { setCreditAmount, setSelectedInsurance } =
  appCreditLimitRequestSlice.actions

export default appCreditLimitRequestSlice.reducer
