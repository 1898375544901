import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from '../ProofOfIncomeStyle'
import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  createFilterOptions,
  InputLabel,
  Stack
} from '@mui/material'
import clsx from 'clsx'
import { setFormData } from '../store'
const JobLetter = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [touched, setTouched] = React.useState(false)
  const { catalogs } = useSelector((state) => state.ccProofOfIncome)

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <InputLabel shrink className={classes.SelectLable}>
        Job Letter
      </InputLabel>
      <Autocomplete
        fullWidth
        disablePortal
        options={catalogs.jobLetterCatalog}
        filterOptions={createFilterOptions({
          stringify: (option) => option.name
        })}
        getOptionLabel={(option) => option.name}
        // {...field}
        // value={visualJobLetterSelection}
        onChange={(event, newValue) => {
          event.preventDefault()
          dispatch(setFormData({ jobLetterId: newValue.id }))
        }}
        className={clsx(classes.Select)}
        classes={{ paper: classes.paper }}
        renderInput={(params) => (
          <TextField
            {...params}
            // name={field.name}
            placeholder='Please select one option'
          />
        )}
      />
      {/* {meta.touched && meta.error && (
        <p className={classes.errorMessage}>{meta.error}</p>
      )} */}
    </div>
  )
}

export default JobLetter
