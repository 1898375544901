import React from 'react'
import Button from '../../../Components/Button/Button'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ClearIcon from '@mui/icons-material/Clear'
import { useStyles } from '../ProofOfIncomeStyle'
import { Typography } from '@mui/material'

const UploadFile = () => {
  const classes = useStyles()
  const inputFile = React.useRef(null)
  const [touched, setTouched] = React.useState(false)
  const [errorFile, setErrorFile] = React.useState()
  const [file, setFile] = React.useState()

  return (
    <React.Fragment>
      <Button
        Title='Please upload your job letter'
        variant='h7'
        className={classes.UploadFiles}
        endIcon={<AttachFileIcon />}
        onClick={() => {
          inputFile.current.click()
          setTouched(true)
        }}
      />

      <>
        <input
          // {...field}
          type='file'
          accept='application/pdf,image/*'
          onChange={(event) => {
            // form.setFieldValue(field.name, event.currentTarget.files[0])
          }}
          value=''
          style={{ display: 'none' }}
          ref={inputFile}
          // error={meta.touched && meta.error}
        />
        <Typography className={classes.UploadFileText}>
          Only pdf documents are permitted with a maximum of 2MB
        </Typography>
        {touched && errorFile ? (
          <p className={classes.errorMessage}>{errorFile}</p>
        ) : (
          <React.Fragment>
            <p className={classes.uploadFileName}>
              {`${String(file?.name).substring(0, 20)}...`}
              <span>
                <ClearIcon
                  className={classes.deleteFileIcon}
                  onClick={() => {}}
                />
              </span>
            </p>
          </React.Fragment>
        )}
      </>
    </React.Fragment>
  )
}

export default UploadFile
