import React from 'react'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import InputLabel from "@mui/material/InputLabel";
import { FormControl } from "@mui/material";
import { useStyles } from "./currencyStyle2";
import { Stack } from "@mui/system";
import { InputAdornment } from "@mui/material";
import "./currencyStyle.css"

export default function Currency(
  { Lable,
    className,
    placeholder,
    value,
    onChange,
    onBlur,
    error,
    ...props }) {
  const classes = useStyles();
  return (
    // fullWidth={true}
    <Stack sx={{ width: "100%" }}>
      <FormControl
        sx={{ width: "100%",mb:'10px' }}
        variant="outlined"
        className={[classes.FormWrapper, className].join(" ")}
      >
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          className={classes.InputLable}
        >
          {Lable}
        </InputLabel>
        <CurrencyTextField
          {...props}
          variant="standard"
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          className={classes.currency}
          id="bootstrap-input"
          fullWidth
          type="text"
          outputFormat="string"
          decimalCharacter="."
          size="small"
          digitGroupSeparator=","
          autoComplete='off'
          minimumValue="0"
          InputProps={{
            disableUnderline: true, startAdornment: (
              <InputAdornment
                position="start"
              >
                BZD
              </InputAdornment>
            ), autoComplete: 'off'
          }}
        />
        {error ? <p className={classes.errorMessage}>{error}</p> : ""}
      </FormControl>
    </Stack>

  );
}