import React, { useEffect, useState, useRef } from 'react'
import { Box } from '@mui/system'
import { useStyles } from './styles'
import ProgressCC from '../../Components/ProgressBar/ProgressBar'
import ProcessSteps from '../../Components/ProcessSteps/ProcessSteps'
import { Outlet } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import IdleTimeOutModal from './IdleTimeOutModal'
import { useNavigate } from 'react-router-dom'
import { Card, Typography } from '@material-ui/core'

const CreditCardContainerPage = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const idleTimer = useRef(null)

  const [tabs, setTabs] = useState('/Customer/letsMeet')
  const [progrress, setProgrress] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [isTimedOut, setIsTimedOut] = useState(false)
  const [timeVal, setTimeVal] = useState(null)

  const timeout = 1000 * 300 * 1

  const { innerWidth: width } = window

  const onAction = (e) => {
    setIsTimedOut(false)
  }
  const onActive = (e) => {
    setIsTimedOut(false)
  }
  const onIdle = (e) => {
    navigate('/')
  }
  const handleClose = () => {
    setShowModal(false)
  }

  const { getRemainingTime, reset } = useIdleTimer({
    ref: idleTimer,
    element: document,
    onActive: onActive,
    onIdle: onIdle,
    onAction: onAction,
    debounce: 250,
    timeout: timeout
  })

  useEffect(() => {
    setInterval(() => {
      setTimeVal(getRemainingTime())
    }, 1000)
  }, [])

  useEffect(() => {
    if (timeVal && timeVal <= 60000) setShowModal(true)
  }, [timeVal])

  return (
    <Box className={classes.MainContianer}>
      <Card style={{ padding: '1rem' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography style={{ fontSize: '20px' }} color='#091242'>
            Credit card Application
          </Typography>

          <Typography
            style={{ fontSize: '14px', marginRight: width > 1100 ? 24 : 0 }}
            color='#091242'
          >
            {Math.ceil(progrress)} %
          </Typography>
        </div>
        <IdleTimeOutModal
          showModal={showModal}
          getRemainingTime={getRemainingTime}
          handleClose={handleClose}
        />
        <div style={{ position: 'relative' }}>
          {width > 1100 && (
            <div
              style={{
                position: 'absolute',
                left: 45,
                top: '10%',
                width: 'calc(100% - 92px)'
              }}
            >
              <ProgressCC progrress={progrress} />
            </div>
          )}
          <ProcessSteps
            setProgrress={(progress) => setProgrress(progress)}
            progress={progrress}
            setTabs={(tabs) => setTabs(tabs)}
            tabs={tabs}
          />
        </div>
      </Card>
      <Box className={classes.FormContainer}>
        <Outlet />
      </Box>
    </Box>
  )
}

export default CreditCardContainerPage
