export const USER_TYPE = {
  Individual: 'Individual',
  Business: 'Business'
}

export const APPLICATION_STEP = {
  'personal-information-review': {
    path: '/credit-card/personal-information-review',
    key: 'personal-information-review'
  },
  'credit-limit-request-cc': {
    path: '/credit-card/credit-limit-request',
    key: 'credit-limit-request-cc'
  },
  'additional-applicants-cc': {
    path: '/credit-card/additional-applicants',
    key: 'additional-applicants-cc'
  },
  'monthly-income-cc': {
    path: '/credit-card/monthly-income',
    key: 'monthly-income-cc'
  },
  'monthly-expenses-cc': {
    path: '/credit-card/monthly-expenses',
    key: 'monthly-expenses-cc'
  },
  'proof-of-income-cc': {
    path: '/credit-card/proof-of-income',
    key: 'proof-of-income-cc'
  },
  'upload-documents-cc': {
    path: '/credit-card/upload-documents',
    key: 'upload-documents-cc'
  }
}

export const UPLOAD_DOCUMENTS = {
  chat: {
    name: 'Chat Attached Document in Back Office',
    module: 'unknow' // upadte this
  },
  'credit-card-payment': {
    name: 'Credit Card Payment',
    module: 'unknow' // upadte this
  },
  'job-letter': {
    name: 'Job Letter',
    module: 'income'
  },
  'loan-payment': {
    name: 'Loan Payment',
    module: 'expenses'
  },
  mortage: {
    name: 'Mortage',
    module: 'expenses'
  },
  'other-credit-obligation': {
    name: 'Other Credit Obligation',
    module: 'expenses'
  },
  'overdraft-payment': {
    name: 'Overdraft Payment',
    module: 'expenses'
  }
}
