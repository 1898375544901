import React from 'react'
import Seguro1 from '../../../../assets/CCImages/seguro1.jpg'
import Seguro2 from '../../../../assets/CCImages/seguro2.png'
import { useStyles } from '../styles'
import { Checkbox, Typography, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedInsurance } from '../store'

const Insurance = () => {
  const classes = useStyles()
  const isSmallScreen = useMediaQuery('(max-width:636px)')
  const dispatch = useDispatch()
  const { limits, insuranceList, selectedInsurance } = useSelector(
    (state) => state.creditLimitRequest
  )

  React.useEffect(() => {
    if (
      selectedInsurance &&
      selectedInsurance.id &&
      selectedInsurance.title === null &&
      insuranceList &&
      insuranceList.length > 0
    ) {
      const currentIns = insuranceList.find(
        (item) => item.insuranceOptionID === selectedInsurance.id
      )
      if (currentIns) {
        dispatch(setSelectedInsurance(currentIns))
      }
    }
  }, [selectedInsurance, insuranceList])

  return (
    <div>
      <div className={classes.ButtonTitle}>Insurance</div>

      <Typography color={'#666C89'}>
        Life insurance for Credit Cards is an insurance product that is designed
        to offer coverage if a defined event such as death or a covered critical
        illness occurs. Critical illness includes heart attack, stroke, and
        life-threatening cancer.
        <br /> <br />
        This insurance can easily pay off all or a part of your outstanding
        credit card balance, relieving your loved ones of that added financial
        pressure. <br /> <br />
        Do you wish to add insurance coverage for the credit card you are
        applying for?
      </Typography>

      {insuranceList.map((item, indx) => (
        <div
          key={indx}
          style={{
            border:
              selectedInsurance?.id === item.insuranceOptionID
                ? '3px solid #2F80ED'
                : '1px solid rgba(0,0,0,0.5)'
          }}
          className={classes.InsuranceContainer}
          onClick={() => dispatch(setSelectedInsurance(item))}
        >
          <img
            src={item.insuranceOptionID === 1 ? Seguro1 : Seguro2}
            alt={`${item.title} representation`}
          />

          <div>
            <Typography color={'#091242'} fontSize={20} fontWeight={600}>
              {item.title}
            </Typography>
            <Typography color={'#666C89'}>{item.description}</Typography>
            <ul>
              <li>
                <Typography color={'#666C89'}>{item.firstBullet}</Typography>
              </li>
              <li>
                <Typography color={'#666C89'}>{item.secondBullet} </Typography>
              </li>
            </ul>
          </div>

          <Checkbox
            onChange={() => {}}
            checked={selectedInsurance?.id === item.insuranceOptionID}
            style={{
              position: 'absolute',
              top: isSmallScreen ? 5 : 12,
              right: isSmallScreen ? 5 : 12,
              padding: 0
            }}
          />
        </div>
      ))}

      <div
        style={{
          backgroundColor: '#FFD6D6',
          padding: '2rem',
          borderRadius: '8px',
          margin: '2rem 0',
          textAlign: 'center'
        }}
      >
        <Typography color={'#37393F'}>
          Maximum Coverage: BZ ${limits?.min === 500 ? '15,000' : '30,000'}
        </Typography>
      </div>
    </div>
  )
}

export default Insurance
