/*
[
{
    "id": 2,
    "creditCardId": 2,
    "features": {
        "Interest rate": {
            "title": "Interest rate",
            "value": "22%"
        },
        "Minimum payment": {
            "title": "Minimum payment",
            "value": "5% of outstanding"
        },
        "Annual membership fee-primary card": {
            "title": "Annual membership fee-primary card",
            "value": "BZ$100"
        },
        "Annual membership fee - additional card": {
            "title": "Annual membership fee - additional card",
            "value": "BZ$50"
        },
        "Transaction Limit": {
            "title": "Transaction Limit",
            "value": "Number of Transactions: 10 daily, 40 weekly"
        },
        "US Spending Limit": {
            "title": "US Spending Limit",
            "value": "US$7,500 monthly / US$35,000 annually"
        },
        "Monthly Statement Cycle": {
            "title": "Monthly Statement Cycle",
            "value": "every 25th"
        },
        "Insurance": {
            "title": "Insurance",
            "value": [
                "Personal Credit Cards (premium is charged monthly per $100 of outstanding balance)",
                "-Credit Life- Single coverage is $0.29 & Joint is $0.52",
                "-Credit Life & Critical Illness (combo) – Single coverage is $0.53 and Joint is $0.95 "
            ]
        },
        "Service Provider Fees ": {
            "title": "Service Provider Fees ",
            "value": [
                ".0175% stamp duty for both Visa and MasterCard credit cards"
            ]
        },
        "Standing Order": {
            "title": "Standing Order",
            "value": true
        }
    },
    "benefits": {
        "AA miles collection": {
            "title": "AA miles collection",
            "value": false
        },
        "Mastercard Global Services": {
            "title": "Mastercard Global Services",
            "value": true
        },
        "Price Protection": {
            "title": "Price Protection",
            "value": false
        },
        "Purchase protection": {
            "title": "Purchase protection",
            "value": true
        },
        "MasterRental": {
            "title": "MasterRental",
            "value": false
        },
        "Concierge Service": {
            "title": "Concierge Service",
            "value": false
        },
        "Mastercard Travel Services": {
            "title": "Mastercard Travel Services",
            "value": false
        },
        "Travel Assistance": {
            "title": "Travel Assistance",
            "value": false
        }
    }
},
{
}
]

*/

import { extractKeys } from '../utils'

const transformComparisonAttributes = (data = {}) => {
  const keys = extractKeys(data)

  const result = keys.map((key) => {
    const title = data[0][key].title
    const values = data.map((item) => item[key].value)
    return { title, values }
  })
  // console.log("comparisonBenefitsAdapter", benefits)
  return result
}


const comparisonFeaturesAdapter = (allFeatures = {}) => {
  const features = transformComparisonAttributes(allFeatures)
  // console.log("comparisonFeaturesAdapter", features)
  return features
}

const comparisonBenefitsAdapter = (allBenefits = {}) => {
  const benefits = transformComparisonAttributes(allBenefits)
  // console.log("comparisonBenefitsAdapter", benefits)
  return benefits
}


export const comparisonAdapter = (comparison) => {
  if (!comparison) return []

  const allFeatures = comparison.map((cc) => cc.features).filter((features) => features)
  const allBenefits = comparison.map((cc) => cc.benefits).filter((benefits) => benefits)

  return {
    features: comparisonFeaturesAdapter(allFeatures),
    benefits: comparisonBenefitsAdapter(allBenefits)
  }
}