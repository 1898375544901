import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '../Input'
import { Stack } from '@mui/material'

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .required('Employer email is required'),
  confirm_email: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Emails must match')
    .required('Confirm email is required')
})

const EmailConfirmation = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
      confirm_email: ''
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      console.log(values)
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2 }}
        mt={3}
        alignItems='flex-start'
        mb={3}
        gap={3}
      >
        <Input
          Lable='Enter your employer’s email address'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
          id='email'
        />

        <Input
          Lable='Confirm your employer’s email address'
          name='confirm_email'
          value={formik.values.confirm_email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.confirm_email && formik.errors.confirm_email}
          id='confirm_email'
        />
      </Stack>
    </form>
  )
}

export default EmailConfirmation
