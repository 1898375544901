import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../../auth/jwt/JwtCreditCard'
import { creditCardInfoAdapter } from '../../../adapters'

export const getCardInfoById = createAsyncThunk('appComparison/getCardInfoById', async (params) => {
  const { id, carPosition } = params
  const response = await UseJwt.gerCardDetailsById(id)
  return { card: creditCardInfoAdapter(response.data), carPosition }
})

export const startNewApplication = createAsyncThunk('appComparison/startApplication', async (params) => {
  const response = await UseJwt.startApplication(params)
  return response.data
})


export const appComparisonSlice = createSlice({
  name: 'appComparison',
  initialState: {
    pending: true,
    openModal: false,
    cardOne: {},
    cardTwo: {},
    cardThree: {},
    comparison: [],
    tableComparison: {
      features: [],
      benefits: []
    },
    selectedCard: {},
  },
  reducers: {
    setComparison: (state, action) => {
      state.comparison = action.payload
    },
    setTableComparison: (state, action) => {
      state.tableComparison = action.payload
    },
    setInitialCard: (state) => {
      state.cardOne = {}
      state.cardTwo = {}
      state.cardThree = {}
    },
    setOpenModal: (state, action) => {
      state.openModal = action.payload
    },
    setSelectedCard: (state, action) => {
      state.selectedCard = action.payload
    }
  },
  extraReducers: builder =>
    builder.addCase(getCardInfoById.fulfilled, (state, action) => {
      const { card, carPosition } = action.payload
      // cardPosition is cardOne, cardTwo, or cardThree
      state[carPosition] = card
      state.pending = false
    })
    .addCase(startNewApplication.pending, (state) => {
      state.pending = true
    })
    .addCase(startNewApplication.fulfilled, (state, action) => {
      state.pending = false
    })
    .addCase(startNewApplication.rejected, (state) => {
      state.pending = false
    })
})


export const { setComparison, setTableComparison, setInitialCard, setOpenModal, setSelectedCard } = appComparisonSlice.actions

export default appComparisonSlice.reducer