import * as yup from 'yup'

export const validation = yup.object({
  isCurrentEmail: yup.boolean(),
  new_email: yup.string().when('isCurrentEmail', {
    is: false,
    then: yup
      .string('Email should be string')
      .email('Incorrect email format. Please correct')
      .required('Email is Required'),
  }),
  confirm_new_email: yup.string().when('isCurrentEmail', {
    is: false,
    then: yup
      .string('Email should be string')
      .oneOf([yup.ref('new_email')], 'Email adresses entered not match.  Please try again.')
      .required('Email is Required'),
  }),
  ssn: yup
  .string()
  .when('hasSsb', {
    is: false,
    then: yup.string()
    .test("SOCIAL SECURITY NUMBER*", "SSN must have 9 digits", function (value) {
      // console.log(yup)
      if (value) {
        return String(value).replace(/[_\s]/g, '').length === 9;
      } else {
        return false;
      }
    })
  }),
  // .min(9)
  // .test("SOCIAL SECURITY NUMBER*", "SSN must have 9 digits", function (value) {
  //   // console.log(yup)
  //   if (value) {
  //     return String(value).replace(/[_\s]/g, '').length === 9;
  //   } else {
  //     return false;
  //   }
  // })
  // .required('**Please enter your ssn number '),
  ssnFile: yup
    .mixed()
    .test('file-size', 'The file cannot exceed 2MB ', function (value) {
      if (value) {
        var filesize = (value?.size / 1024 / 1024).toFixed(4)
        return filesize <= 2
      } else {
        return true
      }
    }),
})
