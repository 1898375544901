import { Card, CardContent, Container, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import LoanList from '../../../Components/AtomicDesign/organisms/LoanList/LoanList'
import { CreditCardInfo } from './data'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { hasApplicationInProgress } from './store'

const CreditCardApplication = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { hasCurrentApplicationInProgress } = useSelector(
    (state) => state.application
  )

  useEffect(() => {
    dispatch(hasApplicationInProgress())
  }, [])

  return (
    <>
      <Container>
        <Card variant='outlined'>
          <CardContent>
            <Typography
              width='fit-content'
              padding='0.3rem 4rem'
              borderRadius='1rem'
              bgcolor='#091242'
              color='#fff'
              fontWeight={600}
              margin='2rem 0'
            >
              Credit Cards
            </Typography>
            <Typography fontSize={40} fontWeight={400}>
              Expand Your Purchasing Power
            </Typography>
            <Typography fontSize={20}>
              Enjoy the flexibility to choose the card that best fits your
              lifestyle with our diverse range of credit cards. Experience
              competitive rates and rewards that make spending more rewarding.
            </Typography>
          </CardContent>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              marginTop: '30px',
              marginBottom: '30px'
            }}
          >
            {CreditCardInfo.map((card, idx) => (
              <>
                {card.show(hasCurrentApplicationInProgress) && (
                  <LoanList
                    key={idx}
                    LoanList={card}
                    onClick={() => {
                      navigate(`${card.uri}`)
                    }}
                  />
                )}
              </>
            ))}
          </div>
        </Card>
      </Container>
      {/* <ModalContainer open={openModal} onClose={() => setOpenModal(false)} width={'350px'} borderRadius={'8px'}>
      <div style={{padding: '2rem'}}>
      <Typography>You already have an active Credit Card Application in progress.</Typography>
      <Button fullWidth style={{background: '#0066A4', color: '#fff', marginTop: '1rem'}} onClick={() => setOpenModal(false)}>Back</Button>
      </div>
    </ModalContainer> */}
    </>
  )
}

export default CreditCardApplication
