export const calculateAge = (dobString) => {
  if (!dobString) return 0
  const dob = new Date(dobString)
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export const formatBelizeCurrency = (value) => {
  return value.toLocaleString('en-US', { style: 'currency', currency: 'BZD' })
}