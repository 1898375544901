import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  ButtonTitle:{
    backgroundColor: '#0066A4',
    color: 'white',
    width: '100%',
    padding: '0.5rem',
    textTransform: 'capitalize',
    fontSize: '24px',
    marginBottom: '3rem',
    "&:hover": {
      backgroundColor: '#0066A4',
    color: 'white',
    }
  },
  MonthlyIncomeContainer: {
    width: "100%",
    margin: "auto"
  },
  MonthlyIncomeHeader: {
    "& .MuiTypography-body1": {
      fontSize: "16.46px",
      fontWeight: "800",
      fontFamily: theme.fonts.family.secondary,
      lineHeight: "22.49px",
      letterSpacing: "0.1em",
      color: "#005FAA",
    },
  },
  SupplyIncome: {
    '& .MuiPaper-root': {
      color: 'darkslategray',
      backgroundColor: '#E4FAF4',
      borderRadius: '0.6rem',
      boxShadow: 'none'
    },
    marginBottom: "15px",
    "& .MuiAutocomplete-input": {
      textAlign: "center",
      fontSize: "13px",
      lineHeight: "17.76px",
      letterSpacing: "0.1em",
      fontWeight: "500",
      fontFamily: theme.fonts.family.tertiary,
    },
    "& .MuiAccordionSummary-root ": {
      backgroundColor: '#0066A4',
      color: '#fff',
    },
    "& .MuiTypography-body1": {
     
      letterSpacing: '0.1em',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '17px',
      fontFamily: theme.fonts.family.secondary,
    },
    "& .MuiAccordionSummary-content": {
      placeContent: "center",
    },
    "& .MuiAccordionDetails-root": {
      color: "unset",
      padding: "0px 8px",
    },
    "& .MuiSvgIcon-root": {
      fill: '#fff',
    }
  },
  paragraph: {
    color: "#ffff",
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.colors.primary.blue,
    fontFamily: theme.fonts.family.primary,
  },
  Other: {
    marginBottom: "26px",
    "& .MuiAccordionSummary-root ": {
      backgroundColor: '#0066A4',
      color: '#fff',
    },
    "& .MuiTypography-body1": {

      letterSpacing: '0.1em',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '17px',
      fontFamily: theme.fonts.family.secondary,
    },
    "& .MuiAccordionSummary-content": {
      placeContent: "center",
    },
    "& .MuiAccordionDetails-root": {
      color: "unset",
      padding: "0px 8px",
    },
    "& .MuiSvgIcon-root": {
      fill: '#fff',
    },
  },
  errorMessage: {
    color: "red",
    margin: "20px 0px 0px 0px",
    fontFamily: theme.fonts.family.tertiary,
    fontSize: 13,
    fontWeight: 400
  },
  otherErrorMessage: {
    "& .MuiTypography-body1": {
    color: 'red',
    margin: '8px 0px 0px 0px',
    fontSize: '13px',
    fontFamily: 'avenirLight !important',
    fontWeight: '400',
    marginLeft: '40px'
    }
  },
  Total: {
    width: "100%",
    marginTop: "20px",
    "& .MuiInputBase-root": {
      backgroundColor: "#D3E4F6",
    },
    "& .MuiInputLabel-root": {
      // fontWeight: "800",
      fontSize: '20px'
    },
    ["@media (max-width: 600px)"]: {
      width: "100%",
    },
  },
  RadioBtn: {
    width: "49%",
    ["@media (max-width: 600px)"]: {
      width: "100%",
    },
  },
  RadioLabel: {
    fontFamily: theme.fonts.family.tertiary,
    fontSize: 13,
    fontWeight: "500",
    color: theme.colors.primary.Grey,
  },
  InputMaskCustom: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    //border: "1px solid #DADADA",
    fontSize: 13,
    padding: "10px 12px",
    letterSpacing: "0.1em",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },

  },
  NextButton: {
    width: "20%",
    height: 39.01,
    "&.MuiButtonBase-root": {
      backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        color: "White",
        fontWeight: 600,
        letterSpacing: "0.2em",
      },
    },
  },
}));
