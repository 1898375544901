import ApplyCCImage from '../../../assets/CCImages/apply-cc.png'
import AcceptCCImage from '../../../assets/CCImages/accept-cc.png'
import ContinueCCImage from '../../../assets/CCImages/Continue.png'
import IncreaseCCImage from '../../../assets/CCImages/increase-limit.png'

export const CreditCardInfo = [
  {
    ActiveImg: '',
    InactiveImg: '',
    PrincipalTitle: 'Apply for a new credit card',
    bgImage: ApplyCCImage,
    gradient: '90deg, rgba(98,144,194,1) 0%, rgba(98,144,194,0.60) 45%',
    bgColor: '#0F5CAC',
    // uri: '/select-credit-card/business'
    uri: '/available-credit-cards',
    key: 'apply',
    show: (hasCurrentApplicationInProgress) => !hasCurrentApplicationInProgress
  },
  {
    ActiveImg: '',
    InactiveImg: '',
    PrincipalTitle: 'My applications', //  'Continue my started application',
    bgImage: ContinueCCImage,
    gradient: '90deg, rgba(86,175,49,1) 0%, rgba(170,238,31,0.50) 45%',
    bgColor: '#5BB133',
    key: 'continue',
    uri: '/my-applications',
    show: (hasCurrentApplicationInProgress) => hasCurrentApplicationInProgress
  },
  {
    ActiveImg: '',
    InactiveImg: '',
    PrincipalTitle: 'Accept credit card offer',
    bgImage: AcceptCCImage,
    gradient: '90deg, rgba(0,88,255,1) 0%, rgba(0,88,255,0.50) 45%',
    bgColor: '#0A5DFD',
    key: 'accept',
    show: (hasCurrentApplicationInProgress) => !hasCurrentApplicationInProgress
  },
  {
    ActiveImg: '',
    InactiveImg: '',
    PrincipalTitle: 'Apply for a credit limit increase',
    bgImage: IncreaseCCImage,
    gradient: '90deg, rgba(86,175,49,1) 25%, rgba(170,238,31,0.50) 100%',
    bgColor: '#5BB133',
    key: 'increase',
    show: (hasCurrentApplicationInProgress) => !hasCurrentApplicationInProgress
  }
]
