import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenModal, startNewApplication } from './store'
import Spinner from '../../../../Components/BackDrop/Spinner'
import RequestAAdvantageModal from '../../../Components/Modals/RequestAAdvantageModal'

const AAdvantageModal = () => {
  const navigate = useNavigate()
  const { selectedCard, openModal, pending } = useSelector(
    (state) => state.comparison
  )
  const dispatch = useDispatch()

  const closeAAdvantage = (newVal) => {
    dispatch(setOpenModal(newVal))
  }

  const startApplication = (aAdvantageValue) => {
    const sendData = {
      selectedCardId: selectedCard.id
    }
    if (aAdvantageValue) sendData.aadvantageNumber = aAdvantageValue

    dispatch(startNewApplication(sendData)).then((response) => {
      if (response.error) return
      navigate('/credit-card/personal-information-review')
    })
  }

  return (
    <React.Fragment>
      <Spinner open={pending} />

      {openModal && (
        <RequestAAdvantageModal
          openModal={openModal}
          setOpenModal={closeAAdvantage}
          startApplication={startApplication}
        />
      )}
    </React.Fragment>
  )
}

export default AAdvantageModal
